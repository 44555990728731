import React, { useState } from "react";
import {
  Button,
  Box,
  TextField,
  Card,
  CardContent,
  Typography,
  Container,
  Grid,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";

const RegisterEmailVerification = (props) => {
  const { setIsValidation, setIsRegisterReady, uniqueId } = props;
  const [validation, setValidation] = useState({
    validationCode: "",
  });
  const history = useHistory();
  const handleChange = (e) => {
    setValidation({
      ...validation,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validation.validationCode === uniqueId.current) {
      //Navigate to RegisterAgency
      setIsValidation(false);
      setIsRegisterReady(true);
    } else {
      Swal.fire({
        title: "Validation Failed",
        icon: "error",
        text: "Validation Failed.  Please check your email and enter the correct code.",
      });
    }
  };

  const handleCancel = () => {
    setValidation({
      validationCode: "",
    });
    history.push("/login");
  };
  const buttonStyles = {
    backgroundColor: "#3c4143",
    "&:hover": { backgroundColor: "#616161" },
  };

  const buttonSuccessStyles = {
    backgroundColor: "green",
    "&:hover": { backgroundColor: "#33c520" },
  };

  const textFieldStyles = {
    width: "100%", // Adjust the width as needed
    "& .MuiOutlinedInput-root": {
      color: "#000",
      fontFamily: "Arial",
      fontWeight: "bold",
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#2e2e2e",
        borderWidth: "2px",
      },
      "&.Mui-focused": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#3c4143",
          borderWidth: "3px",
        },
      },
    },
    "& .MuiInputLabel-outlined": {
      color: "#2e2e2e",
      fontWeight: "bold",
      "&.Mui-focused": {
        color: "#3c4143",
        fontWeight: "bold",
      },
    },
  };

  return (
    <Grid>
      <Container
        sx={{ alignContent: "center", textAlign: "center" }}
        maxWidth="lg"
      >
        <Card
          variant="outlined"
          sx={{
            bgcolor: "white",
            width: "800px",
            minHeight: "400px",
            margin: "auto",
            textAlign: "center",
            padding: "15px",
            borderRadius: "10px",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            marginTop: "150px",
          }}
        >
          <CardContent>
            <Typography
              variant="h4"
              fontSize="5"
              align="center"
              color="text.secondary"
            >
              Validation Code
            </Typography>
            <br />
            <TextField
              type="text"
              name="validationCode"
              sx={textFieldStyles}
              required
              placeholder="Validation Code *"
              onChange={(e) => handleChange(e)}
            />
            <br />
            <br />

            <Box display="flex" justifyContent="flex-end" gap={2}>
              <Button
                sx={buttonSuccessStyles}
                variant="contained"
                size="small"
                onClick={handleSubmit}
              >
                Validate
              </Button>
              <Button
                sx={buttonStyles}
                variant="contained"
                size="small"
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Container>
    </Grid>
  );
};

export default RegisterEmailVerification;
