import {
  CLEAR_AGENT_CONTRACT_INFO_LIST,
  SET_AGENT_CONTRACT_INFO_LIST,
  AGENT_CONTRACT_INFO_LOADING,
  SET_AGENT_CONTRACT_INFO_FILTER_MODEL,
  CLEAR_AGENT_CONTRACT_INFO_FILTER_MODEL,
} from "../../constants/action-types";

const initState = {
  data: [],
  filterModel: {
    items: [],
  },
  isFetchingAgentContractInfo: false,
};

export const agentsContractInfo = (state = initState, action) => {
  switch (action.type) {
    case SET_AGENT_CONTRACT_INFO_LIST:
      return { ...state, data: action.payload };
    case CLEAR_AGENT_CONTRACT_INFO_LIST:
      return { ...state, data: [] };
    case AGENT_CONTRACT_INFO_LOADING:
      return { ...state, isFetchingAgentContractInfo: action.payload };
    case SET_AGENT_CONTRACT_INFO_FILTER_MODEL:
      return { ...state, filterModel: action.payload };
    case CLEAR_AGENT_CONTRACT_INFO_FILTER_MODEL:
      return { ...state, filterModel: { items: [] } };
    default:
      return state;
  }
};
