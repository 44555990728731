import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import {
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarExport,
} from "@mui/x-data-grid-pro";
import { fetchAgentProductionForAgencyList } from "../../../../store/actions/agents/agentProductionForAgency";
import AgentProductionColumns from "./AgentProductionColumns";
import AgentProductionData from "./AgentProductionData";
import classes from "../../../DataGridTemplate/DataGridTemplate.module.css";
import StripedDataGridTemplate from "../../../DataGridTemplate/StripedDataGridTemplate";
import DataGridNoResults from "../../../DataGridTemplate/DataGridNoResults";
import _ from "lodash";

const AgentProductionInfo = (props) => {
  const { agentId, agencyId } = props;
  const isFetchingAgentProductionForAgency = useSelector(
    (state) => state.agentProductionForAgency.isFetchingProduction
  );
  const dispatch = useDispatch();
  const rawData = useSelector((state) => state.agentProductionForAgency?.data);
  const data = rawData.filter((item) => item.agentId === agentId);
  const currentYear = new Date().getFullYear().toString();

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 5,
    page: 0,
  });

  const shouldFetchAgentProduction = (
    agencyId,
    agentId,
    data,
    isFetchingAgentProductionForAgency
  ) =>
    agencyId !== null &&
    agentId !== null &&
    data.length === 0 &&
    !isFetchingAgentProductionForAgency;

  useEffect(() => {
    if (
      shouldFetchAgentProduction(
        agencyId,
        agentId,
        data,
        isFetchingAgentProductionForAgency
      )
    ) {
      dispatch(
        fetchAgentProductionForAgencyList(parseInt(agencyId), parseInt(agentId))
      );
    }
  }, [agencyId, agentId, data, dispatch, isFetchingAgentProductionForAgency]);

  const rawFilterModel = useSelector(
    (state) => state.agentProductionForAgency.filterModel
  );

  const [filterModel, setFilterModel] = useState(rawFilterModel);
  const columns = AgentProductionColumns();
  const uniqueRows = AgentProductionData({
    data: data,
    currentYear: currentYear,
  });
  const sxToolBarContainer = { fontSize: 12, paddingBottom: 1 };
  const sxToolBarColumnsButton = {
    color: "black",
    backgroundColor: "whitesmoke",
  };

  /// Custom toolbar for the DataGrid
  const CustomToolbar = () => {
    return (
      <GridToolbarContainer sx={sxToolBarContainer}>
        <GridToolbarFilterButton sx={sxToolBarColumnsButton} />
        <GridToolbarExport sx={sxToolBarColumnsButton} />
      </GridToolbarContainer>
    );
  };

  const StripedDataGrid = StripedDataGridTemplate();

  const sxBox = {
    height: "auto",
    width: "100%",
    "& .super-app-theme--header": {
      backgroundColor: "rgba(197, 5, 157, 0.47)",
      borderBottom: "1px solid rgba(27, 133, 243, 0.8)",
    },
  };

  const getRowClass = (params) => {
    if (params.row.carrierName === "Total") {
      return "bold";
    }
    return params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "";
  };

  const debouncedSetFilterModel = _.debounce((newFilterModel) => {
    setFilterModel(newFilterModel);
  }, 1000);

  /// Return the DataGrid
  const StripedDataGridComponent = () => (
    <StripedDataGrid
      slots={{ toolbar: CustomToolbar }}
      loading={isFetchingAgentProductionForAgency}
      rows={uniqueRows}
      columns={columns}
      paginationModel={paginationModel}
      onPaginationModelChange={setPaginationModel}
      pageSizeOptions={[5, 10, 25]}
      pagination={true}
      disableRowSelectionOnClick
      getRowClassName={getRowClass}
      filterModel={filterModel}
      onFilterModelChange={(newFilterModel) => {
        debouncedSetFilterModel(newFilterModel);
      }}
      density="compact"
      autoHeight
    />
  );

  const StripedDataGridNoResults = () => {
    return <DataGridNoResults columns={columns} uniqueRows={uniqueRows} />;
  };

  return (
    <>
      {isFetchingAgentProductionForAgency && <h5>Loading...</h5>}
      {!isFetchingAgentProductionForAgency &&
        uniqueRows[0].carrierName === "" && (
          <div className={classes.myClass}>
            <Box sx={sxBox}>
              <StripedDataGridNoResults />
            </Box>
          </div>
        )}
      {!isFetchingAgentProductionForAgency &&
        uniqueRows.length > 0 &&
        uniqueRows[0].carrierName !== "" && (
          <div className={classes.myClass}>
            <Box sx={sxBox}>
              <StripedDataGridComponent />
            </Box>
          </div>
        )}
    </>
  );
};

export default AgentProductionInfo;
