import { Box, Button, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
import "./LandingPage.css";
import Reactour from "reactour";
import { withRouter } from "react-router-dom";
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock";
import { Directions } from "@mui/icons-material";
import { Link } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "3em",
    width: 600,
  },
  agencyWord: {
    fontSize: "9vw",
    color: "white",
    height: "1.1em",
    marginTop: "-0.3em",
  },
  sloganText: {
    textAlign: "center",
    backgroundColor: "white",
    color: "black",
    height: "1.25em",
    marginTop: "0.5em",
    fontSize: "1.5em",
    letterSpacing: "0.5em",
    [theme.breakpoints.down("sm")]: {
      letterSpacing: "0em",
      fontSize: "1em",
    },
  },
  sloganSpan: {
    width: "100%",
    height: "0px",
    display: "inline-block",
  },
  tourTypography: {
    fontSize: 20,
    color: "#ffffff",
    padding: 20,
  },
  tourIcon: {
    fontSize: 64,
    paddingLeft: 20,
    paddingRight: 15,
    color: "#f1df3a",
    alignSelf: "left",
  },
  button: {
    color: "#12283f",
    marginTop: "1em",
    fontWeight: "bold",
    alignItems: "start",
    fontSize: 14,
    padding: 10,
    transition: "all .5s ease-in-out",
    "&:hover": {
      color: "white",
      backgroundColor: "#12283f",
    },
  },
  tourButton: {
    backgroundColor: "#0a3c64",
    margin: "10px 0 5px",
    "&:hover": {
      backgroundColor: "#416a8b",
    },
  },
}));

const NewFeatures = () => {
  const classes = useStyles();
  const disableBody = (target) => disableBodyScroll(target);
  const enableBody = (target) => enableBodyScroll(target);
  const [rtsTourOpen, setRtsTourOpen] = useState(false);
  const [FSTTourOpen, setFSTTourOpen] = useState(false);
  // const [CLRTourOpen, setCLRTourOpen] = useState(false);
  const closeTour = () =>
    rtsTourOpen
      ? setRtsTourOpen(false)
      : FSTTourOpen
      ? setFSTTourOpen(false)
      : setRtsTourOpen(false);
  const openRTSTour = () => setRtsTourOpen(true);
  // const openCLRTour = () => {
  //   setCLRTourOpen(true);
  // };
  const openFSTTour = () => setFSTTourOpen(true);

  return (
    <Box>
      <Grid>
        <Grid item xs={12} style={{ backgroundColor: "#444242" }}>
          <div className={classes.agencyWord}>
            <span style={{ fontFamily: "mazBold" }}>AGENCY</span>
            <span style={{ fontFamily: "maz" }}>DASHBOARD</span>
          </div>
          <div className={classes.sloganText}>
            All your data, in one place, in real time.
            <span className={classes.sloganSpan}> </span>
          </div>
        </Grid>
        <Grid
          container
          justifyContent="center"
          alignContent="center" /* className={classes.grid} */
        >
          <Box className={classes.root}>
            <Grid container>
              <Button
                onClick={openRTSTour}
                variant="contained"
                className={classes.tourButton}
                fullWidth
              >
                <Grid container align="left" alignItems="center">
                  <Grid item xs={2}>
                    <Directions className={classes.tourIcon} />
                  </Grid>
                  <Grid item xs={10} justifyContent="center">
                    <Typography
                      className={classes.tourTypography}
                      noWrap
                      variant="button"
                    >
                      Take A Tour Of Certs Rts!
                    </Typography>
                  </Grid>
                </Grid>
              </Button>
            </Grid>
            <Grid container>
              <Button
                onClick={openFSTTour}
                variant="contained"
                className={classes.tourButton}
                fullWidth
                style={{ display: "inherit" }}
              >
                <Grid container align="left" alignItems="center">
                  <Grid item xs={2}>
                    <Directions className={classes.tourIcon} />
                  </Grid>
                  <Grid item xs={10} justifyContent="center">
                    <Typography
                      className={classes.tourTypography}
                      noWrap
                      variant="button"
                    >
                      Take A Tour Of Filter Sort Totaling!
                    </Typography>
                  </Grid>
                </Grid>
              </Button>
            </Grid>
            {/* <Grid container>
              <Button
                onClick={openCLRTour}
                variant="contained"
                className={classes.tourButton}
                fullWidth
                style={{ display: "inherit" }}
              >
                <Grid container align="left" alignItems="center">
                  <Grid item xs={2}>
                    <Directions className={classes.tourIcon} />
                  </Grid>
                  <Grid item xs={10} justifyContent="center">
                    <Typography
                      className={classes.tourTypography}
                      noWrap
                      variant="button"
                    >
                      Take A Tour Of CLR!
                    </Typography>
                  </Grid>
                </Grid>
              </Button>
            </Grid> */}
            <Grid>
              <Link to={`/main`} style={{ textDecoration: "none" }}>
                <Button
                  size="small"
                  fullWidth
                  variant="contained"
                  className={classes.button}
                >
                  Back
                </Button>
              </Link>
            </Grid>
          </Box>
        </Grid>
      </Grid>
      <RtsTour
        onAfterOpen={disableBody}
        onBeforeClose={enableBody}
        isOpen={rtsTourOpen}
        closeTour={closeTour}
        rounded={5}
        accentColor={"white"}
      />
      {/* <ClrTour
        onAfterOpen={disableBody}
        onBeforeClose={enableBody}
        isOpen={CLRTourOpen}
        closeTour={closeTour}
        rounded={5}
        accentColor={"white"}
      /> */}
      <ProdFilterSortTour
        onAfterOpen={disableBody}
        onBeforeClose={enableBody}
        isOpen={FSTTourOpen}
        closeTour={closeTour}
        rounded={5}
        accentColor={"white"}
      />
    </Box>
  );
};

const RtsTour = withRouter(
  ({
    onAfterOpen,
    onBeforeClose,
    isOpen,
    closeTour,
    location: { pathname },
    history,
  }) => {
    const steps = [
      {
        selector: '[data-tour="ad__rts_certs"]',
        content:
          "You can now see the RTS and Certification status for Approved contracts where data is available. Click the right arrow to begin the tour.",
      },
      {
        selector: "",
        content: "",
        action: () =>
          history.push({
            pathname: "/AgentList",
            state: {
              rtsTourOpen: true,
              statuses: ["Approved"],
            },
          }),
      },
    ];
    return (
      <Reactour
        onAfterOpen={onAfterOpen}
        onBeforeClose={onBeforeClose}
        steps={steps}
        isOpen={isOpen}
        onRequestClose={() => {
          closeTour();
          clearAllBodyScrollLocks();
        }}
        update={pathname}
        closeWithMask={false}
        rounded={5}
      />
    );
  }
);

// const ClrTour = withRouter(
//   ({
//     onAfterOpen,
//     onBeforeClose,
//     isOpen,
//     closeTour,
//     location: { pathname },
//     history,
//   }) => {
//     const steps = [
//       {
//         selector: '[data-tour="ad__rts_certs"]',
//         content:
//           "Commission Loss Report (CLR) shows potentail lost clients for a given year.",
//       },
//       {
//         selector: "",
//         content: "",
//         action: () => {
//           history.push({
//             pathname: "/CommissionLossReport",
//             state: {
//               CLRTourOpen: true,
//             },
//           });
//         },
//       },
//     ];
//     return (
//       <Reactour
//         onAfterOpen={onAfterOpen}
//         onBeforeClose={onBeforeClose}
//         steps={steps}
//         isOpen={isOpen}
//         onRequestClose={() => {
//           closeTour();
//           clearAllBodyScrollLocks();
//         }}
//         update={pathname}
//         closeWithMask={false}
//         rounded={5}
//       />
//     );
//   }
// );

const ProdFilterSortTour = withRouter(
  ({
    onAfterOpen,
    onBeforeClose,
    isOpen,
    closeTour,
    location: { pathname },
    history,
  }) => {
    const steps = [
      {
        selector: '[data-tour="ad__totals"]',
        content:
          "Take a tour of the Production Statistic Filterering, sorting and totaling.",
      },
      {
        selector: "",
        content: "",
        action: () =>
          history.push({
            pathname: "/production",
            state: {
              FSTTourOpen: true,
              carrier: "1",
            },
          }),
      },
    ];
    return (
      <Reactour
        onAfterOpen={onAfterOpen}
        onBeforeClose={onBeforeClose}
        steps={steps}
        isOpen={isOpen}
        onRequestClose={() => {
          closeTour();
          clearAllBodyScrollLocks();
        }}
        update={pathname}
        closeWithMask={false}
        rounded={5}
      />
    );
  }
);

export default NewFeatures;
