import {
  AGENT_PRODUCTION_FOR_AGENCY_LOADING,
  SET_AGENT_PRODUCTION_FOR_AGENCY,
  CLEAR_AGENT_PRODUCTION_FOR_AGENCY,
  SET_AGENT_PRODUCTION_FOR_AGENCY_FILTER_MODEL,
  CLEAR_AGENT_PRODUCTION_FOR_AGENCY_FILTER_MODEL,
} from "../../constants/action-types";

const initState = {
  data: [],
  filterModel: {
    items: [],
  },
  isFetchingProduction: false,
};

export const agentProductionForAgency = (
  state = initState,
  { type, payload }
) => {
  switch (type) {
    case SET_AGENT_PRODUCTION_FOR_AGENCY:
      return { ...state, data: [...state.data, ...payload] };
    case CLEAR_AGENT_PRODUCTION_FOR_AGENCY:
      return { ...state, data: [] };
    case SET_AGENT_PRODUCTION_FOR_AGENCY_FILTER_MODEL:
      return { ...state, filterModel: payload };
    case CLEAR_AGENT_PRODUCTION_FOR_AGENCY_FILTER_MODEL:
      return {
        ...state,
        filterModel: {
          items: [],
        },
      };
    case AGENT_PRODUCTION_FOR_AGENCY_LOADING:
      return { ...state, isFetchingProduction: payload };
    default:
      return state;
  }
};
