import LineChartModelMUI from "./LineChartModelMUI";

const LineChartBody = (props) => {
  const {data} = props;

  const getProductData = (productData, product) => {
    if (!Array.isArray(productData)) {
      return [];
    }
    const lineDataByProduct = productData.filter(
      (item) => item.product === product
    );
    return reduceData(lineDataByProduct);
  };

  const reduceData = (productData) => {
    return productData.reduce((acc, currentObj) => {
      const lastItem = acc[acc.length - 1];
      const newItem = {
        quarter: currentObj.quarter,
        Year1: 0,
        Year2: 0,
        Year3: 0,
      };

      if (!lastItem || lastItem.quarter !== currentObj.quarter) {
        return [...acc, newItem];
      }

      lastItem.Year1 += currentObj.Year1;
      lastItem.Year2 += currentObj.Year2;
      lastItem.Year3 += currentObj.Year3;

      return acc;
    }, []);
  };

  const lineDataItems = ["MA", "MS", "PDP"].map((product) => ({
    lineData: getProductData(data, product),
    product,
  }));

  return lineDataItems.map((item, index) => (
    <LineChartModelMUI key={index} {...item} />
  ));
};

export default LineChartBody;