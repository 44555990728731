import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const getStyles = (name, personName, theme) => {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
};

const SelectChipContractStatuses = (props) => {
  const { records, checkedValues, setCheckedValues } = props;
  const theme = useTheme();
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setCheckedValues(typeof value === "string" ? value.split(",") : value);
  };

  const RenderChips = ({ selected }) => (
    <Box sx={{ display: "flex", flexWrap: "wrap" }}>
      {selected.map((value) => (
        <Chip key={value} label={value} />
      ))}
    </Box>
  );

  return (
    <div>
      <FormControl sx={{ m: 1, width: 220, margin: 2 }}>
        <InputLabel id="demo-multiple-chip-label">Contract Status</InputLabel>
        <Select
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          multiple
          value={checkedValues}
          onChange={handleChange}
          input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
          renderValue={(selected) => <RenderChips selected={selected} />}
          MenuProps={MenuProps}
          sx={{ backgroundColor: 'white' }}
        >
          {records.map((item) => (
            <MenuItem
              key={item}
              value={item}
              style={getStyles(item, checkedValues, theme)}
            >
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default SelectChipContractStatuses;
