import { Grid, Typography, Paper } from "@mui/material";
import LineChartComponent from "./LineCharts/LineChartComponent";

const ProductionInformation = (props) => {
  const { agencyId, classes } = props;

  return (
    <Grid container direction="row" justify="center" alignItems="center">
      <Grid item xs={12}>
        <Typography
          className={classes.typography}
          gutterBottom
          variant="h5"
          component="h3"
          style={{ padding: 4 }}
        >
          Production By Year and Quarter
        </Typography>
        <Paper className={classes.paper}>
          <LineChartComponent agencyId={agencyId}/>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default ProductionInformation;
