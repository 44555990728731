import {
  SET_DOWN_LINE_COMMUNICATIONS,
  SET_DOWN_LINE_COMMUNICATIONS_ERROR,
  CLEAR_DOWN_LINE_COMMUNICATIONS,
  DOWN_LINE_COMMUNICATIONS_LOADING,
} from "../../constants/action-types";

const initState = {
  downLineCommunicationsData: [],
  downLineCommunicationsLoading: false,
  setDownLineCommunicationsError: null,
};

export const downLineCommunications = (state = initState, { type, payload }) => {
  switch (type) {
    case SET_DOWN_LINE_COMMUNICATIONS:
      return { ...state, downLineCommunicationsData: payload };
    case SET_DOWN_LINE_COMMUNICATIONS_ERROR:
      return { ...state, setDownLineCommunicationsError: payload };
    case CLEAR_DOWN_LINE_COMMUNICATIONS:
      return { ...state, downLineCommunicationsData: [] };
    case DOWN_LINE_COMMUNICATIONS_LOADING:
      return { ...state, downLineCommunicationsLoading: payload };
    default:
      return state;
  }
};
