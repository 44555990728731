import {
  SET_AGENCY_READY_TO_SELL_BY_CARRIER,
  SET_AGENCY_READY_TO_SELL_BY_CARRIER_ERROR,
  CLEAR_AGENCY_READY_TO_SELL_BY_CARRIER,
  AGENCY_READY_TO_SELL_BY_CARRIER_LOADING,
  AGENCY_READY_TO_SELL_BY_CARRIER_LOADED,
} from "../../constants/action-types";
import { getCertsPerCarrier } from "../../../services/GraphQl/BDSWebService.js";

export const setAgencyReadyToSellByCarrier = (data) => {
  return {
    type: SET_AGENCY_READY_TO_SELL_BY_CARRIER,
    payload: data,
  };
};

export const setAgencyReadyToSellByCarrierError = (error) => {
  return {
    type: SET_AGENCY_READY_TO_SELL_BY_CARRIER_ERROR,
    payload: error,
  };
};

export const clearAgencyReadyToSellByCarrier = () => {
  return {
    type: CLEAR_AGENCY_READY_TO_SELL_BY_CARRIER,
  };
};

export const agencyReadyToSellByCarrierLoading = () => {
  return {
    type: AGENCY_READY_TO_SELL_BY_CARRIER_LOADING,
  };
};

export const setAgencyReadyToSellByCarrierLoading = (data) => {
  return {
    type: AGENCY_READY_TO_SELL_BY_CARRIER_LOADING,
    payload: data,
  };
};

export const setAgencyReadyToSellByCarrierLoaded = (bool) => {
  return {
    type: AGENCY_READY_TO_SELL_BY_CARRIER_LOADED,
    payload: bool,
  };
};

export const fetchAgentReadyToSellByCarrier = (agencyId) => {
  return async (dispatch) => {
    dispatch(setAgencyReadyToSellByCarrierLoading(true));
    try {
      let ProResultData = await getCertsPerCarrier(agencyId);
      let ProResult = ProResultData.data
        ? ProResultData.data.getCertsPerCarrier
        : ProResultData;
      dispatch(setAgencyReadyToSellByCarrier(ProResult));
      dispatch(setAgencyReadyToSellByCarrierLoaded(true));
    } catch (error) {
      dispatch(setAgencyReadyToSellByCarrierError(error));
    } finally {
      dispatch(setAgencyReadyToSellByCarrierLoading(false));
    }
  };
};
