import { LineChart } from '@mui/x-charts/LineChart';
import { Grid } from "@mui/material";

const LineChartModelMUI = (props) => {
  const { lineData, product } = props;  

  if (lineData.length > 0) {
    const xLabels = lineData.map((data) => data.quarter);
    const yLabels1 = lineData.map((data) => data.Year1);
    const yLabels2 = lineData.map((data) => data.Year2);
    const yLabels3 = lineData.map((data) => data.Year3);
    return (
      <div style={{ width: "98%" }}>
        <Grid container direction="column" justifyContent="right">
          <Grid item padding={1}>
            <label>{product}</label>
          </Grid>
        </Grid>

        <Grid container justifyContent="center" alignItems="center">
          <LineChart
            height={102}
            series={[
              {
                curve: "natural",
                type: 'line',
                data: yLabels1,
                name: 'Year1',
                color: '#F3A3F1',
              },
              {
                curve: "natural",
                type: 'line',
                data: yLabels2,
                name: 'Year2',
                color: '#040400',
              },
              {
                curve: "natural",
                type: 'line',
                data: yLabels3,
                name: 'Year3',
                color: '#33A1FF',
              },
            ]}
            xAxis={[{ scaleType: 'point', data: xLabels, gridLines: { display: true } }]}
            margin={{ top: 10, bottom: 20 }}
          />
        </Grid>
      </div>
    );
  } else {
    return null;
  }
};

export default LineChartModelMUI;