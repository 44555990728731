import React, { useState } from "react";
import Box from "@mui/material/Box";
import {
  GridToolbarContainer,
} from "@mui/x-data-grid-pro";
import classes from "../../../../../components/DataGridTemplate/DataGridTemplate.module.css";
import StripedDataGridTemplate from "../../../../DataGridTemplate/StripedDataGridTemplate";
import AgentReadyToSellProductsColumns from "./AgentReadyToSellProductsColumns";
import AgentReadyToSellProductsData from "./AgentReadyToSellProductsData";

const AgentReadyToSellProducts = (props) => {
  const { data } = props;
  const isLoading = false;
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 4,
    page: 0,
  });
 
  const columns = AgentReadyToSellProductsColumns();
  const uniqueRows = AgentReadyToSellProductsData({
    data: data,
  });

  const sxToolBarContainer = { fontSize: 12, paddingBottom: 1 };

  /// Custom toolbar for the DataGrid
  const CustomToolbar = () => {
    return (
      <GridToolbarContainer sx={sxToolBarContainer}>        
      </GridToolbarContainer>
    );
  };

  const StripedDataGrid = StripedDataGridTemplate();

  const sxBox = {
    height: "300",
    width: "100%",
    "& .super-app-theme--header": {
      backgroundColor: "rgba(215, 139, 255, 0.8)",
    },
    "& .super-app.true": {
      color: "green",
      fontWeight: "600",
    },
    "& .super-app.false": {
      color: "red",
      fontWeight: "600",
    },
  };
  
  const getRowClassName = (params) => {
    return params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd";
  };

  /// Return the DataGrid
  return (
    <div className={classes.myClass}>
      <Box sx={sxBox}>
        <StripedDataGrid
          slots={{ toolbar: CustomToolbar }}
          loading={isLoading}
          rows={uniqueRows.sort((a, b) =>
            a.carrierName.localeCompare(b.carrierName)
          )}
          columns={columns}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          pageSizeOptions={[4, 6, 8]}
          pagination={true}
          disableRowSelectionOnClick
          getRowClassName={getRowClassName}          
          density="compact"
        />
      </Box>
    </div>
  );
};

export default AgentReadyToSellProducts;