import {
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid-pro";

const DataGridContainerButtons = (props) => {
  const { containerButtons } = props;

  const StyleSXTemplate = {
    sxToolBarContainer: { fontSize: 12, paddingBottom: 1 },
    sxToolBarColumnsButton: {
      color: "black",
      backgroundColor: "whitesmoke",
    },
  };

  if (containerButtons === "FilterExport")
    return (
      <GridToolbarContainer sx={StyleSXTemplate.sxToolBarContainer}>
        <GridToolbarFilterButton sx={StyleSXTemplate.sxToolBarColumnsButton} />
        <GridToolbarExport sx={StyleSXTemplate.sxToolBarColumnsButton} />
      </GridToolbarContainer>
    );
  else if (containerButtons === "All")
    return (
      <GridToolbarContainer sx={StyleSXTemplate.sxToolBarContainer}>
        <GridToolbarFilterButton sx={StyleSXTemplate.sxToolBarColumnsButton} />
        <GridToolbarExport sx={StyleSXTemplate.sxToolBarColumnsButton} />
        <GridToolbarColumnsButton sx={StyleSXTemplate.sxToolBarColumnsButton} />
        <GridToolbarDensitySelector
          sx={StyleSXTemplate.sxToolBarColumnsButton}
        />
      </GridToolbarContainer>
    );
    else if (containerButtons === "None")
        return(
            <GridToolbarContainer sx={StyleSXTemplate.sxToolBarContainer}>
            </GridToolbarContainer>)
};

export default DataGridContainerButtons;
