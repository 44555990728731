const AgentProductionData = (props) => {
    const { data, currentYear } = props;
    const uniqueRows = [];
  
    const dataRows = data ? data.map((row) => ({
        id: row.agencyId + row.agentId + row.carrierId + row.salesYear,
        carrierName: row.carrierName,
        maProduction: row.maProduction,
        medsuppProduction: row.medsuppProduction,
        pdpProduction: row.pdpProduction,
        salesYear: row.salesYear,
      })) : [];
  
    let maGrandProduction = 0;
    let medsuppGrandProduction = 0;
    let pdpGrandProduction = 0;
  
    const calculateProduction = (id, salesYear) => {
      let maProduction = 0;
      let medsuppProduction = 0;
      let pdpProduction = 0;
      const carrierName = "Total";
  
      uniqueRows.forEach((row) => {
        if (row.salesYear === salesYear) {
          maProduction += parseInt(row.maProduction);
          medsuppProduction += parseInt(row.medsuppProduction);
          pdpProduction += parseInt(row.pdpProduction);
        }
      });
  
      maGrandProduction += maProduction;
      medsuppGrandProduction += medsuppProduction;
      pdpGrandProduction += pdpProduction;
  
      return {
        id,
        carrierName,
        maProduction,
        medsuppProduction,
        pdpProduction,
        salesYear,
      };
    };
  
    const calculateOverallProduction = () => ({
      id: "grandTotal",
      carrierName: "Grand Total",
      maProduction: maGrandProduction,
      medsuppProduction: medsuppGrandProduction,
      pdpProduction: pdpGrandProduction,
      salesYear: "All",
    });
  
    dataRows.forEach((row) => {
      if (!uniqueRows.find((existingRow) => existingRow.id === row.id)) {
        uniqueRows.push(row);
      }
    });
  
    uniqueRows.push(calculateProduction("currentYear", parseInt(currentYear)));
    uniqueRows.push(calculateProduction("previousYear", parseInt(currentYear) - 1));
    uniqueRows.push(calculateProduction("twoYearsAgo", parseInt(currentYear) - 2));
    uniqueRows.push(calculateOverallProduction());
  
    return uniqueRows;
  };
  
  export default AgentProductionData;