import { combineReducers } from "redux";
import { productionReducer } from "./production/productionReducer";
import { currentProduction } from "./production/CurrentProduction";
import { productionLineGraph } from "./production/productionLineGraph";
import { currentNotifications } from "./notifications/notifications";
import { contracts } from "./contracts";
import { agentListAgencies } from "./agents/agencies";
import { downLineCommunications } from "./production/downLineCommunications";
import {
  contractFormData,
  statesOptionsData,
  agentListData,
} from "./dashboard/ContractForm";
import { planChange } from "./plan-change/planChangeReducer";
import { planChangeCarriers } from "./plan-change/planChangeCarriersReducer";
import { currentContractRequests } from "./contract-requests/contractRequests";
import { agents } from "./agents/agents";
import { readyToSell } from "./agents/readyToSell";
import { agentsReadyToSellInfo } from "./agents/agentsReadyToSellInfo";
import { agentsContractInfo } from "./agents/agentsContractInfo";
import { recruiterStore } from "./recruiter-info/recruiterInfo";
import { agentProductionForAgency } from "./agents/agentProductionForAgency";
import { CLEAR_ALL, GET_ALL, GET_ALL_ERROR } from "../constants/action-types";
import { readyToSellByCarrier } from "./agents/readyToSellByCarrier";
import { commissionLossReport } from "./production/commissionLossReport";

const appReducer = combineReducers({
  agents,
  contracts,
  currentProduction,
  production: productionReducer,
  agentListAgencies,
  contractFormData,
  statesOptionsData,
  agentListData,
  planChange,
  planChangeCarriers,
  currentNotifications,
  currentContractRequests,
  recruiterStore,
  readyToSell,
  agentsReadyToSellInfo,
  agentsContractInfo,
  agentProductionForAgency,
  productionLineGraph,
  readyToSellByCarrier,
  downLineCommunications,
  commissionLossReport,
});

const initState = appReducer({}, {});
const rootReducer = (state = initState, action) => {
  switch (action.type) {
    case CLEAR_ALL:
      state = initState;
      break;
    case GET_ALL:
      state = { ...state, ...action.payload };
      break;
    case GET_ALL_ERROR:
      state = { ...state, FetchingAgentsError: action.payload };
      break;
    default:
      break;
  }
  return appReducer(state, action);
};

export default rootReducer;
