import React, { useState, useEffect, Fragment } from "react";
import PageContainerWithMenu from "../../../containers/PageContainerWithMenu";
import { Grid } from "@mui/material";
import SelectChipCarriers from "./components/SelectChipCarriers";
import PlainMenu from "../../PlainMenu";
import SelectChipContractStatuses from "./components/SelectChipContractStatuses";
import ModalMyAgents from "./components/ModalMyAgents";
import SelectChipLevels from "./components/SelectChipLevels";
import SelectAgencyGroups from "./components/SelectAgencyGroups";
import TabAgents from "./components/TabAgents";
import ButtonReset from "./components/ButtonReset";
import PopperNotes from "./components/PopperNotes";
import Card from "@mui/material/Card";
import { useDispatch, useSelector } from "react-redux";
import { fetchAgentList } from "../../../store/actions/agents/agents";
import ButtonSendNotification from "./components/ButtonSendNotification";
import {
  setReadyToSellFilterModel,
  fetchReadyToSellList,
} from "../../../store/actions/agents/readyToSell";
import { fetchAgentContractList } from "../../../store/actions/agents/agentsContractInfo";
import {
  setAgentsReadyToSellInfoFilterModel,
  fetchAgentReadyToSellInfo,
} from "../../../store/actions/agents/agentsReadyToSellInfo";
import ButtonResetIconOnly from "./components/ButtonResetIconOnly";

const AgentInfo = (props) => {
  const [checkedCarrierValues, setCheckedCarrierValues] = useState([]);
  const [checkedLevelValues, setCheckedLevelValues] = useState([]);
  const [checkedContractStatusValues, setCheckedContractStatusValues] =
    useState([]);
  const [isSendNotificationOpen, setIsSendNotificationOpen] = useState(false);
  const [allCarrierValues, setAllCarrierValues] = useState([]);
  const [allLevelValues, setAllLevelValues] = useState([]);
  const [allContractStatusValues, setAllContractStatusValues] = useState([]);
  const [allAgencyGroupValues, setAllAgencyGroupValues] = useState([]);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [agencyId, setAgencyId] = useState(sessionStorage.getItem("agencyId"));
  const readyToSellData = useSelector((state) => state.readyToSell.data);

  const isFetchingRTSList = useSelector(
    (state) => state.readyToSell.isFetchingRTSList
  );
  const agentsReadyToSellInfoData = useSelector(
    (state) => state.agentsReadyToSellInfo.data
  );
  const agentsReadyToSellInfoIsLoaded = useSelector(
    (state) => state.agentsReadyToSellInfo.isLoaded
  );
  const isFetchingRTSInfo = useSelector(
    (state) => state.agentsReadyToSellInfo.isFetchingRTSInfo
  );
  const agentsContractInfoData = useSelector(
    (state) => state.agentsContractInfo.data
  );
  const isFetchingContractData = useSelector(
    (state) => state.agentsContractInfo.isFetchingAgentContractInfo
  );
  const agentData = useSelector((state) => state.agents.data);
  const isAgentDataLoading = useSelector(
    (state) => state.agents.isFetchingAgentList
  );
  const [tabCheck, setTabCheck] = useState("one");

  const dispatch = useDispatch();

  useEffect(() => {
    if (agencyId === "") {
      setAgencyId(sessionStorage.getItem("agencyId"));
    }

    const fetchData = async () => {
      if (
        agencyId !== "" &&
        readyToSellData.length === 0 &&
        !isFetchingRTSList
      ) {
        dispatch(fetchReadyToSellList(agencyId));
      }
      if (
        agencyId !== "" &&
        agentsReadyToSellInfoIsLoaded === false &&
        !isFetchingRTSInfo
      ) {
        dispatch(fetchAgentReadyToSellInfo(parseInt(agencyId)));
      }
      if (
        agencyId !== "" &&
        agentsContractInfoData.length === 0 &&
        !isFetchingContractData
      ) {
        dispatch(fetchAgentContractList(parseInt(agencyId)));
      }

      if (agentData && agentData.length === 0 && !isAgentDataLoading) {
        dispatch(fetchAgentList());
      } else if (agentData && agentData.length > 0) {
        setData(agentData.filter((row) => row.agencyId === agencyId));
      }
    };
    fetchData();
  }, [
    agentData,
    dispatch,
    agencyId,
    readyToSellData,
    agentsContractInfoData,
    agentsReadyToSellInfoData,
    isAgentDataLoading,
    isFetchingRTSList,
    isFetchingContractData,
    isFetchingRTSInfo,
    agentsReadyToSellInfoIsLoaded,
  ]);

  useEffect(() => {
    if (data.length > 0) {
      setFilteredData(data.filter((row) => row.agencyId === agencyId));
    }
  }, [data, agencyId]);

  useEffect(() => {
    if (data.length > 0) {
      let results = data.filter((row) => row.agencyId === agencyId);

      const filterResultsArray = (checkedValues, key) => {
        if (checkedValues.length > 0) {
          results = results.filter((row) => checkedValues.includes(row[key]));
        }
      };

      filterResultsArray(checkedCarrierValues, "carrierName");
      filterResultsArray(checkedLevelValues, "agentLevel");
      filterResultsArray(checkedContractStatusValues, "contractStatus");
      setFilteredData(results);
    }
  }, [
    data,
    agencyId,
    checkedCarrierValues,
    checkedLevelValues,
    checkedContractStatusValues,
  ]);

  useEffect(() => {
    const getContractStatusValue = () => {
      switch (props.location.state.state.contractStatusValue.toUpperCase()) {
        case "APPROVED - TOTAL":
          return ["APPROVED", "APPROVED_NOT_SELLING"];
        case "APPROVED - INTENT TO MOVE AWAY SUBMITTED":
          return ["APPROVED_INTENT_TO_MOVE_AWAY_SUBMITTED"];
        case "APPROVED - RELEASED":
          return ["APPROVED_RELEASING"];
        case "INTENT TO MOVE":
          return ["INTENT_MOVE_BIG"];
        case "IN PROCESS":
          return [
            "PENDING_WRITING",
            "RECEIVED NOT SUBMITTED",
            "SUBMITTED",
            "SUBMITTED_MISSING_DOC",
            "SUBMITTED_NO_CERT",
          ];
        case "TERMINATED":
          return ["TERMINATED (SELF RELEASE)"];
        case "WITHDRAWN":
          return [
            "WITHDRAWN",
            "WITHDRAWN (CARRIER DENIED)",
            "WITHDRAWN (FAILED TO CERTIFY)",
            "WITHDRAWN (INCOMPLETE/NON-RESPONSIVE)",
          ];
        default:
          return [];
      }
    };
    const createSetFromFilteredData = (key) =>
      [...new Set(filteredData.map((row) => row[key]))].filter(
        (value) => value !== null && value !== "" && value !== undefined
      );
    const createSetFromFilteredDataAndIndex = (key, idKey) => {
      const uniqueData = filteredData.reduce((acc, row) => {
        if (
          !acc.find((item) => item.id === row[idKey] && item.value === row[key])
        ) {
          acc.push({ id: row[idKey], value: row[key] });
        }
        return acc.sort((a, b) =>
          String(a.value).localeCompare(String(b.value))
        );
      }, []);

      return uniqueData.filter(
        (item) =>
          item.value !== null && item.value !== "" && item.value !== undefined
      );
    };

    if (allCarrierValues.length === 0 && filteredData.length > 0) {
      setAllCarrierValues(createSetFromFilteredData("carrierName"));
    }
    if (allLevelValues.length === 0 && filteredData.length > 0) {
      setAllLevelValues(createSetFromFilteredData("agentLevel"));
    }
    if (allContractStatusValues.length === 0 && filteredData.length > 0) {
      setAllContractStatusValues(createSetFromFilteredData("contractStatus"));
      if (props.location.state !== undefined) {
        setCheckedContractStatusValues(getContractStatusValue());
      }
    }
    if (allAgencyGroupValues.length === 0 && filteredData.length > 0) {
      setAllAgencyGroupValues(
        createSetFromFilteredDataAndIndex("subagencyName", "subagencyId")
      );
    }
    setIsLoading(false);
  }, [
    filteredData,
    allAgencyGroupValues,
    allCarrierValues,
    allLevelValues,
    allContractStatusValues,
    props.location.state,
  ]);

  useEffect(() => {
    const getMaxYear = (readyToSellData) => {
      const years = readyToSellData.map((row) => row.planYear);
      const uniqueYears = [...new Set(years)]; // Use Set to get unique years
      return Math.max(...uniqueYears);
    };

    const maxYear = getMaxYear(readyToSellData); // Call the function with the data

    dispatch(
      setReadyToSellFilterModel({
        items: [
          {
            id: 0,
            field: "planYear",
            operator: "equals",
            value: maxYear.toString(), // Use the result of the function call
          },
        ],
      })
    );
  }, [dispatch, readyToSellData]);

  useEffect(() => {
    const getMaxYear = (agentsReadyToSellInfoData) => {
      const years = agentsReadyToSellInfoData.map((row) => row.planYear);
      const uniqueYears = [...new Set(years)]; // Use Set to get unique years
      return Math.max(...uniqueYears);
    };

    const maxYear = getMaxYear(agentsReadyToSellInfoData); // Call the function with the data

    dispatch(
      setAgentsReadyToSellInfoFilterModel({
        items: [
          {
            id: 0,
            field: "planYear",
            operator: "equals",
            value: maxYear.toString(),
          },
        ],
      })
    );
  }, [dispatch, agentsReadyToSellInfoData]);

  const SelectChipWrapper = ({
    Component,
    records,
    checkedValues,
    setCheckedValues,
    ...props
  }) => (
    <Component
      records={records.sort((a, b) => String(a).localeCompare(String(b)))}
      checkedValues={checkedValues}
      setCheckedValues={setCheckedValues}
      {...props}
    />
  );

  return (
    <Fragment>
      <PageContainerWithMenu
        data-tour="ad__home"
        type="agents"
        HasMenu={true}
        menu={
          <PlainMenu
            title="My Agents"
            submenu={
              <Grid
                container
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center", // This will center the Card horizontally
                  alignItems: "center", // This will center the Card vertically
                  height: "100%",
                }}
              >
                {!isLoading && (
                  <Fragment>
                    {tabCheck === "one" && (
                      <Card
                        style={{
                          margin: "10px",
                          background: "transparent",
                          color: "black",
                          size: "md",
                          varient: "outlined",
                          alignContent: "center",
                        }}
                      >
                        <Fragment>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "-15px",
                              justifyContent: "center",
                            }}
                          >
                            <SelectChipWrapper
                              Component={SelectChipCarriers}
                              records={allCarrierValues}
                              checkedValues={checkedCarrierValues}
                              setCheckedValues={setCheckedCarrierValues}
                            />
                            <ButtonResetIconOnly
                              setCheckedCarrierValues={setCheckedCarrierValues}
                              type="carrier"
                            />
                          </div>

                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "-15px",
                              justifyContent: "center",
                            }}
                          >
                            <SelectChipWrapper
                              Component={SelectChipContractStatuses}
                              records={allContractStatusValues}
                              checkedValues={checkedContractStatusValues}
                              setCheckedValues={setCheckedContractStatusValues}
                            />
                            <ButtonResetIconOnly
                              setCheckedContractStatusValues={
                                setCheckedContractStatusValues
                              }
                              type="contract"
                            />
                          </div>

                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "-15px",
                              justifyContent: "center",
                            }}
                          >
                            <SelectChipWrapper
                              Component={SelectChipLevels}
                              records={allLevelValues}
                              checkedValues={checkedLevelValues}
                              setCheckedValues={setCheckedLevelValues}
                            />
                            <ButtonResetIconOnly
                              setCheckedLevelValues={setCheckedLevelValues}
                              type="level"
                            />
                          </div>

                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "-15px",
                              justifyContent: "center",
                            }}
                          >
                            <SelectAgencyGroups
                              records={allAgencyGroupValues}
                              agencyId={agencyId}
                              setAgencyId={setAgencyId}
                            />
                            <ButtonResetIconOnly
                              setAgencyId={setAgencyId}
                              type="agencyGroup"
                            />
                          </div>

                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "center",
                              marginBottom: "-15px",
                            }}
                          >
                            <ButtonReset
                              setCheckedCarrierValues={setCheckedCarrierValues}
                              setCheckedLevelValues={setCheckedLevelValues}
                              setCheckedContractStatusValues={
                                setCheckedContractStatusValues
                              }
                              setAgencyId={setAgencyId}
                            />
                          </div>
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "center",
                              marginBottom: "-10px",
                            }}
                          >
                            <ButtonSendNotification
                              isOpen={isSendNotificationOpen}
                              setIsOpen={setIsSendNotificationOpen}
                              filteredAgents={filteredData}
                            />
                          </div>
                        </Fragment>
                      </Card>
                    )}
                    <PopperNotes />
                  </Fragment>
                )}
              </Grid>
            }
          />
        }
        content={
          <TabAgents
            filteredData={filteredData}
            checkedCarrierValues={checkedCarrierValues}
            checkedContractStatusValues={checkedContractStatusValues}
            checkedLevelValues={checkedLevelValues}
            agencyId={agencyId}
            setTabCheck={setTabCheck}
            agentsContractInfoData={agentsContractInfoData}
            isAgentDataLoading={isAgentDataLoading}
          />
        }
      />
      <ModalMyAgents />
    </Fragment>
  );
};

export default AgentInfo;
