import { useEffect } from "react";
import { Typography, Paper } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { fetchAgentReadyToSellByCarrier } from "../../../../store/actions/agents/readyToSellByCarrier.js";
import ReadyToSellByCarrierDataGrid from "./ReadyToSellByCarrierDataGrid.jsx";
import classes from "./ReadyToSellByCarrier.module.css";

const ReadyToSellByCarrier = () => {
  const agencyId = sessionStorage.getItem("agencyId");
  const dispatch = useDispatch();
  const {
    agencyReadyToSellByCarrier,
    isFetchingRTSByCarrier,
    RTSByCarrierLoaded,
  } = useSelector((state) => state.readyToSellByCarrier);

  useEffect(() => {
    const fetchData = async () => {
      if (
        agencyReadyToSellByCarrier.length === 0 &&
        !RTSByCarrierLoaded &&
        !isFetchingRTSByCarrier
      ) {
        dispatch(fetchAgentReadyToSellByCarrier(parseInt(agencyId)));
      }
    };
    fetchData();
  }, [
    dispatch,
    agencyId,
    agencyReadyToSellByCarrier,
    isFetchingRTSByCarrier,
    RTSByCarrierLoaded,
  ]);

  return (
    <>
      <Typography
        className={classes.typography}
        gutterBottom
        variant="h5"
        component="h3"
      >
        {`Ready to Sell (RTS) Agents by Carrier ${
          agencyReadyToSellByCarrier && agencyReadyToSellByCarrier.length > 0
            ? ` (${agencyReadyToSellByCarrier[0].planYear})`
            : ""
        }`}
      </Typography>
      <Paper className={classes.paper}>
        <ReadyToSellByCarrierDataGrid
          tableData={agencyReadyToSellByCarrier}
          isLoading={isFetchingRTSByCarrier}
        />
      </Paper>
    </>
  );
};

export default ReadyToSellByCarrier;
