const commonProps = {
  headerAlign: "center",
  align: "center",
  headerClassName: "super-app-theme--header",
  type: "string",
  cellClassName: "super-app-theme--cell",
  sortable: false,
  disableColumnMenu: true,
  flex: 1,
};

const columns = [
  {
    field: "product",
    headerName: "Product",
    width: 300,
  },
  {
    field: "state",
    headerName: "State",
    width: 100,
  },
];

const AgentReadyToSellProductsColumns = () => {
  return columns.map((column) => ({ ...commonProps, ...column }));
};

export default AgentReadyToSellProductsColumns;
