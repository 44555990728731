import React, { useEffect, useState, useCallback } from "react";
import {
  pushNotificationApi,
  sendMsGraphEmail,
  sendDashEmailAws,
} from "../../services/GraphQl/BDSWebService";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Typography,
} from "@mui/material";
import Swal from "sweetalert";

const SendCommunicationsDialog = ({ onClose, open, currentAgents }) => {
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");
  const [to, setTo] = useState([]);
  const [cc, setCc] = useState([]);
  const [bcc, setBcc] = useState([]);
  const [toError, setToError] = useState("");
  const [ccError, setCcError] = useState("");
  const [bccError, setBccError] = useState("");
  const [errorMessageTo, setErrorMessageTo] = useState("");
  const [errorMessageCc, setErrorMessageCc] = useState("");
  const [errorMessageBcc, setErrorMessageBcc] = useState("");
  const [filteredValues, setFilteredValues] = useState([]);
  const [title, setTitle] = useState("Email");

  const awayRegisteredAgents = useCallback(() => {
    return currentAgents.filter((agent) => agent.agentWayEnabled === true);
  }, [currentAgents]);

  useEffect(() => {
    if (title === "Notification") {
      setFilteredValues(awayRegisteredAgents());
    } else if (title === "Email") {
      setFilteredValues(currentAgents);
    }
  }, [title, currentAgents, awayRegisteredAgents]);

  const handleCommunicationTypeChange = (e) => {
    setTitle(e.target.value);
  };

  const validateEmailFromArr = (arr) => {
    let error = "";
    arr.forEach((email) => {
      if (email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]+$/i.test(email)) {
        error = `Invalid email address: ${email}`;
      }
    });
    return error;
  };

  const handleBlur = (e, type) => {
    if (e.target.value !== "") {
      switch (type) {
        case "To":
          setErrorMessageTo(
            `You must press enter to add ${e.target.value} to the ${type} list`
          );
          break;
        case "Cc":
          setErrorMessageCc(
            `You must press enter to add ${e.target.value} to the ${type} list`
          );
          break;
        case "Bcc":
          setErrorMessageBcc(
            `You must press enter to add ${e.target.value} to the ${type} list`
          );
          break;
        default:
          break;
      }
    }
  };

  const onFocus = (type) => {
    switch (type) {
      case "To":
        setErrorMessageTo("");
        break;
      case "Cc":
        setErrorMessageCc("");
        break;
      case "Bcc":
        setErrorMessageBcc("");
        break;
      default:
        break;
    }
  };

  const apiChunkCall = async (currentEmails, to, cc, bcc, subject, body) => {
    const isStaging = sessionStorage.getItem("isStaging") === "true";
    try {
      const chunk = 50;
      let i,
        j,
        temparray,
        firstHit = true;
      for (i = 0, j = currentEmails.length; i < j; i += chunk) {
        temparray = currentEmails.slice(i, i + chunk);
        await sendDashEmailAws({
          FromEmailAddress: "info-blast@theagencydashboard.com",
          Destination: {
            ToAddresses: to,
            CcAddresses: firstHit ? cc : [],
            BccAddresses: isStaging
              ? ["bit.testing@berwickInsurance.com"]
              : firstHit
              ? [...bcc, ...temparray]
              : temparray,
          },
          Content: {
            Simple: {
              Subject: { Data: subject },
              Body: { Text: { Data: body } },
            },
          },
        });
        firstHit = false;
      }
      return "Success";
    } catch (error) {
      const agencyId = sessionStorage.getItem("agencyId");
      await sendMsGraphEmail({
        from: "bit@berwickinsurance.com",
        to: ["billy.smith@berwickinsurance.com", "daniel@berwickinsurance.com"],
        subject: `Error Sending Email for Agency ${agencyId}`,
        body: `${error}`,
      });
      return "There was a problem sending your email - an email was sent to our administrators to look into it.";
    }
  };

  const sendEmail = async () => {
    const currentEmails =
      filteredValues.length > 0
        ? filteredValues.map(({ email }) => email)
        : currentAgents.map(({ email }) => email);
    if (filteredValues.length === 0) {
      Swal({
        text: "Please know you are about to email all Agents in your Agency, please click confirm to proceed.",
        title: "Email Confirmation",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (proceed) => {
        if (proceed) {
          const result = await apiChunkCall(
            currentEmails,
            to,
            cc,
            bcc,
            subject,
            body
          );
          const success = result === "Success";
          Swal({
            text: success ? "Your email was sent!" : result,
            title: success ? result : "Error",
            icon: success ? "success" : "error",
            dangerMode: !success,
          }).then(() => closeAll());
        }
      });
    } else {
      const result = await apiChunkCall(
        currentEmails,
        to,
        cc,
        bcc,
        subject,
        body
      );
      const success = result === "Success";
      Swal({
        text: success ? "Your email was sent!" : result,
        title: success ? result : "Error",
        icon: success ? "success" : "error",
        dangerMode: !success,
      }).then(() => closeAll());
    }
  };

  const handleChange = (e, type) => {
    switch (type) {
      case "subject":
        setSubject(e.target.value);
        break;
      case "body":
        setBody(e.target.value);
        break;
      default:
        break;
    }
  };

  /// Send AWAY Notification
  const handleSentNotes = async () => {
    if (filteredValues.length === 0) {
      Swal({
        text: "You have no agents selected.  Do you wish to send to all agents?.",
        title: "Agent Notification Confirmation",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (proceed) => {
        if (proceed) {
          setFilteredValues(awayRegisteredAgents());
        } else {
          alert(`Please select the agents to send this message to.`);
        }
      });
      return;
    }

    if (subject == null) {
      alert(`Please add a subject to the ` + title + ` message`);
      return;
    }
    if (body == null) {
      alert(`Please include the body of the ` + title + ` message`);
      return;
    }

    const agencyId = sessionStorage.getItem("agencyId");
    const input = {
      body,
      subject,
      agencyId,
      agentIds: filteredValues.map((i) => parseInt(i.agentId)),
      type: "Notification",
    };
    await pushNotificationApi(input);
    onClose();
  };

  const isEmail = title === "Email";

  const closeAll = () => {
    // closeAll logic here
  };

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="note-dialog-title">Send {title}</DialogTitle>
      <DialogContent>
        <Grid
          container
          alignItems="center"
          // className={classes.switchContainer}
        >
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            onChange={(e) => handleCommunicationTypeChange(e)}
            value={title}
          >
            {/* <FormControlLabel
              value="Notification"
              control={<Radio />}
              label="AgentWay"
            /> */}
            <FormControlLabel value="Email" control={<Radio />} label="Email" />
          </RadioGroup>
        </Grid>
        {title === "Email" && (
          <>
            *Emails are sent via our system email address - add cc or bcc below
            for any additional emails
            <br />
            <br />
            *Agents listed at the bottom will be sent an email through the Bcc
            field in addition to any emails you add
            <Autocomplete
              multiple
              options={to}
              getOptionLabel={(option) => option}
              freeSolo
              defaultValue={to}
              style={{ marginTop: 10 }}
              onBlur={(e) => handleBlur(e, "To")}
              onFocus={() => onFocus("To")}
              onChange={(e, newValue) => {
                const error = validateEmailFromArr(newValue);
                error ? setToError(error) : setTo(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Please insert your email address in this field"
                  required
                  error={toError || errorMessageTo}
                  helperText={
                    <Grid container>
                      <Grid item xs={12}>
                        {toError}
                      </Grid>
                      <Grid item xs={12}>
                        {errorMessageTo}
                      </Grid>
                    </Grid>
                  }
                />
              )}
            />
            <Autocomplete
              multiple
              options={cc}
              getOptionLabel={(option) => option}
              freeSolo
              defaultValue={cc}
              style={{ marginTop: 10 }}
              onBlur={(e) => handleBlur(e, "Cc")}
              onFocus={() => onFocus("Cc")}
              onChange={(e, newValue) => {
                const error = validateEmailFromArr(newValue);
                error ? setCcError(error) : setCc(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Cc"
                  error={ccError || errorMessageCc}
                  helperText={
                    <Grid container>
                      <Grid item xs={12}>
                        {ccError}
                      </Grid>
                      <Grid item xs={12}>
                        {errorMessageCc}
                      </Grid>
                    </Grid>
                  }
                />
              )}
            />
            <Autocomplete
              multiple
              options={bcc}
              getOptionLabel={(option) => option}
              freeSolo
              defaultValue={bcc}
              style={{ marginTop: 10 }}
              onBlur={(e) => handleBlur(e, "Bcc")}
              onFocus={() => onFocus("Bcc")}
              onChange={(e, newValue) => {
                const error = validateEmailFromArr(newValue);
                error ? setBccError(error) : setBcc(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Bcc"
                  error={bccError || errorMessageBcc}
                  helperText={
                    <Grid container>
                      <Grid item xs={12}>
                        {bccError}
                      </Grid>
                      <Grid item xs={12}>
                        {errorMessageBcc}
                      </Grid>
                    </Grid>
                  }
                />
              )}
            />
          </>
        )}
        {title === "Notification" && (
          <Typography
            variant="caption"
            align="center"
            component="p"
            style={{ color: "#333", fontSize: 16 }}
          >
            Note: ONLY agents that have
            <br /> activated AgentWay can be sent notifications
          </Typography>
        )}
        <TextField
          required
          margin="dense"
          id="subject"
          label="Subject"
          value={subject}
          onChange={(e) => handleChange(e, "subject")}
          fullWidth
        />
        <TextField
          required
          margin="dense"
          id="body"
          label="Message"
          multiline
          rows={2}
          value={body}
          onChange={(e) => handleChange(e, "body")}
          fullWidth
        />
        <Autocomplete
          multiple
          limitTags={1}
          id="multiple-limit-tags"
          style={{ marginTop: 10 }}
          options={
            title === "Notification" ? awayRegisteredAgents() : currentAgents
          }
          getOptionLabel={(option) => option.firstName + " " + option.lastName}
          defaultValue={
            title === "Notification" ? awayRegisteredAgents() : currentAgents
          }
          value={filteredValues}
          onChange={(e, newValue) => {
            setFilteredValues(newValue);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="AgentList"
              placeholder="Agent"
              helperText={
                <Typography variant="caption" color="error">
                  {!isEmail && awayRegisteredAgents().length === 0
                    ? `No Agents Currently Registered With Agent Way`
                    : ""}
                </Typography>
              }
            />
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            if (title === "Email") {
              setErrorMessageCc("");
              setErrorMessageBcc("");
            }
            onClose();
          }}
          color="primary"
        >
          Cancel
        </Button>
        <Button
          color="primary"
          onClick={title === "Email" ? sendEmail : handleSentNotes}
          disabled={
            title === "Email"
              ? !subject || !body || to.length === 0
              : !subject || !body || currentAgents.length === 0
          }
        >
          Send
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SendCommunicationsDialog;
