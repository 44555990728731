import {
  SET_COMMISSION_LOSS_REPORT,
  SET_COMMISSION_LOSS_REPORT_ERROR,
  CLEAR_COMMISSION_LOSS_REPORT,
  COMMISSION_LOSS_REPORT_LOADING,
} from "../../constants/action-types";

const initState = {
  commissionLossReportData: [],
  commissionLossReportLoading: false,
  setCommissionLossReportError: null,
};

export const commissionLossReport = (state = initState, { type, payload }) => {
  switch (type) {
    case SET_COMMISSION_LOSS_REPORT:
      return { ...state, commissionLossReportData: payload };
    case SET_COMMISSION_LOSS_REPORT_ERROR:
      return { ...state, setCommissionLossReportError: payload };
    case CLEAR_COMMISSION_LOSS_REPORT:
      return { ...state, commissionLossReportData: [] };
    case COMMISSION_LOSS_REPORT_LOADING:
      return { ...state, commissionLossReportLoading: payload };
    default:
      return state;
  }
};
