import {
  SET_AGENT_PRODUCTION_FOR_AGENCY,
  CLEAR_AGENT_PRODUCTION_FOR_AGENCY,
  AGENT_PRODUCTION_FOR_AGENCY_LOADING,
  SET_AGENT_PRODUCTION_FOR_AGENCY_FILTER_MODEL,
  CLEAR_AGENT_PRODUCTION_FOR_AGENCY_FILTER_MODEL,
} from "../../constants/action-types";
import { getAgentProductionForAgency } from "../../../services/GraphQl/BDSWebService";

/* redux-thunk implementation */
export const fetchAgentProductionForAgencyList = (agencyId, agentId) => {
  const currentYear = new Date().getFullYear().toString();
  return async (dispatch) => {
    dispatch(setAgentProductionLoading(true));
    await getAgentProductionForAgency(agencyId, agentId).then(async (res) => {
      if (res.length > 0) {
        dispatch(setAgentProductionForAgencyList(res));
      } else {
        dispatch(
          setAgentProductionForAgencyList([
            {
              agentId: agentId.toString(),
              agencyId: agencyId.toString(),
              salesYear: currentYear,
              maProduction: 0,
              medsuppProduction: 0,
              pdpProduction: 0,
              carrierName: "",
              carrierId: 0,
            },
          ])
        );
      }
    });
    dispatch(setAgentProductionLoading(false));
  };
};

export const clearAgentProductionForAgencyList = () => {
  return (dispatch) => {
    dispatch(clearAgentProductionForAgencyListAction([]));
  };
};

/* redux action */
const setAgentProductionForAgencyList = (data) => {
  return {
    type: SET_AGENT_PRODUCTION_FOR_AGENCY,
    payload: data,
  };
};

/* redux action */
const clearAgentProductionForAgencyListAction = (data) => {
  return {
    type: CLEAR_AGENT_PRODUCTION_FOR_AGENCY,
    payload: data,
  };
};

export const setAgentProductionForAgencyListFilterModel = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_AGENT_PRODUCTION_FOR_AGENCY_FILTER_MODEL,
      payload: data,
    });
  };
};

export const clearAgentProductionForAgencyListFilterModel = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_AGENT_PRODUCTION_FOR_AGENCY_FILTER_MODEL,
    });
  };
};

export const setAgentProductionLoading = (data) => {
  return (dispatch) => {
    dispatch({
      type: AGENT_PRODUCTION_FOR_AGENCY_LOADING,
      payload: data,
    });
  };
};
