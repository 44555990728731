import clsx from "clsx";

const commonProps = {
  headerAlign: "center",
  align: "center",
  headerClassName: "super-app-theme--header",
  cellClassName: "super-app-theme--cell",
  flex: 1,
};

const columns = [
  {   
    field: "createDate",
    headerName: "Sent Date",
    width: 200,
    type: "dateTime",
    valueGetter: (params) => {
      if (!params.row.createDate) {
        return null;
      }
      return new Date(params.row.createDate);
    },
    sortComparator: (cellParams1, cellParams2) => {
      const date1 =
        cellParams1 && cellParams1.value ? new Date(cellParams1.value) : null;
      const date2 =
        cellParams2 && cellParams2.value ? new Date(cellParams2.value) : null;

      if (!date1 && !date2) {
        return 0;
      }
      if (!date1) {
        return -1;
      }
      if (!date2) {
        return 1;
      }
      return date1.getTime() - date2.getTime();
    },
    flex: 0,
  },
  {    
    field: "subject",
    headerName: "Subject",
    width: 300,
    type: "string",
  },
  {    
    field: "body",
    headerName: "Body",
    width: 500,
    type: "string",
  },
  {
    field: "agents",
    headerName: "Recipients",
    width: 200,
    type: "string",
    flex: 0,
  },
  {
    field: "type",
    headerName: "Type",
    width: 200,
    type: "singleSelect",
    valueOptions: ["Contract Request Submitted", "Contract Request Denial"],
    cellClassName: (params) => {
      if (params.value == null) {
        return "";
      }
      return clsx("super-app", {
        submitted: params.value === "Contract Request Submitted",
        denied: params.value === "Contract Request Denial",
      });
    },
    flex: 0,
  },
];

const DownlineCommunicationsColumns = () => {
  return columns.map((column) => ({ ...commonProps, ...column }));
};

export default DownlineCommunicationsColumns;
