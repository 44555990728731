import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchDownLineCommunications } from "../../store/actions/production/downLineCommunications";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import locale from "date-fns/locale/en-US";
import classes from "../DataGridTemplate/DataGridTemplate.module.css";
import DataGridTemplate from "../DataGridTemplate/DataGridTemplate";
import DownLineCommunicationsColumns from "./DownLineCommunicationsColumns";
import DownLineCommunicationsData from "./DownLineCommunicationsData";
import { Box } from "@mui/system";

const DownLineCommunications = () => {
  const dispatch = useDispatch();
  const { downLineCommunicationsData, downLineCommunicationsLoading } =
    useSelector((state) => state.downLineCommunications);

  useEffect(() => {
    const agencyId = sessionStorage.getItem("agencyId");
    const paging = {
      first: 10000,
      orderBy: { field: "lastModifiedDate", sort: "desc" },
    };
    const filters = [{ field: "agencyId", comparison: "==", value: agencyId }];
    const fetchData = async () => {
      if (
        downLineCommunicationsData === undefined ||
        downLineCommunicationsData.length === 0
      ) {
        dispatch(fetchDownLineCommunications(paging, filters));
      }
    };
    fetchData();
  }, [dispatch, downLineCommunicationsData]);

  const columns = DownLineCommunicationsColumns();
  const uniqueRows = DownLineCommunicationsData({
    data: downLineCommunicationsData,
  });

  const sxBox = {
    height: 640,
    width: "100%",
    "& .super-app-theme--header": {
      backgroundColor: "rgba(27, 133, 243, 0.8)",
    },
    "& .super-app.submitted": {
      color: "green",
      fontWeight: "600",
    },
    "& .super-app.denied": {
      color: "red",
      fontWeight: "600",
    },
  };

  /// Return the DataGrid
  return (
    <div className={classes.myClass}>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
        <Box sx={sxBox}>
          <DataGridTemplate
            columns={columns}
            uniqueRows={uniqueRows}
            isLoading={downLineCommunicationsLoading}
            paginationProps={{
              rowsPerPageOptions: [25, 50, 100],
              pageSize: 25,
            }}
            CustomToolbar="All"
            density="standard"
            size="medium"
            hideFooter={false}
            autoHeight={false}
          />
        </Box>
      </LocalizationProvider>
    </div>
  );
};

export default DownLineCommunications;
