import React, { useMemo, useState } from "react";
import Box from "@mui/material/Box";
import {
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarExport,
} from "@mui/x-data-grid-pro";
import AgentContractColumns from "./AgentContractColumns";
import AgentContractData from "./AgentContractData";
import classes from "../../../DataGridTemplate/DataGridTemplate.module.css";
import StripedDataGridTemplate from "../../../DataGridTemplate/StripedDataGridTemplate";

const AgentContractInfo = (props) => {
  const { agentId, data } = props;
  const columns = useMemo(() => AgentContractColumns(), []);
  const formattedData = useMemo(
    () => AgentContractData({ agentId, data }),
    [agentId, data]
  );

  const sxToolBarContainer = { fontSize: 12, paddingBottom: 1 };
  const sxToolBarColumnsButton = {
    color: "black",
    backgroundColor: "whitesmoke",
  };

  /// Custom toolbar for the DataGrid
  const CustomToolbar = () => {
    return (
      <GridToolbarContainer sx={sxToolBarContainer}>
        <GridToolbarFilterButton sx={sxToolBarColumnsButton} />
        <GridToolbarExport sx={sxToolBarColumnsButton} />
      </GridToolbarContainer>
    );
  };

  const StripedDataGrid = StripedDataGridTemplate();

  const sxBox = {
    height: 315,
    width: "100%",
    "& .super-app-theme--header": {
      backgroundColor: "rgba(184, 73, 255, 0.8)",
    },
    "& .super-app.true": {
      color: "green",
      fontWeight: "600",
    },
    "& .super-app.false": {
      color: "red",
      fontWeight: "600",
    },
  };
  const getRowClassName = (params) => {
    return params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd";
  };

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 5,
    page: 0,
    pagenation: true,
  });

  /// Return the DataGrid
  return (
    <div className={classes.myClass}>
      <Box sx={sxBox}>
        <StripedDataGrid
          slots={{ toolbar: CustomToolbar }}
          rows={[...formattedData].sort((a, b) =>
            a.carrierName.localeCompare(b.carrierName)
          )}
          columns={columns}
          columnVisibilityModel={{ tacygcId: false }}
          onColumnVisibilityModelChange={() => {}}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          pageSizeOptions={[5, 10, 25]}
          pagination={true}
          disableRowSelectionOnClick
          getRowClassName={getRowClassName}
          density="compact"
        />
      </Box>
    </div>
  );
};

export default AgentContractInfo;
