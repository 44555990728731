import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import classes from "../../../DataGridTemplate/DataGridTemplate.module.css";
import DataGridTemplate from "../../../DataGridTemplate/DataGridTemplate";
import ReadyToSellByCarrierColumns from "./ReadyToSellByCarrierColumns";

const ReadyToSellByCarrierDataGrid = (props) => {
  const { tableData, isLoading } = props;
  const [data, setData] = useState([]);

  useEffect(() => {
    const results = [];

    if (tableData !== undefined || tableData.length > 0) {
      tableData.forEach((row) => {
        const carrierCount = row.count;
        const name = row.name;
        const planYear = row.planYear;
        const carrierId = row.carrierId;

        results.push({
          id: carrierId,
          carrierName: name,
          count: carrierCount,
          planYear: planYear,
        });
      });
      setData(results);
    }
  }, [tableData]);

  const columns = ReadyToSellByCarrierColumns();

  const sxBox = {
    height: 325,
    width: "auto",
    "& .super-app-theme--header": {
      backgroundColor: "rgba(27, 133, 243, 0.8)",
    },
  };

  return (
    <div className={classes.myClass}>
      <Box sx={sxBox}>
        {data && (
          <DataGridTemplate
            columns={columns}
            uniqueRows={data}
            isLoading={isLoading}
            CustomToolbar="None"
            density="compact"
            size="medium"
            hideFooter={true}
            autoHeight={true}
          />
        )}
      </Box>
    </div>
  );
};

export default ReadyToSellByCarrierDataGrid;
