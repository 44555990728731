import Box from "@mui/material/Box";
import classes from "../../../DataGridTemplate/DataGridTemplate.module.css";
import DataGridTemplate from "../../../DataGridTemplate/DataGridTemplate";
import ProductionByCarrierColumns from "./ProductionByCarrierColumns";
import DataGridNoResults from "../../../DataGridTemplate/DataGridNoResults";

const ProductionByCarrierDataGrid = (props) => {
  const { tableData, year, isLoading } = props;
  const columns = ProductionByCarrierColumns({ year });
  const sxBox = {
    height: "485px",
    width: "auto",
    "& .super-app-theme--header": {
      backgroundColor: "rgba(27, 133, 243, 0.8)",
    },
  };
  const StripedDataGridNoResults = () => {
    return <DataGridNoResults columns={columns} uniqueRows={tableData} />;
  };

  if (tableData.length === 0 && isLoading === false) {
    return (
      <div className={classes.myClass}>
        <Box sx={sxBox}>
          <StripedDataGridNoResults />
        </Box>
      </div>
    );
  } else {
    return (
      <div className={classes.myClass}>
        <Box sx={sxBox}>
          {tableData && (
            <DataGridTemplate
              columns={columns}
              uniqueRows={tableData}
              isLoading={isLoading}
              CustomToolbar="None"
              density="compact"
              hideFooter={true}
              autoHeight={true}
            />
          )}
        </Box>
      </div>
    );
  }
};

export default ProductionByCarrierDataGrid;
