import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { Button, ButtonGroup } from "@mui/material";
import { DateTime } from "luxon";
import { alpha, styled } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import locale from "date-fns/locale/en-US";
import { makeStyles } from "tss-react/mui";
import {
  DataGridPro,
  gridClasses,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
} from "@mui/x-data-grid-pro";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { AccountTree } from "@mui/icons-material";
import { useSelector } from "react-redux";
import AgentHierarchyModal from "../agentTileFiles/AgentHierarchyModal";
import ContractRequestModal from "../common/ContractRequestModal";
import DenyRequestModal from "./DenyRequestModal";
import moment from "moment";

const ContractRequests = (props) => {
  const { pendingContractsToggle } = props;
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [openAH, setOpenAH] = useState(false);
  const [agentName, setAgentName] = useState("");
  const [currentAgentId, setCurrentAgentId] = useState("");
  const [contractModalOpen, setContractModalOpen] = useState(false);
  const [rowData, setRowData] = useState({});
  const [denyModalOpen, setDenyModalOpen] = useState(false);
  const [uplineArr, setUplineArr] = useState([]);

  /// Get the contract requests from the Redux store.
  const contractRequests = useSelector(
    ({ currentContractRequests }) =>
      currentContractRequests.storedContractRequests
  );
  useEffect(() => {
    pendingContractsToggle === true
      ? setData(contractRequests.filter((r) => r.status === "pending"))
      : setData(contractRequests.filter((r) => r.status === "submitted"));
    setIsLoading(false);
    setOpenAH(false);
    setContractModalOpen(false);
    setDenyModalOpen(false);
  }, [contractRequests, pendingContractsToggle]);

  /// This function is used to create the CSS classes for the DataGrid.
  const useStyles = makeStyles()((theme) => {
    return {
      myClass: {
        height: 640,
        width: "100%",
      },
      uplinesButton: {
        color: "#eee",
        background: "rgba(83,83,83, .9)",
      },
      uplinesButtonHover: {
        "&:hover": {
          background: "#535353",
        },
      },
      uplinesButtonFocus: {
        "&:focus": {
          outline: "none",
        },
      },
      reviewButton: {
        color: "#eee",
        background: "rgb(0,128,0, .85)",
      },
      reviewButtonHover: {
        "&:hover": {
          background: "rgb(0,128,0)",
        },
      },
      reviewButtonFocus: {
        "&:focus": {
          outline: "none",
        },
      },
      denyButton: {
        background: "rgba(255,241,118, .9)",
      },
      denyButtonHover: {
        "&:hover": {
          background: "#fff176",
        },
      },
      denyButtonFocus: {
        "&:focus": {
          outline: "none",
        },
      },
    };
  });

  /// The classes object is used to get the CSS classes for the DataGrid.
  const { classes } = useStyles();

  /// This function is called when the uplines button is clicked.
  const handleUplineClick = (event, cellValues) => {
    if (cellValues === undefined) {
      setAgentName("");
      setCurrentAgentId("");
    } else {
      setAgentName(cellValues.row.agentName);
      setCurrentAgentId(cellValues.row.agentId);
    }
    setOpenAH(!openAH);
  };

  /// This function is called when the review button is clicked.
  const handleReviewClick = (event, cellValues) => {
    const row = {
      agentName: cellValues.row.agentName,
      createDate: cellValues.row.createDate,
      carrierName: cellValues.row.carrierName,
      states: cellValues.row.states,
      isAsCorp: cellValues.row.isAsCorp,
      agentId: cellValues.row.agentId,
    };
    setRowData(row);
    setContractModalOpen(true);
  };

  /// This function is called when the deny button is clicked.
  const handleDenyClick = (event, cellValues) => {
    const row = {
      agentName: cellValues.row.agentName,
      createDate: cellValues.row.createDate,
      carrierName: cellValues.row.carrierName,
      states: cellValues.row.states,
      isAsCorp: cellValues.row.isAsCorp,
      agentId: cellValues.row.agentId,
      id: cellValues.row.id,
    };
    setRowData(row);
    setDenyModalOpen(true);
  };

  const commonProps = {
    headerAlign: "center",
    align: "center",
    headerClassName: "super-app-theme--header",
    cellClassName: "super-app-theme--cell",
    flex: 1,
  };

  /// Columns for the DataGrid
  /// The field property is the name of the property in the data object.
  /// The headerName property is the name of the column header.
  /// The width property is the width of the column.
  /// The headerAlign property is the alignment of the column header.
  /// The align property is the alignment of the column cells.
  /// The headerClassName property is the CSS class name for the column header.
  /// The type property is the type of column.
  /// The valueOptions property is the list of options for the column.
  /// The valueGetter property is the function to get the value for the column.
  /// The sortComparator property is the function to sort the column.
  /// The cellClassName property is the CSS class name for the column cells.
  const columns = [
    {
      ...commonProps,
      field: "agentName",
      headerName: "Agent Name",
      width: 200,
      type: "string",
    },
    {
      ...commonProps,
      field: "createDate",
      headerName: "Request Date",
      width: 200,
      type: "date",
      valueFormatter: (params) => {
        return moment(params.value).format("L");
      },
      sortComparator: (v1, v2, cellParams1, cellParams2) => {
        const date1 =
          cellParams1 && cellParams1.value ? new Date(cellParams1.value) : null;
        const date2 =
          cellParams2 && cellParams2.value ? new Date(cellParams2.value) : null;

        if (!date1 && !date2) {
          return 0;
        }
        if (!date1) {
          return -1;
        }
        if (!date2) {
          return 1;
        }

        return date1.getTime() - date2.getTime();
      },
    },
    {
      ...commonProps,
      field: "carrierName",
      headerName: "Carrier Name",
      width: 300,
      type: "string",
    },
    {
      ...commonProps,
      field: "states",
      headerName: "States",
      width: 150,
      type: "string",
    },
    {
      ...commonProps,
      field: "isAsCorp",
      headerName: "Corp",
      width: 50,
      type: "singleSelect",
      sortable: false,
      valueOptions: ["true", "false"],
      renderCell: (params) => {
        return params.value === "true" ? (
          <CheckCircleIcon style={{ color: "green" }} />
        ) : (
          <CancelIcon style={{ color: "red" }} />
        );
      },
    },
    pendingContractsToggle === true
      ? {
          ...commonProps,
          field: "Action",
          headerName: "Action",
          sortable: false,
          filterable: false,
          width: 280,
          flex: 0,
          renderCell: (cellValues) => {
            return (
              <ButtonGroup variant="contained">
                <Button
                  className={`${classes.uplinesButton} ${classes.uplinesButtonHover} ${classes.uplinesButtonFocus}`}
                  endIcon={<AccountTree />}
                  onClick={(event) => handleUplineClick(event, cellValues)}
                >
                  Uplines
                </Button>
                <Button
                  className={`${classes.reviewButton} ${classes.reviewButtonHover} ${classes.reviewButtonFocus}`}
                  onClick={(event) => handleReviewClick(event, cellValues)}
                >
                  Review
                </Button>
                <Button
                  className={`${classes.denyButton} ${classes.denyButtonHover} ${classes.denyButtonFocus}`}
                  onClick={(event) => handleDenyClick(event, cellValues)}
                >
                  Deny
                </Button>
              </ButtonGroup>
            );
          },
        }
      : {
          ...commonProps,
          field: "status",
          headerName: "Status",
          width: 450,
          type: "string",
          sortable: false,
          filterable: false,
        },
  ];

  /// The uniqueRows array is used to store the unique rows.
  /// The dataRows array is used to store the data rows.
  const uniqueRows = [];
  const dataRows = data.map((row) => {
    const zone = DateTime.now().zoneName;
    const convertTimezone = new Date(row.createDate);
    return {
      id: row.id, // This is used to make the row unique.
      agentName: row.agentName,
      createDate: convertTimezone.toLocaleString("en-US", {
        timeZone: zone,
      }),
      carrierName: row.carrierName,
      states: row.states,
      isAsCorp: row.isAsCorp,
      agentId: row.agentId,
      status: row.status,
    };
  });

  dataRows.forEach((row) => {
    if (!uniqueRows.find((existingRow) => existingRow.id === row.id)) {
      uniqueRows.push(row);
    }
  });

  /// The sxToolBarContainer object is used to style the toolbar for the DataGrid.
  const sxToolBarContainer = { fontSize: 12, paddingBottom: 1 };
  const sxToolBarColumnsButton = {
    color: "black",
    backgroundColor: "whitesmoke",
  };

  /// Custom toolbar for the DataGrid
  const CustomToolbar = () => {
    return (
      <GridToolbarContainer sx={sxToolBarContainer}>
        <GridToolbarColumnsButton sx={sxToolBarColumnsButton} />
        <GridToolbarFilterButton sx={sxToolBarColumnsButton} />
        <GridToolbarDensitySelector sx={sxToolBarColumnsButton} />
        <GridToolbarExport sx={sxToolBarColumnsButton} />
      </GridToolbarContainer>
    );
  };

  /// The ODD_OPACITY constant is used to set the opacity for the odd rows.
  const ODD_OPACITY = 0.2;

  /// StripedDataGrid is a styled version of DataGridPro
  /// The getRowClassName property is used to apply the even and odd CSS classes to the rows.
  const StripedDataGrid = styled(DataGridPro)(({ theme }) => ({
    [`& .${gridClasses.row}.even`]: {
      backgroundColor: theme.palette.grey[200],
      "&:hover, &.Mui-hovered": {
        backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
        "@media (hover: none)": {
          backgroundColor: "lightgrey",
        },
      },
      "&.Mui-selected": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY + theme.palette.action.selectedOpacity
        ),
        "&:hover, &.Mui-hovered": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY +
              theme.palette.action.selectedOpacity +
              theme.palette.action.hoverOpacity
          ),
          // Reset on touch devices, it doesn't add specificity
          "@media (hover: none)": {
            backgroundColor: alpha(
              theme.palette.primary.main,
              ODD_OPACITY + theme.palette.action.selectedOpacity
            ),
          },
        },
      },
    },
  }));

  /// The sxBox object is used to style the DataGrid.
  const sxBox = {
    height: 640,
    width: "100%",
    "& .super-app-theme--header": {
      backgroundColor: "rgba(27, 133, 243, 0.8)",
    },
    "& .super-app.submitted": {
      color: "green",
      fontWeight: "600",
    },
    "& .super-app.denied": {
      color: "red",
      fontWeight: "600",
    },
  };

  /// The paginationProps object is used to set the pagination properties for the DataGrid.
  const paginationProps = {
    pagination: true,
    pageSize: 25,
    rowsPerPageOptions: [25, 50, 100],
  };

  /// This function is called when the contract request modal is closed.
  const closeContractRequest = () => {
    setRowData({});
    setContractModalOpen(false);
  };

  /// This function is called when the deny modal is closed.
  const closeDenyModal = () => {
    setRowData({});
    setDenyModalOpen(false);
  };

  const hiddenFields = ["id", "agentId"];

  const getTogglableColumns = (columns) => {
    return columns
      .filter((column) => !hiddenFields.includes(column.field))
      .map((column) => column.field);
  };

  /// Return the DataGrid
  return (
    <div className={classes.myClass}>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
        <Box sx={sxBox}>
          <StripedDataGrid
            slots={{ toolbar: CustomToolbar }}
            slotProps={{
              columnsPanel: {
                getTogglableColumns,
              },
            }}
            loading={isLoading}
            rows={uniqueRows}
            columns={columns}
            columnVisibilityModel={{ agentId: false, id: false }}
            pagination={paginationProps}
            disableRowSelectionOnClick
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
            }
          />
        </Box>
      </LocalizationProvider>
      {denyModalOpen === true && (
        <DenyRequestModal
          closeDenyModal={closeDenyModal}
          denyModalOpen={denyModalOpen}
          rowData={rowData}
        />
      )}
      {contractModalOpen === true && (
        <ContractRequestModal
          handleClose={closeContractRequest}
          contractModalOpen={contractModalOpen}
          rowData={rowData}
        />
      )}
      {openAH === true && (
        <AgentHierarchyModal
          currentAgentId={currentAgentId}
          openAH={openAH}
          toggleAH={handleUplineClick}
          agentName={agentName}
          uplineArr={uplineArr}
          setUplineArr={setUplineArr}
        />
      )}
    </div>
  );
};

export default ContractRequests;
