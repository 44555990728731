import {
  CLEAR_ALL,
  GET_ALL,
  GET_ALL_ERROR,
  AGENT_LIST_LOADING,
} from "../constants/action-types";
import { pieData } from "./contracts";
import {
  getAgencyEmailOwner,
  getContractStatuses,
  getSubAgencies,
} from "../../services/GraphQl/BDSWebService";
import _ from "lodash";
import stringUtils from "../../utils/stringUtils";

/* redux-thunk implementation */
export const clearStore = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_ALL, payload: {} });
  };
};

export const getAgency = (agencyId, agentId) => {
  return async (dispatch) => {
    dispatch({
      type: AGENT_LIST_LOADING,
      payload: true,
    });

    getAgencyEmailOwner(agencyId).then((data) => {
      if (data?.data.getAgencyEmailOwner) {
        sessionStorage.setItem(
          "adminEmail",
          data.data.getAgencyEmailOwner.adminEmail
        );
      }
    });

    try {
      // const contracts = await getContractStatuses(agencyId);

      // get agent list agencies
      var agentListAgencies = await getSubAgencies(agencyId);
      if (agentListAgencies) {
        agentListAgencies = agentListAgencies.filter(
          (row) => row.agentId !== sessionStorage.getItem("agentId")
        );
        /* alpha sort */

        agentListAgencies = _.sortBy(agentListAgencies, [
          (row) => row.name.toLowerCase(),
        ]);
        /* upper first chars */

        agentListAgencies.map((row) => {
          row.name = stringUtils.firstUpperLetter(row.name);
        });
        agentListAgencies = [...new Set(agentListAgencies)];
        // dispatch all required parts
        dispatch(
          getAgencyAction({
            // contracts: {
            //   raw: contracts,
            //   pieData: pieData(contracts),
            // },
            // carriers: carriersData.map((carrierData) => {
            //   return carrierData;
            // }),
            agentListAgencies: agentListAgencies,
          })
        );
      }
    } catch (error) {
      dispatch({
        type: GET_ALL_ERROR,
        payload: { error },
      });
    } finally {
      dispatch({
        type: AGENT_LIST_LOADING,
        payload: false,
      });
    }
  };
};

/* redux action */

const getAgencyAction = (data) => {
  return {
    type: GET_ALL,
    payload: data,
  };
};
