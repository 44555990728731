import Box from "@mui/material/Box";
import DataGridContainerButtons from "./DataGridContainerButtons";
import StripedDataGridTemplate from "./StripedDataGridTemplate";

const StripedDataGrid = StripedDataGridTemplate();

const DataGridTemplate = (props) => {
  const {
    columns, // array of objects
    uniqueRows, // array of objects
    isLoading, // true | false
    paginationProps, // object
    CustomToolbar, // "FilterExport" | "All" | "None"
    filterModel, // object
    density, // compact | standard | comfortable
    size, // small | medium | large
    type, // "contracts" / "production" / "readyToSell" / "none"
    hideFooter, // true | false
    autoHeight, // true | false
  } = props;

  const getBackgroundColor = () => {
    if (type === "contracts") {
      return "rgba(184, 73, 255, 0.8)";
    } else if (type === "production") {
      return "rgba(215, 139, 255, 0.8)";
    } else if (type === "readyToSell") {
      return "rgba(27, 133, 243, 0.8)";
    } else {
      return "rgba(27, 133, 243, 0.8)";
    }
  };

  const sxBox = () => {
    if (size === "tiny") {
      return {
        height: 115,
        width: "100%",
        "& .super-app-theme--header": {
          backgroundColor: getBackgroundColor(),
        },
      };
    }
    if (size === "small") {
      return {
        height: 315,
        width: "100%",
        "& .super-app-theme--header": {
          backgroundColor: getBackgroundColor(),
        },
      };
    }
    if (size === "medium") {
      return {
        height: 640,
        width: "100%",
        "& .super-app-theme--header": {
          backgroundColor: getBackgroundColor(),
        },
      };
    }
    if (size === "large") {
      return {
        height: 960,
        width: "100%",
        "& .super-app-theme--header": {
          backgroundColor: getBackgroundColor(),
        },
      };
    }
    if (size === "wide") {
      return {
        height: "500",
        width: "auto",
        "& .super-app-theme--header": {
          backgroundColor: getBackgroundColor(),
        },
      };
    }
  };

  const pageSizeOptions =
    size === "small" ? [5, 10, 25] : size === "medium" ? [25, 50, 100] : [];

  const toolBar = () => {
    return <DataGridContainerButtons containerButtons={CustomToolbar} />;
  };

  const getRowClass = (params) => {
    if (params.row.product === "Total") {
      return "bold";
    }
    return params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "";
  };

  return (
    <Box sx={sxBox}>
      <StripedDataGrid
        slots={{ toolbar: toolBar }}
        loading={isLoading}
        rows={uniqueRows}
        columns={columns}
        disableRowSelectionOnClick
        getRowClassName={(params) => getRowClass(params)}
        initialState={filterModel ? { filter: { filterModel } } : {}}
        density={density}
        paginationProps={paginationProps}
        pageSizeOptions={pageSizeOptions}
        pagination
        hideFooter={hideFooter}
        autoHeight={autoHeight}
      />
    </Box>
  );
};

export default DataGridTemplate;
