import {
  CLEAR_AGENT_LIST,
  SET_AGENT_LIST,
  SET_AGENT_LIST_FILTERED,
  CLEAR_AGENT_LIST_FILTERED,
  AGENT_LIST_LOADING,
} from "../../constants/action-types";
import { getAgentsInfoGql } from "../../../services/GraphQl/BDSWebService";

/* redux-thunk implementation */
export const fetchAgentList = (agencyId) => {
  return async (dispatch) => {
    dispatch({
      type: AGENT_LIST_LOADING,
      payload: true,
    });

    await getAgentsInfoGql()
      .then(async (res) => {
        dispatch(setAgentList(res.data.getAgentsInfo));
        return res.data.getAgentsInfo;
      })
      .then(async (res) => {
        dispatch(
          setFilteredAgentList(res.filter((row) => row.agencyId === agencyId))
        );
      })
      .then(() => {
        dispatch({
          type: AGENT_LIST_LOADING,
          payload: false,
        });
      });
  };
};

export const clearAgentList = () => {
  return (dispatch) => {
    dispatch(clearAgentListAction([]));
  };
};

/* redux action */
const setAgentList = (data) => {
  return {
    type: SET_AGENT_LIST,
    payload: data,
  };
};

/* redux action */
const clearAgentListAction = (data) => {
  return {
    type: CLEAR_AGENT_LIST,
    payload: data,
  };
};

const setFilteredAgentList = (data) => {
  return {
    type: SET_AGENT_LIST_FILTERED,
    payload: data,
  };
};
