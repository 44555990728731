import {
  SET_PRODUCTION_SALES_YEAR,
  SET_PRODUCTION_SALES_YEAR_ERROR,
  CLEAR_PRODUCTION_SALES_YEAR,
  PRODUCTION_SALES_YEAR_LOADING,
  SET_PRODUCTION_BY_CARRIER_AND_QTR,
  SET_PRODUCTION_BY_CARRIER_AND_QTR_ERROR,
  CLEAR_PRODUCTION_BY_CARRIER_AND_QTR,
  PRODUCTION_BY_CARRIER_AND_QTR_LOADING,
} from "../../constants/action-types";

const initState = {
  productionByCarrierAndQtr: [],
  productionByCarrierAndQtrLoading: false,
  setProductionByCarrierAndQtrError: null,
  productionSalesYear: [],
  productionSalesYearLoading: false,
  setProductionSalesYearError: null,
};

export const productionLineGraph = (state = initState, { type, payload }) => {
  switch (type) {
    case SET_PRODUCTION_SALES_YEAR:
      return { ...state, productionSalesYear: payload };
    case SET_PRODUCTION_SALES_YEAR_ERROR:
      return { ...state, setProductionSalesYearError: payload };
    case CLEAR_PRODUCTION_SALES_YEAR:
      return { ...state, productionSalesYear: [] };
    case PRODUCTION_SALES_YEAR_LOADING:
      return { ...state, productionSalesYearLoading: payload };
    case SET_PRODUCTION_BY_CARRIER_AND_QTR:
      return { ...state, productionByCarrierAndQtr: payload };
    case SET_PRODUCTION_BY_CARRIER_AND_QTR_ERROR:
      return { ...state, setProductionByCarrierAndQtrError: payload };
    case CLEAR_PRODUCTION_BY_CARRIER_AND_QTR:
      return { ...state, productionByCarrierAndQtr: [] };
    case PRODUCTION_BY_CARRIER_AND_QTR_LOADING:
      return { ...state, productionByCarrierAndQtrLoading: payload };
    default:
      return state;
  }
};
