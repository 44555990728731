import React from "react";
import PieChartModelMUI from "./PieChartModelMUI";
import { Grid } from "@mui/material";
const PieChartComponentMUI = (props) => {
  const { data, year, type, carrierList } = props; 
  const pieData = getPieData(data, year, type); 
  const newYear = getDefaultYear(year, data); 
  // labelMap is a map of the product types to their respective labels
  const labelMap = {
    "MA": <label>Medicare Advantage</label>,
    "Med Supp": <label>Medicare Supplement</label>,
    "PDP": <label>Prescription Drug Plan</label>,
  };
  // PieChartModel returns a div with a pie, its legend, and its tooltip. the Grid item below it contains the product labels
  return pieData.length > 0 ? (
    <Grid>
      <PieChartModelMUI
        pieData={pieData}
        year={newYear}
        carrierList={carrierList}
      />
      <Grid item xs={12}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          {(() => {
            return labelMap[type];
          })()}
        </Grid>
      </Grid>
    </Grid>
  ) : null;
};

// this function returns the year to be used in the pie chart
const getDefaultYear = (year, data) =>
  parseInt(year) === 0 ? getYear(data.map((item) => item.salesYear)) : parseInt(year);

// this function returns the product type
const getType = (type) => {
  const typeMap = {
    "MA": "Medicare Advantage",
    "Med Supp": "Medicare Supplement",
    "PDP": "Prescription Drug Plan",
  };
  return typeMap[type] || "";
};

// this function returns the pie data
const getPieData = (data, year, type) => {
  if (data.length > 0) {
    const productData = data.filter((item) => item.product === type); // get rows for the product we're working on
    const maxYear = getYear(data.map((item) => item.salesYear));
    const yearData = getYearData(productData, year, maxYear); // maxYear is the default if year = 0; only return rows from productData for the given year
    const totalValue = getTotalValue(yearData); // totalValue = all totals from all carriers, allowing each carrier's % to be calculated

    let results = [];
    let index = 0;

    yearData.forEach((item) => {
      results.push({
        id: index,
        value: getValuePercent(parseInt(item.sum, 10), totalValue),
        label: item.carrierName,
        color: getFillColor(item.carrierName),
        type: getType(item.product),
      });
      index += 1;
    });

    return results;
  }
};

// this function returns the percentage of the total value
const getValuePercent = (dataValue, totalValue) =>
  ((dataValue / totalValue) * 100).toFixed(2);

// this function returns the total value
const getTotalValue = (data) => {
  return data.reduce((total, item) => total + parseInt(item.sum), 0);
};

// this function returns the fill color for the pie chart
const getFillColor = (carrierName) => {
  const colorMap = {
    "Aetna US Health Care": "#F90101",
    "Cigna MA/PDP": "#56B4E9",
    "Elevance/Anthem": "#F07900",
    "Humana": "#39C05E",
    "UHC": "#0072B2",
    "WellCare": "#CC79A7",
    "Aetna MS": "#9F05C5",
    "SCAN": "#D55E00",
    "Molina": "#A9A19F",
    "ATRIO": "#FFE134",
    "UHC IFP": "#7E1215",
    "FL Blue": "#2C73E9",
    "Devoted Health": "#062A77",
    "ACE Medicare Supplements": "#A27900",
    "LA Care": "#A2C8EC",
  };

  return colorMap[carrierName] || "#000000";
};

// this function returns the data for the given year
const getYearData = (productData, year, maxYear) => {
  const targetYear = year === 0 ? maxYear : year;
  return productData.filter((item) => item.salesYear === targetYear);
};

// this function returns the max year
const getYear = (salesYearList) =>
  salesYearList.length > 0 ? Math.max(...salesYearList) : 0;

export default PieChartComponentMUI;
