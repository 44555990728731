import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles({
  uplinesButton: {
    color: "#eee",
    background: "rgba(83,83,83, .9)",
  },
  uplinesButtonHover: {
    "&:hover": {
      background: "#535353",
    }
  },
  uplinesButtonFocus: {
    "&:focus": {
      outline: "none",
    }
  },
});

export default useStyles;