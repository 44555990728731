import { DateTime } from "luxon";

const DownLineCommunicationsData = ({ data }) => {
  const uniqueIds = new Set();
  const uniqueRows = [];

  data?.forEach((row) => {
    const zone = DateTime.now().zoneName;
    const convertTimezone = new Date(row.createDate);
    const id = row.createDate + row.subject;

    if (!uniqueIds.has(id)) {
      uniqueIds.add(id);
      uniqueRows.push({
        id,
        subject: row.subject,
        createDate: convertTimezone.toLocaleString("en-US", { timeZone: zone }),
        body: row.body,
        agents: row.agents?.map((i) => i.displayName).join(", "),
        type: row.type,
      });
    }
  });

  return uniqueRows;
};

export default DownLineCommunicationsData;