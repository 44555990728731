import React, { Fragment } from "react";
import { Typography, Paper } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import ProductionByCarrierDataGrid from "./ProductionByCarrierDataGrid";

const useStyles = makeStyles()(() => {
  return {
    root: {
      flexGrow: 1,
    },
    card: {
      background: "linear-gradient(to bottom right, white, #0e76bc) !important",
      minHeight: "fit-content",
    },
    typography: {
      color: "#003760",
    },
    table: {
      padding: 10,
    },
    input: {
      marginLeft: 8,
      flex: 1,
    },
    iconButton: {
      padding: 10,
    },
  };
});

const ProductionByCarrier = (props) => {
  const { classes } = useStyles();
  const { currentProduction, year, isLoading } = props;
  let results = [];
  const currentProductionData = currentProduction.production
    ? currentProduction.production[year]
    : null;

  if (currentProductionData) {
    let id = 1;
    let sumMa = 0;
    let sumMs = 0;
    let sumPdp = 0;

    currentProductionData?.data?.forEach((item) => {
      const grandTotalMA = item.ma_total ? parseInt(item.ma_total) : 0;
      const grandTotalMS = item.ms_total ? parseInt(item.ms_total) : 0;
      const grandTotalPDP = item.pdp_total ? parseInt(item.pdp_total) : 0;

      sumMa += grandTotalMA;
      sumMs += grandTotalMS;
      sumPdp += grandTotalPDP;

      results.push({
        id: id++,
        product: item.name,
        maTotal: grandTotalMA,
        msTotal: grandTotalMS,
        pdpTotal: grandTotalPDP,
      });
    });

    results.push({
      id: id++,
      product: "Total",
      maTotal: sumMa,
      msTotal: sumMs,
      pdpTotal: sumPdp,
    });
  }

  return (
    <Fragment>
      <Typography
        className={classes.typography}
        gutterBottom
        variant="h5"
        component="h3"
      >
        Production By Carrier
      </Typography>
      <Paper className={classes.paper}>
        <ProductionByCarrierDataGrid
          tableData={results}
          year={year}
          isLoading={isLoading}
        />
      </Paper>
    </Fragment>
  );
};

export default ProductionByCarrier;
