import React, { useState } from "react";
import { useRef } from "react";
import RegisterAgency from "./RegisterAgency";
import RegisterEmailVerification from "./RegisterEmailVerification";
import RegisterVerification from "./RegisterVerification";

const Register = () => {
  const [isRegistering, setIsRegistering] = useState(true);
  const [isValidation, setIsValidation] = useState(false);
  const [isRegisterReady, setIsRegisterReady] = useState(false);
  const [registrationId, setRegistrationId] = useState(0);
  const [email, setEmail] = useState("");
  const uniqueId = useRef(crypto.randomUUID());

  return (
    <div>
      {isRegistering && (
        <RegisterVerification
          setIsRegistering={setIsRegistering}
          setIsValidation={setIsValidation}
          uniqueId={uniqueId}
          setRegistrationId={setRegistrationId}
          setEmail={setEmail}
        />
      )}
      {isValidation && (
        <RegisterEmailVerification
          setIsValidation={setIsValidation}
          setIsRegisterReady={setIsRegisterReady}
          uniqueId={uniqueId}
        />
      )}
      {isRegisterReady && (
        <RegisterAgency registrationId={registrationId} email={email} />
      )}
    </div>
  );
};

export default Register;
