const AgentsReadyToSellData = ({ data, agentId }) => {
  const filteredData = data?.filter(
    (row) => row.agentId === agentId && row.writingNumber !== "No RTS Rows"
  );

  const dataRows = filteredData?.map((row) => ({
    id: row.carrierId + row.planYear + row.writingNumber,
    writingNumber: row.writingNumber,
    carrierName: row.carrierName,
    state: row.state,
    product: row.product,
    isCorporation: row.isCorporation,
    agentCertified: row.agentCertified,
    agentReady: row.agentReady,
    productReady: row.productReady,
    planYear: row.planYear,
    tacygrtsId: row.tacygrtsId,
  }));

  const ids = new Set();
  const uniqueRows = dataRows.filter((row) => {
    if (!ids.has(row.id)) {
      ids.add(row.id);
      return true;
    }
    return false;
  });

  return uniqueRows;
};

export default AgentsReadyToSellData;
