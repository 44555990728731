import moment from "moment";

const commonProps = {
  headerAlign: "center",
  align: "center",
  headerClassName: "super-app-theme--header",
  type: "string",
  cellClassName: "super-app-theme--cell",
  sortable: false,
  disableColumnMenu: true,
  flex: 1,
};

const columns = [
  {
    field: "carrierName",
    headerName: " Carrier Name",
    width: 200,
  },
  {
    field: "contractStatus",
    headerName: "Contract Status",
    width: 350,
  },
  {
    field: "agentLevel",
    headerName: "Agent Level",
    width: 200,
  },
  {
    field: "contractState",
    headerName: "Contract State",
    width: 100,
  },
  {
    field: "writingNumber",
    headerName: "Writing Number",
    width: 200,
  },
  {
    field: "statusDate",
    headerName: "Status Date",
    width: 200,
    valueFormatter: (params) => {
      return moment(params.value).format("L");
    },
  },
];

const AgentContractColumns = () => {
  return columns.map((column) => ({ ...commonProps, ...column }));
};

export default AgentContractColumns;
