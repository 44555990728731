import { useEffect } from "react";
import { Box, CircularProgress } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import LineChartHeader from "./LineChartHeader";
import LineChartBody from "./LineChartBody";
import {
  fetchProductionCarrierAndQtrData,
  fetchProductionSalesYear,
} from "../../../store/actions/production/productionLineGraph";

const LineChartComponent = (props) => {
  const { agencyId } = props;
  const dispatch = useDispatch();
  const {
    productionByCarrierAndQtr,
    productionByCarrierAndQtrLoading,
    productionSalesYear,
  } = useSelector((state) => state.productionLineGraph);

  useEffect(() => {
    const fetchData = async () => {
      if (productionByCarrierAndQtr.length === 0) {
        dispatch(fetchProductionCarrierAndQtrData(agencyId));
        dispatch(fetchProductionSalesYear());
      }
    };
    fetchData();
  }, [dispatch, agencyId, productionByCarrierAndQtr]);

  return (
    <div>
      {productionByCarrierAndQtrLoading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="48vh"
        >
          <CircularProgress color="secondary" />
        </Box>
      ) : (
        <div style={{ paddingBottom: '10px' }}>
          <LineChartHeader data={productionSalesYear} />
          <LineChartBody data={productionByCarrierAndQtr} />
        </div>
      )}
    </div>
  );
};

export default LineChartComponent;
