import {
  SET_AGENCY_READY_TO_SELL_BY_CARRIER,
  SET_AGENCY_READY_TO_SELL_BY_CARRIER_ERROR,
  CLEAR_AGENCY_READY_TO_SELL_BY_CARRIER,
  AGENCY_READY_TO_SELL_BY_CARRIER_LOADING,
  AGENCY_READY_TO_SELL_BY_CARRIER_LOADED,
} from "../../constants/action-types";

const initState = {
  agencyReadyToSellByCarrier: [],
  isFetchingRTSByCarrier: false,
  RTSByCarrierLoaded: false,
  error: null,
};

export const readyToSellByCarrier = (state = initState, { type, payload }) => {
  switch (type) {
    case SET_AGENCY_READY_TO_SELL_BY_CARRIER:
      return {
        ...state,
        agencyReadyToSellByCarrier: payload,
      };
    case CLEAR_AGENCY_READY_TO_SELL_BY_CARRIER:
      return { ...state, agencyReadyToSellByCarrier: [] };
    case AGENCY_READY_TO_SELL_BY_CARRIER_LOADING:
      return { ...state, isFetchingRTSByCarrier: payload };
    case SET_AGENCY_READY_TO_SELL_BY_CARRIER_ERROR:
      return { ...state, error: payload };
    case AGENCY_READY_TO_SELL_BY_CARRIER_LOADED:
      return { ...state, RTSByCarrierLoaded: payload };
    default:
      return state;
  }
};
