
const PlanChangesData = (props) => {
    const { data } = props;
    const uniqueRows = [];
    const dataRows = data.map((row) => {
      return {
        id:
          row.agent_id +
          row.carrier_id +
          row.effectivedate +
          row.county +
          row.state,
        agentName: row.agentname,
        carrierName: row.carrier_name,
        companyName: row.company_name,
        hlevel: row.hlevel,
        ma_production: row.ma_production,
        medsupp_production: row.medsupp_production,
        pdp_production: row.pdp_production,
        uplines: row.uplines,
        county: row.county,
        state: row.state,
        effectivedate: row.effectivedate,
        planYear: new Date(row.effectivedate).getFullYear().toString(),
      };
    });
  
    dataRows.forEach((row) => {
      if (!uniqueRows.find((existingRow) => existingRow.id === row.id)) {
        uniqueRows.push(row);
      }
    });

    return uniqueRows;
}

export default PlanChangesData;

