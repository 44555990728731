import { alpha, styled } from "@mui/material/styles";
import { DataGridPro, gridClasses } from "@mui/x-data-grid-pro";

const ODD_OPACITY = 0.2;

const StripedDataGrid = styled(DataGridPro)(({ theme }) => {
  const selectedOpacity = ODD_OPACITY + theme.palette.action.selectedOpacity;
  const hoverOpacity = selectedOpacity + theme.palette.action.hoverOpacity;

  return {
    [`& .${gridClasses.row}.bold`]: {
      borderTop: "2px solid black",
      fontWeight: "bold",
    },
    [`& .${gridClasses.row}.even`]: {
      backgroundColor: theme.palette.grey[200],
      "&:hover, &.Mui-hovered": {
        backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
        "@media (hover: none)": {
          backgroundColor: "lightgrey",
        },
      },
      "&.Mui-selected": {
        backgroundColor: alpha(theme.palette.primary.main, selectedOpacity),
        "&:hover, &.Mui-hovered": {
          backgroundColor: alpha(theme.palette.primary.main, hoverOpacity),
          // Reset on touch devices, it doesn't add specificity
          "@media (hover: none)": {
            backgroundColor: alpha(theme.palette.primary.main, selectedOpacity),
          },
        },
      },
    },
  };
});

const StripedDataGridTemplate = () => {
  return StripedDataGrid;
};

export default StripedDataGridTemplate;
