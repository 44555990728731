import React, { useEffect, useState, Fragment, useCallback } from "react";
import {
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  TextField,
  useMediaQuery,
  useTheme,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import CommonDialogTitle from "../calculator/CommonDialogTitle";
import { ArrowLeft } from "@mui/icons-material";

const ProcessDialog = (props) => {
  const {
    openProcessDialog,
    closeProcessDialog,
    submitContractRequests,
    requestsToProcess,
  } = props;

  const [next, setNext] = useState(false);
  const [denyPrompt, setDenyPrompt] = useState(false);
  const [ocrSubmissionInput, setOcrSubmissionInput] = useState([]);

  const theme = useTheme();
  const mQuery = useMediaQuery(theme.breakpoints.down("sm"));
  const onlyDeny = !requestsToProcess.some((rtp) => rtp.status === "send");
  const someDeny = requestsToProcess.some((rtp) => rtp.status !== "send");
  // Only obtain input from send requests
  const sendRequests = requestsToProcess.filter((rtp) => rtp.status !== "deny");
  const denyRequests = requestsToProcess.filter((rtp) => rtp.status === "deny");

  const [modalState, setModalState] = useState("");

  const updateProcessState = useCallback(() => {
    const newModalState = () => {
      if (!next && !onlyDeny) {
        return "uplineRequests";
      } else if (denyPrompt) {
        return "denialMessages";
      } else if (
        (next && onlyDeny) ||
        (next && !someDeny) ||
        (next && someDeny && !denyPrompt)
      ) {
        return "confirmSubmissions";
      }
    };
    if (newModalState() !== modalState) {
      setModalState(newModalState());
    }
  }, [next, onlyDeny, denyPrompt, someDeny, modalState]);

  useEffect(() => {
    setOcrSubmissionInput(requestsToProcess);
    setNext(!requestsToProcess.some((rtp) => rtp.status === "send"));
    setDenyPrompt(requestsToProcess.some((rtp) => rtp.status === "deny"));
  }, [requestsToProcess]);

  useEffect(() => {
    updateProcessState();
  }, [next, denyPrompt, updateProcessState]);

  const close = () => {
    closeProcessDialog();
    setOcrSubmissionInput(requestsToProcess);
  };

  const submitted = () => {
    close();
    submitContractRequests(ocrSubmissionInput);
  };

  //Next enabled when upline and level exist on send requests
  const canNext = ocrSubmissionInput.some(
    (oui) => (!oui.upline || !oui.level) && oui.status !== "deny"
  );

  const setInputValue = (val, key, _ocrId) => {
    setOcrSubmissionInput((ocrInput) => {
      return ocrInput.map((ocr) =>
        ocr.ocrId === _ocrId ? { ...ocr, [key]: val } : ocr
      );
    });
  };

  const dialogVerbiage = () => {
    return (
      <div>
        <Grid item xs={12} style={{ padding: 40 }}>
          {onlyDeny
            ? `Clicking Submit below will remove the contract requests for this lead from this list but not from the full leads list. Would you like 
        to proceed with processing the contract request(s)?`
            : `Clicking Submit below will remove this lead from your lists
        and submit the contract request(s) for processing, would you like
        to proceed?`}
        </Grid>
      </div>
    );
  };

  const denialMessagePrompt = () => {
    return (
      <div>
        <div style={{ marginBottom: 10 }}>
          For <span style={{ color: "red", fontWeight: 600 }}>Denials</span> you
          have the option of sending a notification message to the requestor
        </div>
        {denialMessageInput()};
      </div>
    );
  };

  const denialMessageInput = () => {
    return denyRequests.map((rtp, i) => {
      const message =
        ocrSubmissionInput.find((oui) => rtp.ocrId === oui.ocrId)
          ?.denialMessage ?? "";
      const messageDisabled = !ocrSubmissionInput.find(
        (oui) => rtp.ocrId === oui.ocrId
      )?.sendDenialMessage;
      return (
        <Fragment key={i}>
          <Grid
            item
            xs={12}
            key={rtp.ocrId}
            style={{ marginBottom: 5, padding: 20 }}
          >
            <h4 style={{ marginBottom: 5 }}>{rtp.carrierName}</h4>
            <FormControlLabel
              control={
                <Checkbox
                  id={`denial_checkbox${i}`}
                  onChange={(e) =>
                    setInputValue(
                      e.target.checked,
                      "sendDenialMessage",
                      rtp.ocrId
                    )
                  }
                  checked={!messageDisabled}
                />
              }
              label="Send Notification"
            />
            <TextField
              label="Denial Message"
              value={message}
              disabled={messageDisabled}
              onChange={(e) =>
                setInputValue(e.target.value, "denialMessage", rtp.ocrId)
              }
              fullWidth
            />
          </Grid>
          {denyRequests.length - 1 !== i && (
            <Divider
              style={{
                height: 0.5,
                width: "100%",
              }}
            />
          )}
        </Fragment>
      );
    });
  };

  const uplineLevelPrompt = () => {
    return (
      <div>
        <div style={{ marginBottom: 10 }}>
          For <span style={{ color: "green", fontWeight: 600 }}>Send</span>{" "}
          requests you must submit an upline and level
        </div>
        {uplineLevelInput()}
      </div>
    );
  };

  const uplineLevelInput = () => {
    return sendRequests.map((rtp, i) => {
      const upline =
        ocrSubmissionInput.find((oui) => rtp.ocrId === oui.ocrId)?.upline ?? "";
      const level =
        ocrSubmissionInput.find((oui) => rtp.ocrId === oui.ocrId)?.level ?? "";
      return (
        <Fragment key={i}>
          <Grid
            item
            xs={12}
            key={rtp.ocrId}
            style={{ marginBottom: 5, padding: 20 }}
          >
            <h4 style={{ marginBottom: 5 }}>{rtp.carrierName}</h4>
            <TextField
              label="Upline"
              value={upline}
              onChange={(e) =>
                setInputValue(e.target.value, "upline", rtp.ocrId)
              }
              fullWidth
            />
            <TextField
              label="Level"
              value={level}
              onChange={(e) =>
                setInputValue(e.target.value, "level", rtp.ocrId)
              }
              fullWidth
            />
          </Grid>
          {sendRequests.length - 1 !== i && (
            <Divider
              style={{
                height: 0.5,
                width: "100%",
              }}
            />
          )}
        </Fragment>
      );
    });
  };

  const dialogContentSelected = () => {
    if (modalState === "uplineRequests") {
      return uplineLevelPrompt();
    } else if (modalState === "denialMessages") {
      return denialMessagePrompt();
    } else if (modalState === "confirmSubmissions") {
      return dialogVerbiage();
    }
  };

  const proceedButtonFunction = () => {
    if (modalState === "uplineRequests") {
      setNext(true);
    } else if (modalState === "denialMessages") {
      setDenyPrompt(false);
    } else if (modalState === "confirmSubmissions") {
      submitted();
    }
  };

  const proceedButtonText = () => {
    if (modalState === "uplineRequests" || modalState === "denialMessages") {
      return "Next";
    } else if (modalState === "confirmSubmissions") {
      return "Submit";
    }
  };

  const dialogProceedButton = () => {
    return (
      <div>
        <Button
          onClick={() => {
            proceedButtonFunction();
          }}
          color="inherit"
          // variant="outlined"
          disabled={canNext}
        >
          {proceedButtonText()}
        </Button>
      </div>
    );
  };

  return (
    <Dialog
      open={openProcessDialog}
      fullScreen={mQuery}
      onClose={() => close()}
    >
      <CommonDialogTitle
        title="Process Contract Request"
        close={() => close()}
        variant="h6"
      />
      <DialogContent
        style={{
          display: "grid",
          placeItems: "center",
          transition: "all 1s linear",
        }}
      >
        <Grid container>{dialogContentSelected()}</Grid>
      </DialogContent>
      <DialogActions style={{ color: "green" }}>
        <ButtonGroup>
          {(modalState === "confirmSubmissions" ||
            (modalState === "denialMessages" && !onlyDeny)) && (
            <Button
              onClick={() =>
                someDeny && modalState === "confirmSubmissions"
                  ? setDenyPrompt(true)
                  : setNext(false)
              }
              color="primary"
              variant="outlined"
              style={{ color: "#003760", borderColor: "#003760" }}
            >
              <ArrowLeft />
            </Button>
          )}
          <Button
            onClick={() => {
              close();
            }}
            variant="outlined"
            style={{ color: "red", borderColor: "red" }}
          >
            Cancel
          </Button>
          {dialogProceedButton()}
        </ButtonGroup>
      </DialogActions>
    </Dialog>
  );
};

export default ProcessDialog;
