import { Button, ButtonGroup } from "@mui/material";
import { Business } from "@mui/icons-material";
import history from "../../../../utils/history";

const navigate = (path, name, year) => {
  history.push(path, {
    state: { planYear: String(year), carrierName: name },
  });
};

const handleClick = (e, cellValue, year) => {
    navigate("/Production", cellValue, year);
  };

const commonProps = {
  headerAlign: "center",
  align: "center",
  headerClassName: "super-app-theme--header",
  cellClassName: "super-app-theme--cell",
  flex: 1,
  sortable: false,
  disableColumnMenu: true,
};

const columnData = [
  { field: "maTotal", headerName: "Medicare Advantage", type: "number" },
  { field: "msTotal", headerName: "Medicare Supplement", type: "number" },
  { field: "pdpTotal", headerName: "Prescription Drug Plan", type: "number" },
];

const ProductionByCarrierColumns = ({ year }) => {
  const columns = [
    {
      ...commonProps,
      field: "product",
      headerName: "",
      type: "string",
      renderCell: (params) =>
        params.value !== "Total" ? (
          <ButtonGroup variant="contained">
            <Button
              onClick={(event) => handleClick(event, params.value, year)}
              endIcon={<Business />}
              sx={{ width: "200px", flex: 1 }}
            >
              {params.value}
            </Button>
          </ButtonGroup>
        ) : (
          <span>{params.value}</span>
        ),
    },
    ...columnData.map((data) => ({ ...commonProps, ...data })),
  ];

  return columns;
};

export default ProductionByCarrierColumns;
