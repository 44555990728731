import {
  SET_PRODUCTION_SALES_YEAR,
  SET_PRODUCTION_SALES_YEAR_ERROR,
  CLEAR_PRODUCTION_SALES_YEAR,
  PRODUCTION_SALES_YEAR_LOADING,
  SET_PRODUCTION_BY_CARRIER_AND_QTR,
  SET_PRODUCTION_BY_CARRIER_AND_QTR_ERROR,
  CLEAR_PRODUCTION_BY_CARRIER_AND_QTR,
  PRODUCTION_BY_CARRIER_AND_QTR_LOADING,
} from "../../constants/action-types";
import {
  getProductionByCarrierAndQtr,
  getLatestProductionSalesYear,
} from "../../../services/GraphQl/BDSWebService";

export const setProductionSalesYear = (data) => {
  return {
    type: SET_PRODUCTION_SALES_YEAR,
    payload: data,
  };
};

export const setProductionSalesYearError = (error) => {
  return {
    type: SET_PRODUCTION_SALES_YEAR_ERROR,
    payload: error,
  };
};

export const clearProductionSalesYear = () => {
  return {
    type: CLEAR_PRODUCTION_SALES_YEAR,
  };
};

export const setProductionSalesYearLoading = (data) => {
  return {
    type: PRODUCTION_SALES_YEAR_LOADING,
    payload: data,
  };
};

export const setProductionByCarrierAndQtrLoading = (data) => {
  return {
    type: PRODUCTION_BY_CARRIER_AND_QTR_LOADING,
    payload: data,
  };
};

export const setProductionByCarrierAndQtr = (data) => {
  return {
    type: SET_PRODUCTION_BY_CARRIER_AND_QTR,
    payload: data,
  };
};

export const setProductionByCarrierAndQtrError = (error) => {
  return {
    type: SET_PRODUCTION_BY_CARRIER_AND_QTR_ERROR,
    payload: error,
  };
};

export const clearProductionByCarrierAndQtr = () => {
  return {
    type: CLEAR_PRODUCTION_BY_CARRIER_AND_QTR,
  };
};

export const fetchProductionSalesYear = () => {
  return async (dispatch) => {
    dispatch(setProductionSalesYearLoading(true));
    try {
      let ProResultData = await getLatestProductionSalesYear();
      let ProResult = ProResultData.data
        ? ProResultData.data.getLatestProductionSalesYear
        : ProResultData;
      dispatch(setProductionSalesYear(ProResult));
    } catch (error) {
      dispatch(setProductionSalesYearError(error));
    } finally {
      dispatch(setProductionSalesYearLoading(false));
    }
  };
};

export const fetchProductionCarrierAndQtrData = (agencyId) => {
  return async (dispatch) => {
    dispatch(setProductionByCarrierAndQtrLoading(true));
    try {
      let ProResultData = await getProductionByCarrierAndQtr(agencyId);
      let ProResult = ProResultData.data
        ? ProResultData.data.getProductionByCarrierAndQtr
        : ProResultData;
      dispatch(setProductionByCarrierAndQtr(ProResult));
    } catch (error) {
      dispatch(setProductionByCarrierAndQtrError(error));
    } finally {
      dispatch(setProductionByCarrierAndQtrLoading(false));
    }
  };
};
