import { useMemo } from 'react';

const AgentsData = ({data}) => {
    const dataRows = useMemo(
        () =>
          data?.map((row) => {
            return {
              id: row.agentId,
              agentName: row.agentName,
              agentEmail: row.agentEmail,
              agentPhone: row.agentPhone,
              agentWayEnabled: row.agentWayEnabled,
              agentState: row.agentState,
              agentId: row.agentId,
              npn: row.npn,
              agencyId: row.agencyId,
            };
          }),
        [data]
      );
    
      const uniqueRows = useMemo(() => {
        const uniqueRows = [];
        dataRows?.forEach((row) => {
          if (!uniqueRows.find((existingRow) => existingRow.id === row.id)) {
            uniqueRows.push(row);
          }
        });
        return uniqueRows;
      }, [dataRows]);

    return uniqueRows;
}

export default AgentsData;