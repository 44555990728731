import React, { useState, useEffect } from "react";
import { Grid, Typography, Paper } from "@mui/material";
import PieChartComponentMUI from "./PieCharts/PieChartComponentMUI";
import DropDown from "./DropDown";
import { getAgencyProductionByCarrierAndProduct, getCarriers } from "../../services/GraphQl/BDSWebService";

const CarrierInformation = (props) => {
  const { agencyId, classes, year, setYear } = props;
  const [data, setData] = useState([]);
  const [carrierList, setCarrierList] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      await getCarriers().then((data) => {
        setCarrierList(data);
      });
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (agencyId !== null) {
        await getAgencyProductionByCarrierAndProduct(agencyId).then((data) => {
          setData(data.data.getAgencyProductionByCarrierAndProduct);
        });
      }
    };
    fetchData();
  }, [agencyId]);

  useEffect(() => {
    const maxYear = (data) => {
      const salesYearList = data.map((item) => item.salesYear);
      if (salesYearList.length > 0) {
        return Math.max(...salesYearList);
      }
      return 0;
    };
    
    setYear(maxYear(data));
  }
  , [data, setYear]);

  return (
    <Grid item xs={12}>
      <Typography
        className={classes.typography}
        gutterBottom
        variant="h5"
        component="h3"
      >
        Carrier Information by Product
      </Typography>

      <Paper>
        <Grid
          container
          direction="row"
          justifyContent="space-evenly"
          alignItems="center"
        >
          <div
            style={{
              display: "flex",
              justify: "space-around",
            }}
          >
            {data.length > 0 ? (
              <PieChartComponentMUI data={data} year={parseInt(year)} type={"MA"} carrierList={carrierList} />
            ) : null}
            {data.length > 0 ? (
              <PieChartComponentMUI data={data} year={parseInt(year)} type={"Med Supp"} carrierList={carrierList} />
            ) : null}
            {data.length > 0 ? (
              <PieChartComponentMUI data={data} year={parseInt(year)} type={"PDP"} carrierList={carrierList} />
            ) : null}
          </div>
          <Grid
            style={{ padding: 10 }}
            container
            alignItems="center"
            justifyContent="center"
          >
            {data.length > 0 ? (
              <DropDown setYear={setYear} data={data} />
            ) : null}
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default CarrierInformation;
