
import { Grid, Typography } from "@mui/material";

const TypographyGridItem = ({ xs, color, children }) => (
  <Grid item xs={xs}>
    <Typography
      style={{ color, fontWeight: "bold", fontSize: 20 }}
      align="justify"
    >
      {children}
    </Typography>
  </Grid>
);

const LineChartHeader = (props) => {
  const { data } = props;
  const calculateYears = (productionSalesYear) => {    
    if (
      !productionSalesYear ||
      productionSalesYear === undefined ||
      productionSalesYear === null ||
      productionSalesYear === false ||
      productionSalesYear === true ||
      productionSalesYear.length === 0
    ) {
      return [0, 0, 0];
    }
    return [
      productionSalesYear[0].salesYear - 2,
      productionSalesYear[0].salesYear - 1,
      productionSalesYear[0].salesYear,
    ];
  };

  const [year1, year2, year3] = calculateYears(data);

  return (
    <Grid container direction="row" alignItems="flex-center" spacing={1}>
      <TypographyGridItem xs={4} color="#F3A3F1" />
      <TypographyGridItem xs={2} color="#F3A3F1">
        {year1}
      </TypographyGridItem>
      <TypographyGridItem xs={2} color="#040400">
        {year2}
      </TypographyGridItem>
      <TypographyGridItem xs={1} color="#33A1FF">
        {year3}
      </TypographyGridItem>
    </Grid>
  );
};

export default LineChartHeader;
