import {
  CLEAR_AGENT_LIST,
  SET_AGENT_LIST,
  AGENT_LIST_LOADING,
  SET_AGENT_LIST_FILTERED,
  CLEAR_AGENT_LIST_FILTERED,
} from "../../constants/action-types";

const initState = {
  data: [],
  filteredData: [],
  isFetchingAgentList: false,
};

export const agents = (state = initState, action) => {
  switch (action.type) {
    case SET_AGENT_LIST:
      return { ...state, data: action.payload };
    case CLEAR_AGENT_LIST:
      return { ...state, data: [] };
    case AGENT_LIST_LOADING:
      return { ...state, isFetchingAgentList: action.payload };
    case SET_AGENT_LIST_FILTERED:
      return { ...state, filteredData: action.payload };
    case CLEAR_AGENT_LIST_FILTERED:
      return { ...state, filteredData: [] };
    default:
      return state;
  }
};
