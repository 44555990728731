import moment from "moment";

const formatRow = (row) => ({
    ...row,
    id: row.tacygcId,
    statusDate: moment(Number(row.statusDate)).format("L"),
  });
  
  const isUnique = (unique, item) => 
    unique.some((row) => row.tacygcId === item.tacygcId)
      ? unique
      : [...unique, item];
  
  const AgentContractData = ({ agentId, data }) => {
    if (!Array.isArray(data)) {
      return [];
    }
  
    return data
      .filter((row) => row.agentId === agentId)
      .map(formatRow)
      .reduce(isUnique, []);
  }

export default AgentContractData;
    



