import {
  CLEAR_READY_TO_SELL_LIST,
  SET_READY_TO_SELL_LIST,
  READY_TO_SELL_LOADING,
  SET_READY_TO_SELL_FILTER_MODEL,
  CLEAR_READY_TO_SELL_FILTER_MODEL,
} from "../../constants/action-types";

const initState = {
  data: [],
  filterModel: {
    items: [],
  },
  isLoading: false,
};

export const readyToSell = (state = initState, { type, payload }) => {
  switch (type) {
    case SET_READY_TO_SELL_LIST:
      return { ...state, data: payload };
    case CLEAR_READY_TO_SELL_LIST:
      return { ...state, data: [] };
    case READY_TO_SELL_LOADING:
      return { ...state, isLoading: payload };
    case SET_READY_TO_SELL_FILTER_MODEL:
      return { ...state, filterModel: payload };
    case CLEAR_READY_TO_SELL_FILTER_MODEL:
      return {
        ...state,
        filterModel: {
          items: [],
        },
      };
    default:
      return state;
  }
};
