import { Typography, Button, Fade, Modal, Backdrop } from "@mui/material/";
import Responsive from "react-responsive";
import { makeStyles } from "tss-react/mui";
import { useState } from "react";
const useStyles = makeStyles()((theme) => {
  return {
    root: {
      flexGrow: 1,
      margin: 0,
      padding: 0,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
    },
    tileGrid: {
      padding: 10,
      height: "auto",
      [theme.breakpoints.down("md")]: {
        height: "auto",
      },
    },
    card: {
      height: "100%",
      padding: "8px",
      display: "flex",
      flexDirection: "column",
      backgroundColor: "#575757",
    },
    certsLoadingCard: {
      height: "100%",
      padding: "8px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      backgroundColor: "#575757",
    },
    agentsInView: {
      fontSize: 18,
      color: "#444242",
    },
    agentsInViewContainer: {
      textAlign: "left",
      paddingLeft: 10,
      marginTop: 2,
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "100vh",
      width: "50%",
      margin: "auto",
    },
    modalPaper: {
      backgroundColor: "#f7f7f7",
      border: "2px solid #000",
      padding: theme.spacing(0.5),
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      borderRadius: 5,
    },
  };
});
const Mobile = (props) => <Responsive {...props} maxWidth={959} />;
const Default = (props) => <Responsive {...props} minWidth={960} />;
const ModalTitle = "Please Note:";
const ModalMessage =
  "Information displayed is derived from carrier reports as they are received. For real-time status, please refer to the applicable carrier portal or contact the carrier’s Broker Support hotline. Agents are ultimately responsible for ensuring they are properly licensed, appointed, and certified for the applicable plan year prior to selling. Berwick Insurance Group is not responsible for any errors or omissions of any Ready to Sell (RTS) information provided.";
const ModalTypography = ({ variant, id }) => (
  <Typography color="black" variant={variant} gutterBottom id={id}>
    {id === "transition-modal-title" ? ModalTitle : ModalMessage}
  </Typography>
);

const ModalButton = ({ setRtsModalOpen }) => (
  <Button
    contained="contained"
    variant="contained"
    color="primary"
    size="small"
    style={{ marginTop: "1.5em" }}
    onClick={() => setRtsModalOpen(false)}
  >
    OK
  </Button>
);

const ModalMyAgents = (props) => {
  const { classes } = useStyles();
  const [rtsModalOpen, setRtsModalOpen] = useState(true);

  const handleClose = () => {
    setRtsModalOpen(true);
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={rtsModalOpen}
      onClose={handleClose}
      disablebackdropclick
      closeAfterTransition
      slot={Backdrop}
      disableEscapeKeyDown
      slotProps={{
        backdrop: { timeout: 500 },
      }}
    >
      <Fade in={rtsModalOpen}>
        <div className={classes.modalPaper}>
          <Default>
            <ModalTypography variant="h5" id="transition-modal-title" />
            <ModalTypography variant="h6" id="transition-modal-description" />
            <ModalButton setRtsModalOpen={setRtsModalOpen} />
          </Default>
          <Mobile>
            <ModalTypography variant="h5" id="transition-modal-title" />
            <ModalTypography
              variant="subtitle2"
              id="transition-modal-description"
            />
            <ModalButton setRtsModalOpen={setRtsModalOpen} />
          </Mobile>
        </div>
      </Fade>
    </Modal>
  );
};

export default ModalMyAgents;
