const AgentReadyToSellProductsData = ({ data }) => {
    const dataRows = data?.map((row) => ({
      id: `${row.carrierName}${row.planYear}${row.product}${row.state}`,
      ...row,
    }));
  
    const ids = new Set();
    const uniqueRows = dataRows?.filter((row) => {
      if (!ids.has(row.id)) {
        ids.add(row.id);
        return true;
      }
      return false;
    });
  
    return uniqueRows;
  };
  
  export default AgentReadyToSellProductsData;
