import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "tss-react/mui";
import {
  CircularProgress,
  Grid,
  Paper,
  Typography,
  TableRow,
  TableCell,
  TableHead,
  Table,
  TableBody,
} from "@mui/material";
import { Link } from "react-router-dom";
import { GridLogicOperator } from "@mui/x-data-grid-pro";
import { setAgentListFilterModel } from "../store/actions/agents/agentsContractInfo";

const useStyles = makeStyles()((theme) => {
  const title = { textDecoration: "underline" };
  const value = { fontWeight: "bold" };
  return {
    root: {
      flexGrow: 1,
      padding: 10,
      background: "#575757",
      textAlign: "center",
    },
    typoGraphy: {
      color: "white",
      fontSize: "calc(24px + (36 - 24) * ((100vw - 250px) / (4000 - 250)))",
      lineHeight: "calc(1.3em + (1.5 - 1.2) * ((100vw - 250px)/(3000 - 250)))",
    },
    productionTotals: {
      ...title,
      fontSize: "calc(10px + (16 - 8) * ((100vw - 250px) / (4000 - 250)))",
      lineHeight: "calc(1.3em + (1.5 - 1.2) * ((100vw - 250px)/(3000 - 250)))",
    },
    productionTotals2: {
      ...value,
      fontSize: "calc(12px + (16 - 8) * ((100vw - 250px) / (4000 - 250)))",
      lineHeight: "calc(1.3em + (1.5 - 1.2) * ((100vw - 250px)/(3000 - 250)))",
    },
    header: {
      padding: 8,
    },
    headerTypography: {
      fontWeight: 600,
    },
    contractTypography: {
      fontSize: "calc(10px + (16 - 8) * ((100vw - 250px) / (4000 - 250)))",
      lineHeight: "calc(1.3em + (1.5 - 1.2) * ((100vw - 250px)/(3000 - 250)))",
    },
    contractTotals: {
      fontSize: "calc(10px + (16 - 8) * ((100vw - 250px) / (4000 - 250)))",
      lineHeight: "calc(1.3em + (1.5 - 1.2) * ((100vw - 250px)/(3000 - 250)))",
    },
  };
});

const AgencyPill = (props) => {
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const [statusData, setStatusData] = useState([]);
  const [filteredLabels, setFilteredLabels] = useState([]);
  const contracts = useSelector((state) => state.contracts);
  const currentProduction = useSelector((state) => state.currentProduction);
  const currentYear = currentProduction?.currentYear;
  const { agencyName, toggleStatusCallback } = props;

  useEffect(() => {
    if (contracts && Object.keys(contracts).length === 0) {
      props.setContractStatuses(sessionStorage.getItem("agencyId"));
    }
    contracts.pieData?.datasets?.forEach((data) => {
      const color = data.backgroundColor;
      const labels = data.label;

      const filterLabels = labels.filter(
        (label) =>
          label === "Approved - Total" ||
          label === "Approved - Released" ||
          label === "Sent To Agent" ||
          label === "In Process"
      );

      setFilteredLabels(filterLabels);

      const newArray = data.data.map((e, i) => {
        return { total: e, label: labels[i], color: color[i] };
      });
      const filteredArray = newArray
        .map((e) => {
          if (e.label === "Approved - Total") {
            e.label = "Approved";
          }
          if (e.label === "Sent To Agent") {
            e.label = "Sent to agent";
          }
          return e;
        })
        .filter((e) =>
          [
            "Approved",
            "Approved - Released",
            "Sent to agent",
            "In Process",
          ].includes(e.label)
        );
      setStatusData(filteredArray);
    });
  }, [contracts, currentProduction, props, props.setContractStatuses]);

  const top_display = (topName, type) => {
    const handleOnClick = () => {
      dispatch(
        setAgentListFilterModel({
          items: [
            {
              field: "agentName",
              value: topName,
              operatorValue: GridLogicOperator.EQ,
            },
          ],
        })
      );
    };
    if (topName === "No Production") {
      return <Typography style={{ color: "#0066ff" }}>{topName}</Typography>;
    } else {
      return (
        <Link
          onClick={handleOnClick}
          to={{
            pathname: `/AgentList`,
          }}
        >
          <Typography>{topName}</Typography>
        </Link>
      );
    }
  };

  const currentProductionData =
    currentProduction &&
    currentProduction.production &&
    currentProduction.production[currentYear]
      ? currentProduction.production[currentYear]
      : null;
  const ma_top =
    currentProductionData && currentProductionData.ma_top
      ? currentProductionData.ma_top
      : "No Production";
  const ma_total =
    currentProductionData && currentProductionData.ma_total
      ? currentProductionData.ma_total
      : 0;
  const ms_top =
    currentProductionData && currentProductionData.ms_top
      ? currentProductionData.ms_top
      : "No Production";
  const ms_total =
    currentProductionData && currentProductionData.ms_total
      ? currentProductionData.ms_total
      : 0;
  let minHeight = "";
  if (minHeight) {
    minHeight = { minHeight };
  }

  const inProcess = [
    "Agent Cert",
    "Pending writing",
    "Received",
    "Submitted",
    "Submitted missing doc",
    "Submitted no cert",
  ];

  return (
    <Paper
      className={classes.root}
      style={{ minHeight: minHeight }}
      elevation={5}
    >
      <Grid
        container
        direction="column"
        alignItems="flex-start"
        justifyContent="space-around"
        spacing={2}
      >
        <Grid
          item
          xs={12}
          container
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
        >
          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              alignItems="stretch"
              justifyContent="center"
            >
              <Typography className={classes.typoGraphy}>
                {agencyName}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ width: "100%" }}>
          <Paper>
            {ma_total === undefined ? (
              <CircularProgress />
            ) : (
              <div>
                <Grid
                  container
                  className={classes.header}
                  justifyContent="center"
                >
                  <Typography className={classes.headerTypography}>
                    Sales Summary For PY {currentYear}
                  </Typography>
                </Grid>
                <Grid
                  container
                  direction="row"
                  alignItems="flex-start"
                  justifyContent="flex-start"
                >
                  <Grid item xs={12}>
                    <Grid
                      container
                      direction="row"
                      alignItems="flex-start"
                      justifyContent="flex-start"
                    >
                      <Grid item xs={6}>
                        <Typography className={classes.productionTotals}>
                          Total Agency MA Sales
                        </Typography>
                        <Typography
                          style={{ color: "green" }}
                          className={classes.productionTotals2}
                        >
                          {ma_total}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography className={classes.productionTotals}>
                          Top Producing MA Agent
                        </Typography>
                        {top_display(ma_top, "ma_top")}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid
                      container
                      direction="row"
                      alignItems="flex-start"
                      justifyContent="flex-start"
                    >
                      <Grid item xs={6}>
                        <Typography className={classes.productionTotals}>
                          Total Agency MS Sales
                        </Typography>
                        <Typography
                          style={{ color: "green" }}
                          className={classes.productionTotals2}
                        >
                          {ms_total}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography className={classes.productionTotals}>
                          Top Producing MS Agent
                        </Typography>
                        {top_display(ms_top, "ms_top")}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container justifyContent="center">
                  <Grid container alignItems="center" justifyContent="center">
                    <Table
                      style={{ backgroundColor: "#3e4653" }}
                      className={classes.table}
                      size="small"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell
                            style={{ color: "#eee" }}
                            className={classes.contractTypography}
                            padding={"10px"}
                            colSpan={12}
                            align="center"
                          >
                            Contract Totals
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          {filteredLabels.map((label) => {
                            return (
                              <TableCell
                                style={{ color: "#eee" }}
                                padding={"none"}
                                align="center"
                              >
                                {label}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow className={classes.contractTotals}>
                          {statusData.map((stepStatusData) => {
                            return (
                              <TableCell
                                component="th"
                                scope="row"
                                align="center"
                                className={classes.contractTotals}
                              >
                                <Link
                                  onClick={(e) => {
                                    if (toggleStatusCallback) {
                                      if (
                                        stepStatusData.label === "In Process"
                                      ) {
                                        toggleStatusCallback(e, inProcess);
                                      } else {
                                        toggleStatusCallback(e, [
                                          stepStatusData.label,
                                        ]);
                                      }
                                    }
                                  }}
                                  to={{
                                    pathname: `/AgentList`,
                                    state: {
                                      statuses:
                                        stepStatusData.label === "In Process"
                                          ? inProcess
                                          : [stepStatusData.label],
                                    },
                                  }}
                                  style={{
                                    color: stepStatusData.color,
                                  }}
                                >
                                  {stepStatusData.total}
                                </Link>
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Grid>
                </Grid>
              </div>
            )}
          </Paper>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default AgencyPill;
