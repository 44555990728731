import React from "react";
import { PieChart, pieArcLabelClasses } from "@mui/x-charts";
import { Stack } from "@mui/material";
import history from "../../../utils/history";
import { setCriteria } from "../../../store/actions/production/productionActions";
const navigate = (path, name, year) => {
  history.push(path, { state: { planYear: String(year), carrierName: name } });
};
let dataYear = "";

// this component is a pie chart that is used to display the market share of each carrier
const PieChartModelMUI = (props) => {
  const { pieData, year, carrierList } = props;
  dataYear = year;

  // this function is called when a pie slice is clicked
  const handlePieClick = (event, itemIdentifier, item) => {
    const name = item.label;
    const type = item.type;
    const year = dataYear;
    const carrierId = carrierList.filter((item) => item.name === name)[0].id;
    setCriteria(carrierId, year, type);
    navigate("/Production", name, year);
  };

  // this returns a div containing the pie, its legend, and its tooltip
  if (pieData.length !== 0) {
    const arcLabel = (item) => `${item.value}%`;
    return (
      <div style={{ width: 400, height: 500 }}>
        <Stack
          direction={{ xs: "column", md: "row" }}
          alignItems={{ xs: "flex-start", md: "center" }}
          justifyContent="space-between"
          sx={{ width: "100%" }}
        >
          <PieChart
            series={[
              {
                type: "pie",
                data: pieData.map(item => ({ ...item, value: Number(item.value) })),
                arcLabel,
                innerRadius: 120,
                outerRadius: 215,
                paddingAngle: 5,
                cornerRadius: 5,
                startAngle: -90,
                endAngle: 360,
                margin: { top: 100, bottom: 50, left: 25, right: 25 },
                alignItems: "center",
                highlightScope: { faded: "global", highlighted: "item" },
                faded: {
                  additionalRadius: -30,
                  color: "gray",
                },
              },
            ]}
            onClick={handlePieClick}
            sx={{
              [`& .${pieArcLabelClasses.root}`]: {
                fill: "black",
                fontWeight: "bold",
                fontSize: "15px",
                textAlign: "end",
              },
            }}
            width={600}
            height={600}
            margin={{ top: 100, bottom: 50, left: 25, right: 25 }}
            slotProps={{
              legend: {
                direction: "row",
                position: { vertical: "top", horizontal: "middle" },
                padding: 0,
                labelStyle: { fontSize: "20px" },
              },
            }}
          />
        </Stack>
      </div>
    );
  }
  return null;
};

export default PieChartModelMUI;
