import {SET_DOWN_LINE_COMMUNICATIONS,
  SET_DOWN_LINE_COMMUNICATIONS_ERROR,
  DOWN_LINE_COMMUNICATIONS_LOADING} from "../../constants/action-types";

  import { getNotificationsApi } from "../../../services/GraphQl/BDSWebService";

  export const setDownLineCommunications = (data) => {
    return {
      type: SET_DOWN_LINE_COMMUNICATIONS,
      payload: data,
    };
  };

  export const setDownLineCommunicationsError = (error) => {
    return {
      type: SET_DOWN_LINE_COMMUNICATIONS_ERROR,
      payload: error,
    };
  };


  export const setDownLineCommunicationsLoading = (data) => {
    return {
      type: DOWN_LINE_COMMUNICATIONS_LOADING,
      payload: data,
    };
  }

  export const fetchDownLineCommunications = (paging, filters) => {
    return async (dispatch) => {
      dispatch(setDownLineCommunicationsLoading(true));
  
      try {
        let ProResultData = await getNotificationsApi( paging, filters);
        let ProResult = ProResultData.data.awayNotifications.data === undefined
          ? ProResultData.data.getNotificationsApi
          : ProResultData.data.awayNotifications.data;
        dispatch(setDownLineCommunications(ProResult));
      } catch (error) {
        dispatch(setDownLineCommunicationsError(error));
      } finally {
        dispatch(setDownLineCommunicationsLoading(false));
      }
    };
  }
