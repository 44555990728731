import React, { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import "./LandingPage.css";
import { getAgency } from "../store/actions/index";
import { setCurrentProduction } from "../store/actions/production/CurrentProduction";
import { setPlanChange } from "../store/actions/plan-change/PlanChange";
import { setETLDates } from "../store/actions/production/productionActions";
import { setRecruiterInfo } from "../store/actions/recruiter-info/recruiterInfo";
import LoadingOverlay from "@ronchalant/react-loading-overlay";
import history from "../utils/history";
import { validOcrsCountByAgencyId } from "../services/GraphQl/BDSWebService";

const useStyles = makeStyles()((theme) => {
  return {
    root: {
      textAlign: "center",
      margin: "0px",
      display: "grid",
      webkitBackgroundSize: "cover",
      mozBackgroundSize: "cover",
      // backgroundSize: "cover",
      backgroundSize: "16px 16px",
      minHeight: "100vh",
      background: "#424242",
      backgroundColor: "rgb(68, 66, 66)",
      paddingBottom: 20,
    },
    overlay: {
      height: "100%",
    },
  };
});

const LandingPage = () => {
  const [loaderText, setLoaderText] = useState("Configuring Agency...");
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const currentProduction = useSelector(
    ({ currentProduction }) => currentProduction.currentProduction
  );
  const planChange = useSelector(({ planChange }) => planChange.production);
  const FetchingProductionError = useSelector(
    ({ currentProduction }) => currentProduction.FetchingProductionError
  );
  const FetchingPlanChangeError = useSelector(
    ({ planChange }) => planChange.FetchingPlanChangeError
  );

  let isDataLoaded = currentProduction?.currentYear && planChange !== undefined;

  const loadWithError =
    FetchingProductionError !== undefined ||
    FetchingPlanChangeError !== undefined;

  if (loadWithError) {
    alert("Data loaded with error , please refresh ");
    isDataLoaded = true;
  }

  const navigate = useCallback((path) => {
    history.push(path);
  }, []);

  const LoadInitData = useCallback(() => {
    setTimeout(() => setLoaderText("Gathering Data..."), 1500);
    setTimeout(() => setLoaderText("Collecting Agents..."), 3000);
    setTimeout(async () => {
      setLoaderText("Producing Tables...");
      const agencyId = sessionStorage.getItem("agencyId");
      const agentId = sessionStorage.getItem("agentId");

      // validOcrsCountByAgencyId(agencyId).then((count) => {
      //   const storedOcrCount = localStorage.getItem("storedOcrCount");
      //   const insertVal = storedOcrCount
      //     ? count - parseInt(storedOcrCount)
      //     : count;
      //   localStorage.setItem("storedOcrCount", `${count}`);
      //   localStorage.setItem("newOcrCount", `${insertVal}`);
      // });

      // dispatch(setRecruiterInfo());
      /* async load agency */
      dispatch(getAgency(agencyId, agentId));
      dispatch(setETLDates());
      var HasProduction = sessionStorage.getItem("HasProduction");
      if (HasProduction !== "false") {
        /* async load production */
        dispatch(setPlanChange(agencyId, agentId)).then(() => {
          dispatch(setCurrentProduction(agencyId, agentId)).then(() => {
            navigate("/");
          });
        });
      } else {
        navigate("/");
      }
    }, 4700);
  }, [dispatch, navigate]);

  useEffect(() => {
    const isDataLoaded =
      currentProduction?.production &&
      currentProduction?.currentYear &&
      planChange;
    if (!isDataLoaded) {
      LoadInitData();
    } else {
      navigate("/");
    }
  }, [
    currentProduction,
    planChange,
    FetchingProductionError,
    FetchingPlanChangeError,
    navigate,
    LoadInitData,
  ]);

  return (
    <LoadingOverlay
      active={!isDataLoaded}
      className={classes.overlay}
      styles={{
        spinner: (base) => ({
          ...base,
          width: "100px",
        }),
      }}
      spinner //={<RingLoader color={"white"} />}
      text={
        <Typography
          style={{ fontSize: 20 }}
          // ref={(ref) => {
          //   setLoaderText(ref);
          // }}
        >{`${loaderText}`}</Typography>
      }
    >
      <Grid
        className={classes.root}
        container
        spacing={0}
        direction="column"
        alignItems="center"
      />
    </LoadingOverlay>
  );
};

export default LandingPage;
