import { useState, useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Ballot from "@mui/icons-material/Ballot";
import AllOut from "@mui/icons-material/AllOut";
import BarChart from "@mui/icons-material/BarChart";
import { TabPanel, TabContext } from "@mui/lab";
import AgentContractInfo from "../agentContractInfo/AgentContractInfo";
import AgentReadyToSellInfo from "../agentReadyToSellInfo/agentsReadyToSellInfo/AgentsReadyToSellInfo";
import { makeStyles } from "tss-react/mui";
import AgentProductionInfo from "../agentProductionInfo/AgentProductionInfo";
import { setAgentProductionForAgencyListFilterModel } from "../../../../store/actions/agents/agentProductionForAgency";
import { useDispatch } from "react-redux";

const color = "secondary";
const currentYear = new Date().getFullYear().toString();

const useStyles = makeStyles({
  tab: {
    backgroundColor: color,
  },
});

const TabAgentTileInfo = (props) => {
  const { agencyId, agentId, agentsContractInfoData } = props;
  const { classes } = useStyles();
  const [value, setValue] = useState("one");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const dispatch = useDispatch();

  const createFilterModel = (currentYear) => ({
    items: [
      {
        id: 0,
        field: "salesYear",
        operator: "equals",
        value: currentYear.toString(),
      },
    ],
  });

  useEffect(() => {
    dispatch(
      setAgentProductionForAgencyListFilterModel(createFilterModel(currentYear))
    );
  }, [dispatch]);

  return (
    <Box sx={{ width: "100%" }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            textColor="secondary"
            indicatorColor="secondary"
            aria-label="secondary tabs example"
          >
            <Tab
              icon={<AllOut />}
              value="one"
              className={classes}
              title="Contracts"
            />

            <Tab
              icon={<BarChart />}
              value="two"
              className={classes}
              title="Production"
            />

            <Tab
              icon={<Ballot />}
              value="three"
              className={classes}
              title="Ready to Sell"
            />
          </Tabs>
        </Box>
        <TabPanel value="one">
          <div
            style={{
              display: "flex",
              justifyContent: "left",
              alignItems: "center",
            }}
          >
            <AgentContractInfo
              agentId={agentId}
              data={agentsContractInfoData}
            />
          </div>
        </TabPanel>
        <TabPanel value="two">
          <div
            style={{
              display: "flex",
              justifyContent: "left",
              alignItems: "center",
            }}
          >
            <AgentProductionInfo agencyId={agencyId} agentId={agentId} />
          </div>
        </TabPanel>
        <TabPanel value="three">
          <div
            style={{
              display: "flex",
              justifyContent: "left",
              alignItems: "center",
            }}
          >
            <AgentReadyToSellInfo agentId={agentId} />
          </div>
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export default TabAgentTileInfo;
