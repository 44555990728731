import client from "./client";
import * as queries from "./queries";
import * as Sentry from "@sentry/browser";
import { feedbackEmailAddress } from "../../api.config";
import stringUtls from "../../utils/stringUtils";

export const getProductionStatisticsGql = async (
  agencyId,
  year,
  hierLevel,
  offset,
  rowLimit,
  product,
  orderBy,
  principalAgentId,
  planChange
) => {
  return client.query({
    query: queries.getProductionStatisticsGql,
    variables: {
      agencyId,
      year,
      hierLevel,
      offset,
      rowLimit,
      product,
      orderBy,
      principalAgentId,
      planChange,
    },
  });
};

export const getAgencyEmailOwner = async (agencyId) => {
  return client.query({
    query: queries.getAgencyEmailOwner,
    variables: {
      agencyId,
    },
  });
};

export const getAgencyInfoForBDSAdminAccount = async (agencyId) => {
  let res = await client.query({
    query: queries.getAgencyInfoForBDSAdminAccount,
    variables: {
      agencyId,
    },
  });

  return res.data.getAgencyInfoForBDSAdminAccount;
};

export const getLatestETLDates = async () => {
  let res = await client.query({
    query: queries.getLatestETLDates,
  });

  return res.data.getLatestETLDates;
};

export const getContractStatuses = async (agencyId) => {
  let res = await client.query({
    query: queries.getContractStatuses,
    variables: {
      agencyId,
    },
  });

  return res.data.getContractStatuses;
};

export const getCarriersForCarrierIds = async (carrierIds) => {
  return client.query({
    query: queries.getCarriersForCarrierIdsGql,
    variables: {
      carrierIds,
    },
  });
};

export const getClrForUHC = async (agencyId, carrierId) => {
  return client.query({
    query: queries.getClrForUHCGql,
    variables: {
      agencyId,
      carrierId,
    },
  });
};

export const getCLR = async (agencyId) => {
  return client.query({
    query: queries.getCLRGql,
    variables: {
      agencyId,
    },
  });
};

export const getAgencyStatusInfo = async (agencyId) => {
  return client.query({
    query: queries.getAgencyStatusInfo,
    variables: {
      agencyId,
    },
  });
};

export const getAgentsInfoGql = async () => {
  return client.query({
    query: queries.getAgentsInfoGql,
  });
};

export const getAgentsContractInfo = async (agencyId) => {
  return client.query({
    query: queries.getAgentsContractInfo,
    variables: {
      agencyId,
    },
  });
};

export const getAgentsReadyToSellInfo = async (agencyId) => {
  let res = await client.query({
    query: queries.getAgentsReadyToSellInfo,
    variables: {
      agencyId,
    },
  });
  return res.data.getAgentsReadyToSellInfo;
};

export const getAgentProductionForAgency = async (agencyId, agentId) => {
  let res = await client.query({
    query: queries.getAgentProductionForAgency,
    variables: {
      agencyId,
      agentId,
    },
  });
  return res.data.getAgentProductionForAgency;
};

export const adminLoginGql = async (username, password) => {
  return client.query({
    query: queries.adminLogin,
    variables: {
      username,
      password,
    },
  });
};

export const refreshAdminLoginGql = async (username, refreshToken) => {
  const res = await client.query({
    query: queries.refreshAdminLogin,
    variables: {
      username,
      refreshToken,
    },
  });
  return res.data.refreshAdminLogin;
};

export const login = async (username, password) => {
  let loginResult = "";
  if (username.search("berwickinsurance.com") > -1) {
    loginResult = await adminLoginGql(username, password)
      .then((res) => {
        return res.data.adminLogin;
      })
      .catch((e) => {
        throw e;
      });
  } else {
    loginResult = await agencyLoginGql(username, password)
      .then((res) => {
        return res.data.agencyLogin;
      })
      .catch((e) => {
        throw e;
      });
  }

  sessionStorage.setItem("bdsApiToken", loginResult.token);
  sessionStorage.setItem("email", username);
  sessionStorage.setItem("type", loginResult.__typename);
  sessionStorage.setItem("bdsApiRefreshToken", loginResult.refreshToken);
  sessionStorage.setItem("currentTimeOnLogin", JSON.stringify(Date.now()));
  if (loginResult.__typename === "AgencyLogin") {
    setAgencySessionVariables(loginResult.agencyInfo);
  }
  return loginResult;
};
export const setAgencySessionVariables = async (res) => {
  sessionStorage.setItem("agencyId", res.agencyId);
  const agencyName = stringUtls.firstUpperLetter(res.agencyName);
  sessionStorage.setItem("agencyName", agencyName);
  sessionStorage.setItem("agentId", res.agentId);
  sessionStorage.setItem("agent_total", res.agentTotal);

  Sentry.setUser({
    agencyId: res.agencyId,
    agencyName: res.agencyName,
    id: res.agentId,
  });
};

export const agencyLoginGql = async (username, password) => {
  return client.query({
    query: queries.agencyLogin,
    variables: {
      username,
      password,
    },
  });
};

export const refreshAgencyLoginGql = async (username, refreshToken) => {
  const res = await client.query({
    query: queries.refreshAgencyLogin,
    variables: {
      username,
      refreshToken,
    },
  });
  return res.data.refreshAgencyLogin;
};

export const sendContractRequestGql = async (input) => {
  let res = await client.mutate({
    mutation: queries.sendContractRequest,
    variables: {
      input,
    },
  });

  return res;
};

export const certsPerAgent = (agentId, agencyId) => {
  return client.query({
    query: queries.certsPerAgentDash,
    variables: {
      agentId,
      agencyId,
    },
  });
};

export const getSubagenciesForCarrier = async (agencyId, carrierId) => {
  let res = await client.query({
    query: queries.getSubAgenciesForCarrier,
    variables: {
      agencyId,
      carrierId,
    },
  });

  return res.data.getSubAgenciesForCarrier;
};

export const getSubAgencies = async (agencyId) => {
  let res = await client.query({
    query: queries.getSubAgencies,
    variables: {
      agencyId,
    },
  });

  return res.data.getSubAgencies;
};

export const getCurrentProductionYear = async () => {
  let res = await client.query({
    query: queries.getCurrentProductionYear,
  });

  return res.data.getCurrentProductionYear.currentproduction;
};

export const getLevelsForCarrier = async (agentId, carrierId) => {
  let res = await client.query({
    query: queries.getLevelsForCarrier,
    variables: {
      agentId,
      carrierId,
    },
  });

  return res.data.getLevelsForCarrier;
};

export const getAvailablePlanYears = async () => {
  const currentYear =
    sessionStorage.getItem("currentProductionYear") ||
    (await getCurrentProductionYear());
  const currentYearInt = parseInt(currentYear);
  const lastSupportedYear = currentYearInt - 2;
  let year = currentYearInt;
  const planYears = [];
  while (lastSupportedYear <= year) {
    planYears.push(year);
    year -= 1;
  }
  return planYears;
};

export const getPlanChangeCarriers = async () => {
  let res = await client.query({
    query: queries.getPlanChangeCarriers,
  });

  return res.data.getPlanChangeCarriers;
};

export const getSubAgenciesFor1904 = async () => {
  let res = await client.query({
    query: queries.getSubAgenciesFor1904,
  });

  return res.data.getSubAgenciesFor1904;
};

export const getStates = async () => {
  let res = await client.query({
    query: queries.getStates,
  });

  return res.data.getStates;
};

export const getAgents = async (agencyId) => {
  let res = await client.query({
    query: queries.getAgents,
    variables: {
      agencyId,
    },
  });

  return res.data.getAgents;
};

export const getAllCarriers = async () => {
  let res = await client.query({
    query: queries.getAllCarriers,
  });

  return res.data.getAllCarriers;
};

export const getCarriers = async () => {
  let res = await client.query({
    query: queries.getCarriers,
  });
  return res.data.getCarriers;
};

export const sendDenialMessages = async (
  agencyName,
  agentEmail,
  agencyEmail,
  denials
) => {
  const bodyStyles =
    "style='font-family: Arial, Helvetica, sans-serif;border-collapse: collapse;width: 100%;'";
  const tableStyles =
    "style='border-collapse:collapse;font-family:Arial;width: 70%'";
  const thCarrierStyles =
    "style='border: 1px solid #ddd;padding: 8px;text-align: center;color: rgb(238, 238, 238); background-color: rgb(63, 81, 181);width:30%;'";
  const thMessageStyles =
    "style='border: 1px solid #ddd;padding: 8px;text-align: center;color: rgb(238, 238, 238); background-color: rgb(63, 81, 181);width:70%;'";
  const trStyles = "style='background-color: #f2f2f2;'";
  const tdStyles =
    "style='border: 1px solid #ddd;padding: 8px;text-align: center;'";
  const contractRows = denials
    .map(
      (d) =>
        `<tr ${trStyles}><td ${tdStyles}>${d.carrierName}</td><td ${tdStyles}>${d.denialMessage}</td></tr>`
    )
    .join("");
  const contractTable = `<table ${tableStyles}><tr ${trStyles}><th ${thCarrierStyles}>Carrier</th><th ${thMessageStyles}>Message</th></tr>${contractRows}</table>`;
  const body = `<body ${bodyStyles}>
  <div>
  We were unable to process your contract request.  Please see the comments below or contact your agency for more information.
  </div>
  <br />
  <br />
  <h5>Contract Requests</h5>
  <div>${contractTable}</div>
  <br />
  <div>`;

  console.log(agentEmail);
  let res = await client.mutate({
    mutation: queries.sendEmailApi,
    variables: {
      from: "bit@berwickinsurance.com",
      to: agentEmail,
      bcc: agencyEmail,
      subject: `Contract request update from ${agencyName}`,
      body,
      save: true,
    },
  });

  return res.data.sendEmailApi;
};

export const sendFeedback = async (name, email, feedback) => {
  let body = "";
  if (name) {
    body = "Feedback sent from: " + name;
  }
  if (email && name) {
    body = body + " with email: " + email;
  } else if (email) {
    body = "Feedback sent from: " + email;
  }
  body += "<br><br><br>" + feedback;

  let res = await client.mutate({
    mutation: queries.sendEmailApi,
    variables: {
      from: "bit.testing@berwickinsurance.com",
      to: feedbackEmailAddress,
      subject: "Agency Dashboard Feedback from: " + name,
      body,
      save: true,
    },
  });

  return res.data.sendEmailApi;
};

export const sendMsGraphEmail = async (emailOutput) => {
  let res = await client.mutate({
    mutation: queries.sendEmailApi,
    variables: {
      ...emailOutput,
      save: true,
    },
  });

  return res.data.sendEmailApi;
};

export const isUserAuthenticated = async () => {
  const type = sessionStorage.getItem("type");
  if (!type) return false;
  if (type === "AdminLogin") {
    return (
      (await isAdminTokenValid()) && sessionStorage.getItem("agencyId") !== null
    );
  } else {
    return await isAgencyTokenValid();
  }
};

export const isAdminTokenValid = async () => {
  let res = await client.query({
    query: queries.isAdminTokenValid,
  });

  return res.data.isAdminTokenValid;
};

export const isAgencyTokenValid = async () => {
  let res = await client.query({
    query: queries.isAgencyTokenValid,
  });

  return res.data.isAgencyTokenValid;
};

//Leave in the case client uses refresh inside onError function
export const refreshAuthToken = async (refreshToken) => {
  let result;
  const type = sessionStorage.getItem("type");
  const username = sessionStorage.getItem("email");
  if (type === "AdminLogin") {
    result = await refreshAdminLoginGql(username, refreshToken);
    sessionStorage.setItem("bdsApiToken", result.token);
  } else {
    result = await refreshAgencyLoginGql(username, refreshToken);
    sessionStorage.setItem("bdsApiToken", result.token);
  }
  return result.token;
};

export const getCertsPerCarrier = async (agencyId) => {
  let res = await client.query({
    query: queries.getCertsPerCarrier,
    variables: {
      agencyId,
    },
  });
  return res.data.getCertsPerCarrier;
};

export const getCertsTable = async (agencyId) => {
  let res = await client.query({
    query: queries.getCertsTable,
    variables: {
      agencyId,
    },
  });
  return res.data.getCertsTable;
};

export const pushNotificationApi = async (notification) => {
  let res = await client.mutate({
    mutation: queries.pushNotification,
    variables: { input: notification },
  });

  return res.data.pushNotification;
};

export const getNotificationsApi = async (paging, filters) => {
  return await client.query({
    query: queries.awayNotifications,
    variables: { paging, filters },
  });
};

export const agentHierarchyWithIds = async (agentId, agencyId) => {
  const res = await client.query({
    query: queries.agentHierarchyWithIds,
    variables: { agentId, agencyId },
  });
  return res.data.agentHierarchyWithIds;
};

export const contractRequestsAgency = async (agencyId) => {
  const res = await client.query({
    query: queries.contractRequestsAgency,
    variables: { agencyId },
  });

  res.data.contractRequestsAgency.map(
    (request) => (request.phone = request.phone?.replace(/\D/gi, ""))
  );

  return res.data.contractRequestsAgency;
};
let crSubscription;
export const crSubscribe = async (agencyId, callback) => {
  if (crSubscription?._state === "ready") {
    return;
  }
  crSubscription = client
    .subscribe({
      query: queries.contractRequestModified,
      variables: { agencyId: agencyId },
    })
    .subscribe({
      next: ({ data }) => {
        callback(data.contractRequestModified);
      },
    });
  return crSubscription;
};
export const crUnsubscribe = () => {
  if (crSubscription) {
    crSubscription.unsubscribe();
  }
};
let ocrSubscription;
export const ocrSubscribe = async (agencyId, callback) => {
  if (ocrSubscription?._state === "ready") {
    return;
  }
  ocrSubscription = client
    .subscribe({
      query: queries.onboardContractRequestInsert,
      variables: { agencyId: agencyId },
    })
    .subscribe({
      next: ({ data }) => {
        callback(data.onboardContractRequestInsert);
      },
    });
  return ocrSubscription;
};
export const ocrUnsubscribe = () => {
  if (ocrSubscription) {
    ocrSubscription.unsubscribe();
  }
};
export const agencyContractRequest = async (input) => {
  await client.query({
    query: queries.agencyContractRequest,
    variables: { input },
  });
};
export const sendDashEmailAws = async (input) => {
  return await client.mutate({
    mutation: queries.sendDashEmailAws,
    variables: { input },
  });
};
export const updateAgencyContractRequest = async (input) => {
  return await client.mutate({
    mutation: queries.updateAgencyContractRequest,
    variables: { input },
  });
};
export const agencyAgentUserInfo = async (agentIds) => {
  const { data } = await client.query({
    query: queries.agencyAgentUserInfo,
    variables: { agentIds },
  });
  return data.agencyAgentUserInfo;
};

export const recruiterInfoById = async (id) => {
  const result = await client.query({
    query: queries.recruiterInfoById,
    variables: { id },
  });
  return result.data.recruiterInfoById;
};

export const onboardLeadsByRecruiter = async (recruiterId) => {
  const result = await client.query({
    query: queries.onboardLeadsByRecruiter,
    variables: { recruiterId },
  });
  return result.data.onboardLeadsByRecruiter;
};

export const updateOnboardLead = async (input) => {
  const result = await client.mutate({
    mutation: queries.updateOnboardLead,
    variables: { input },
  });
  return result.data.updateOnboardLead;
};

export const updateOcr = async (input) => {
  const result = await client.mutate({
    mutation: queries.updateOcr,
    variables: { input },
  });
  return result.data.updateOcr;
};

export const createAgentLead = async (agentLeadInput) => {
  const result = await client.mutate({
    mutation: queries.createAgentLead,
    variables: { agentLeadInput },
  });
  return result.data.createAgentLead;
};

export const ocrUpdateStatus = async (idArr, status) => {
  const result = await client.mutate({
    mutation: queries.ocrUpdateStatus,
    variables: { idArr, status },
  });
  return result.data.ocrUpdateStatus;
};

export const updateRecruiter = async (input) => {
  const result = await client.mutate({
    mutation: queries.updateRecruiter,
    variables: { input },
  });
  return result.data.updateRecruiter;
};

export const validOcrsCountByAgencyId = async (agencyId) => {
  const result = await client.query({
    query: queries.validOcrsCountByAgencyId,
    variables: { agencyId },
  });
  return result.data.validOcrsCountByAgencyId.count;
};

export const getCalc = async (eligibleDate, enrollmentDate) => {
  return await client.query({
    query: queries.getCalc,
    variables: { eligibleDate, enrollmentDate },
  });
};

export const getAgencyProductionByCarrierAndProduct = async (agencyId) => {
  return await client.query({
    query: queries.getAgencyProductionByCarrierAndProduct,
    variables: { agencyId },
  });
};

export const getProductionByCarrierAndMonth = async (agencyId, product) => {
  return await client.query({
    query: queries.getProductionByCarrierAndMonth,
    variables: { agencyId, product },
  });
};

export const getProductionByCarrierAndQtr = async (agencyId) => {
  return await client.query({
    query: queries.getProductionByCarrierAndQtr,
    variables: { agencyId },
  });
};

export const getLatestProductionSalesYear = async () => {
  return await client.query({
    query: queries.getLatestProductionSalesYear,
  });
};

export const getAgencyStatsProfileOverYears = async (agencyId) => {
  return await client.query({
    query: queries.getAgencyStatsProfileOverYears,
    variables: { agencyId },
  });
};

export const cognitoForgotPassword = async (input) => {
  return await client.mutate({
    mutation: queries.cognitoForgotPassword,
    variables: input,
  });
};

export const cognitoConfirmPassword = async (input) => {
  return await client.mutate({
    mutation: queries.cognitoConfirmPassword,
    variables: input,
  });
};

export const updateAgencyDashboardEnabled = async (agencyId, dashboardEnabled) => {
  return await client.mutate({
    mutation: queries.updateAgencyDashboardEnabled,
    variables: { agencyId, dashboardEnabled },
  });
};

export const registerValidationEmail = async (recipient, validationCode) => {
  const response = await client.query({
    query: queries.registerValidationEmail,
    variables: { recipient, validationCode },
  });
  return response.data.registerValidationEmail; // This should be a boolean
};

export const addOrUpdateCognitoAgency = async (options) => {
  return await client.query({
    query: queries.addOrUpdateCognitoAgency,
    variables:  { options }, 
  });
};