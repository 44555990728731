/** This will load agents for large agencies in the background */
import { getProductionStatisticsGql } from "../GraphQl/BDSWebService";
import * as Sentry from "@sentry/browser";

const ProductionLoader = {
  loadProduction,
  loadPlanChange,
};

async function loadProduction(agencyId, year, level) {
  //const increment = 4000;
  //const res = await loadChunk(agencyId, year, level, agentId, increment, 0, []);
  try {
    const res = await getProductionStatisticsGql(
      parseInt(agencyId),
      parseInt(year),
      level,
      null,
      null
    );
    return res;
  } catch (e) {
    Sentry.captureMessage(
      "Production Loader error: " + e.message,
      Sentry.Severity.Error
    );
  }
}

async function loadPlanChange(agencyId, year, level) {
  try {
    const res = await getProductionStatisticsGql(
      parseInt(agencyId),
      parseInt(year),
      level,
      null,
      null,
      null,
      null,
      null,
      true
    );
    return res;
  } catch (e) {
    Sentry.captureMessage(
      "Plan Change Loader error: " + e.message,
      Sentry.Severity.Error
    );
  }
}

export default ProductionLoader;
