import {
  CLEAR_AGENT_CONTRACT_INFO_LIST,
  SET_AGENT_CONTRACT_INFO_LIST,
  AGENT_CONTRACT_INFO_LOADING,
  SET_AGENT_CONTRACT_INFO_FILTER_MODEL,
  CLEAR_AGENT_CONTRACT_INFO_FILTER_MODEL,
} from "../../constants/action-types";
import { getAgentsContractInfo } from "../../../services/GraphQl/BDSWebService";

/* redux-thunk implementation */
export const fetchAgentContractList = (agencyId) => {
  return async (dispatch) => {
    dispatch(setAgentContractLoading(true));
    await getAgentsContractInfo(agencyId)
      .then(async (res) => {
        if (res) {
          dispatch(setAgentContractList(res.data.getAgentsContractInfo));
        }
      })
      .then(() => {
        dispatch(setAgentContractLoading(false));
      });
  };
};

export const clearAgentContractList = () => {
  return (dispatch) => {
    dispatch(clearAgentContractListAction([]));
  };
};

/* redux action */
const setAgentContractList = (data) => {
  return {
    type: SET_AGENT_CONTRACT_INFO_LIST,
    payload: data,
  };
};

/* redux action */
const clearAgentContractListAction = (data) => {
  return {
    type: CLEAR_AGENT_CONTRACT_INFO_LIST,
    payload: data,
  };
};

export const setAgentContractLoading = (data) => {
  return (dispatch) => {
    dispatch({
      type: AGENT_CONTRACT_INFO_LOADING,
      payload: data,
    });
  };
};

export const setAgentListFilterModel = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_AGENT_CONTRACT_INFO_FILTER_MODEL,
      payload: data,
    });
  };
};

export const clearAgentListFilterModel = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_AGENT_CONTRACT_INFO_FILTER_MODEL,
    });
  };
};
