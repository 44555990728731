import { useSelector } from "react-redux";
import classes from "../../DataGridTemplate/DataGridTemplate.module.css";
import DataGridTemplate from "../../DataGridTemplate/DataGridTemplate";
import ReadyToSellColumns from "./ReadyToSellColumns";
import ReadyToSellData from "./ReadyToSellData";
import { CircularProgress, Box } from "@mui/material";

const sortByName = (a, b) =>
  a.agentName?.localeCompare(b?.agentName?.toLowerCase());

const ReadyToSell = () => {
  const { data, filterModel } = useSelector((state) => state.readyToSell);
  const columns = ReadyToSellColumns();
  const uniqueRows = ReadyToSellData({ data });

  return (
    <div className={classes.myClass}>
      {uniqueRows.length > 0 ? (
        <DataGridTemplate
          columns={columns}
          uniqueRows={uniqueRows.sort(sortByName)}
          isLoading={false}
          paginationProps={{ rowsPerPageOptions: [25, 50, 100], pageSize: 25 }}
          CustomToolbar="FilterExport"
          filterModel={filterModel}
          density="compact"
          size="medium"
          autoHeight={false}
        />
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="48vh"
        >
          <CircularProgress />
        </Box>
      )}
    </div>
  );
};

export default ReadyToSell;
