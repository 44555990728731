import {
  CLEAR_READY_TO_SELL_LIST,
  SET_READY_TO_SELL_LIST,
  READY_TO_SELL_LOADING,
  SET_READY_TO_SELL_FILTER_MODEL,
  CLEAR_READY_TO_SELL_FILTER_MODEL,
} from "../../constants/action-types";
import { getCertsTable } from "../../../services/GraphQl/BDSWebService";

export const clearReadyToSellList = () => {
  return (dispatch) => {
    dispatch(clearReadyToSellListAction([]));
  };
};

/* redux action */
export const setReadyToSellList = (data) => {
  return {
    type: SET_READY_TO_SELL_LIST,
    payload: data,
  };
};

/* redux action */
export const clearReadyToSellListAction = (data) => {
  return {
    type: CLEAR_READY_TO_SELL_LIST,
    payload: data,
  };
};

export const setReadyToSellFilterModel = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_READY_TO_SELL_FILTER_MODEL,
      payload: data,
    });
  };
};

export const setIsLoading = (data) => {
  return {
    type: READY_TO_SELL_LOADING,
    payload: data,
  };
};

export const clearReadyToSellFilterModel = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_READY_TO_SELL_FILTER_MODEL,
    });
  };
};

/* redux-thunk implementation */
export const fetchReadyToSellList = (agencyId) => {
  return async (dispatch) => {
    dispatch(setIsLoading(true));
    await getCertsTable(agencyId)
      .then(async (res) => {
        if (res) {
          dispatch(setReadyToSellList(res));
        }
      })
      .then(() => {
        dispatch(setIsLoading(false));
      });
  };
};
