import { AccountCircle } from "@mui/icons-material";
import { Button, ButtonGroup } from "@mui/material";
import { GridLogicOperator } from "@mui/x-data-grid-pro";
import { setAgentListFilterModel } from "../../../store/actions/agents/agentsContractInfo"
import history from "../../../utils/history";
import moment from "moment";
import { useCallback } from "react";
import { useDispatch } from "react-redux";

const PlanChangesColumns = () => {
    const dispatch = useDispatch();
const navigate = useCallback(
    (path) => {
      history.push(path);
    },
    []
  );
  const handleClick = useCallback(
    (e, cellValue) => {
      dispatch(
        setAgentListFilterModel({
          items: [
            {
              field: "agentName",
              value: cellValue,
              operatorValue: GridLogicOperator.EQ,
            },
          ],
        })
      );
      navigate("/AgentList");
    },
    [dispatch, navigate]
  );

const commonProps = {
  headerAlign: "center",
  align: "center",
  headerClassName: "super-app-theme--header",
  flex: 0,
};

const columns = [
  {
    field: "agentName",
    headerName: "Agent Name",
    width: 200,
    type: "string",
    renderCell: (params) => {
      return (
        <ButtonGroup variant="contained">
          <Button
            endIcon={<AccountCircle />}
            onClick={(event) => handleClick(event, params.value)}
            sx={{ width: "190px", flex: 1 }}
          >
            {params.value}
          </Button>
        </ButtonGroup>
      );
    },
  },
  {
    field: "carrierName",
    headerName: "Carrier Name",
    width: 125,
    type: "string",
  },
  {
    field: "companyName",
    headerName: "Company Name",
    width: 150,
    type: "string",
  },
  {
    field: "hlevel",
    headerName: "Hlevel",
    width: 100,
    type: "string",
  },
  {
    field: "ma_production",
    headerName: "MA",
    width: 10,
    type: "number",
  },
  {
    field: "medsupp_production",
    headerName: "MEDSUPP",
    width: 10,
    type: "number",
  },
  {
    field: "pdp_production",
    headerName: "PDP",
    width: 10,
    type: "number",
  },
  {
    field: "planYear",
    headerName: "Plan Year",
    width: 10,
    type: "string",
  },
  {
    field: "uplines",
    headerName: "Uplines",
    width: 480,
    type: "string",
  },
  {
    field: "county",
    headerName: "County",
    width: 150,
    type: "string",
  },
  {
    field: "state",
    headerName: "State",
    width: 10,
    type: "singleSelect",
    valueOptions: [
      "AK",
      "AL",
      "AR",
      "AZ",
      "CA",
      "CO",
      "CT",
      "DC",
      "DE",
      "FL",
      "GA",
      "HI",
      "IA",
      "ID",
      "IL",
      "IN",
      "KS",
      "KY",
      "LA",
      "MA",
      "MD",
      "ME",
      "MI",
      "MN",
      "MO",
      "MS",
      "MT",
      "NC",
      "ND",
      "NE",
      "NH",
      "NJ",
      "NM",
      "NV",
      "NY",
      "OH",
      "OK",
      "OR",
      "PA",
      "PR",
      "RI",
      "SC",
      "SD",
      "TN",
      "TX",
      "UT",
      "VA",
      "VT",
      "WA",
      "WI",
      "WV",
      "WY",
    ],
  },
  {
    field: "effectivedate",
    headerName: "Effective Date",
    width: 100,
    type: "date",
    valueFormatter: (params) => {
      return moment(params.value).format("L");
    },
  },
];

return columns.map((column) => ({ ...commonProps, ...column }));
}

export default PlanChangesColumns;

