import React, { useState } from "react";
import {
  Button,
  Box,
  TextField,
  Card,
  CardContent,
  Typography,
  Container,
  Grid,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import {
  getAgencyStatusInfo,
  updateAgencyDashboardEnabled,
  addOrUpdateCognitoAgency
} from "../../../services/GraphQl/BDSWebService";

const RegisterAgency = (props) => {
  const [user, setUser] = useState({
    userName: "",
    password: "",
    password2: "",
  });
  const {registrationId, email} = props;
  const registrationID = parseInt(registrationId);
  const history = useHistory();
  const handleChange = (e) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await getAgencyStatusInfo(registrationID);
      let dashboardEnabled = "false";
      if (response.data.getAgencyStatusInfo.length > 0) {
        dashboardEnabled =
          response.data.getAgencyStatusInfo[0].dashboardEnabled;
        if (dashboardEnabled === "true") {
          Swal.fire({
            title: "Agency Already Registered",
            icon: "warning",
            text:
              "This Agency was already registered.  You will need to change the password if applicable. Questions?  Please " +
              "contact Agent Support at 888-745-2320  " +
              "or agentsupport@berwickinsurance.com for more information.",
          }).then(() => {
            history.push("/login");
          });
        } else {

          // Update the agency dashboard enabled status in the database
          await updateAgencyDashboardEnabled(registrationID, true);
          

          const options = {agencyId: registrationId, username: user.userName, email: email, enabled: true, password: user.password};

          // Add or update the agency in cognito
          await addOrUpdateCognitoAgency(
            options
          );

          Swal.fire({
            title: "Agency has been successfully Registered",
            icon: "success",
            text: "You have successfully registered your agency.  You can now login to the system.",
          }).then(() => {
            history.push("/login");
          });
        }
      } else {
        Swal.fire({
          title: "Agency Is Not Registered",
          icon: "error",
          text:
            "This Agency is not registered in our system.  You will need to " +
            "contact Agent Support at 888-745-2320 " +
            "or agentsupport@berwickinsurance.com to get added to our system.",
        }).then(() => {
          history.push("/login");
        });
      }
    } catch (error) {
      Swal.fire({
        title: "Issue with Registration",
        icon: "error",
        text: error,
      });
    }
  };

  const handleCancel = () => {
    setUser({
      registrationID: "",
      userName: "",
      password: "",
      password2: "",
    });
    history.push("/login");
  };

  const textFieldStyles = {
    width: "100%", // Adjust the width as needed
    "& .MuiOutlinedInput-root": {
      color: "#000",
      fontFamily: "Arial",
      fontWeight: "bold",
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#2e2e2e",
        borderWidth: "2px",
      },
      "&.Mui-focused": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#3c4143",
          borderWidth: "3px",
        },
      },
    },
    "& .MuiInputLabel-outlined": {
      color: "#2e2e2e",
      fontWeight: "bold",
      "&.Mui-focused": {
        color: "#3c4143",
        fontWeight: "bold",
      },
    },
  };

  const buttonStyles = {
    backgroundColor: "#3c4143",
    "&:hover": { backgroundColor: "#616161" },
  };

  const buttonSuccessStyles = {
    backgroundColor: "green",
    "&:hover": { backgroundColor: "#33c520" },
  };

  const validatePassword = (password) => {
    const regex =
      /^(?=(.*[A-Z]){3,})(?=(.*[a-z]){3,})(?=(.*\d){3,})(?=(.*[\W_]){3,}).*$/;
    return regex.test(password);
  };

  return (
    <Grid>
      <Container
        sx={{ alignContent: "center", textAlign: "center" }}
        maxWidth="lg"
      >
        <Card
          variant="outlined"
          sx={{
            bgcolor: "white",
            width: "800px",
            minHeight: "400px",
            margin: "auto",
            textAlign: "center",
            padding: "15px",
            borderRadius: "10px",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            marginTop: "150px",
          }}
        >
          <CardContent>
            <Typography
              variant="h4"
              fontSize="5"
              align="center"
              color="text.secondary"
            >
              Agency Registration
            </Typography>
            <br />
            <Typography
              variant="h5"
              fontSize="5"
              align="center"
              color="text.secondary"
            >
              Registration ID {registrationId}
              </Typography> 
            <br />
            <TextField
              type="text"
              name="userName"
              sx={textFieldStyles}
              required
              placeholder="Username *"
              onChange={(e) => handleChange(e)}
            />
            <br />
            <br />
            <TextField
              type="password"
              name="password"
              sx={textFieldStyles}
              required
              placeholder="Password *"
              onChange={(e) => handleChange(e)}
              onKeyDown={(e) => {
                if (e.key === ' ') {
                  e.preventDefault();
                }
              }}
              maxLength="14"
            />
            <br />
            <br />
            <TextField
              type="password"
              name="password2"
              sx={textFieldStyles}
              required
              placeholder="Confirm Password *"
              onChange={(e) => handleChange(e)}
              onKeyDown={(e) => {
                if (e.key === ' ') {
                  e.preventDefault();
                }
              }}
              maxLength="14"
            />
            <br />
            <br />

            {validatePassword(user.password) !== true ||
            user.password !== user.password2 ||
            user.password.length <= 14 ||
            user.password2.length <= 14 ? (
              <Typography variant="body2" color="error" sx={{ width: "250px" }}>
                Password must be at least 14 characters long and include at
                least 3 of the following: upper-case letters, lower-case
                letters, numbers, and special characters.
              </Typography>
            ) : (
              <Typography variant="body2" color="green" sx={{ width: "250px" }}>
                Passwords match and meets requirements
              </Typography>
            )}

            <br />
            <br />

            <Box display="flex" justifyContent="flex-end" gap={2}>              
              <Button
                sx={buttonSuccessStyles}
                variant="contained"
                size="small"
                onClick={handleSubmit}
                disabled={
                  user.userName === "" ||
                  user.password === "" ||
                  user.password2 === "" ||
                  validatePassword(user.password) !== true ||
                  user.registrationID === "" ||
                  user.password !== user.password2 ||
                  user.password.length <= 14 ||
                  user.password2.length <= 14
                    ? true
                    : false
                }
              >
                Register
              </Button>
              <Button
                sx={buttonStyles}
                variant="contained"
                size="small"
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Container>
    </Grid>
  );
};

export default RegisterAgency;
