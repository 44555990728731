import React, { useState } from "react";
import { Popper } from "@mui/base/Popper";
import { styled, css } from "@mui/system";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import { Grid } from "@mui/material";
import Notes from "@mui/icons-material/Notes";

const PopperNotes = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const noteIntro = `Note: Information displayed is derived from carrier reports as they are received.`;
  const realtimeStatus = `For real-time status, please refer to the applicable carrier portal or contact the carrier’s Broker Support hotline.`;
  const agentResponsibility = `Agents are ultimately responsible for ensuring they are properly licensed, appointed, and certified for the applicable plan year prior to selling.`;
  const disclaimer = `Berwick Insurance Group is not responsible for any errors or omissions of any Ready to Sell (RTS) information provided.`;

  const notes = `${noteIntro}\n${realtimeStatus}\n${agentResponsibility}\n${disclaimer}`;

  /// This function is used to handle the click event
  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  /// This function is used to handle the click away event
  const handleClickAway = () => {
    setAnchorEl(false);
  };

  /// This is the popper open state
  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;
  const styles = {
    gridItem: {
      m: 1,
      width: 800,
      margin: "20px",
      alignContent: "center",
    },
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Grid item xs={8} style={styles.gridItem}>
        <TriggerButton
          aria-describedby={id}
          type="button"
          onClick={handleClick}
          fullWidth
          icon={<Notes />}
        >
          NOTE...  <Notes />
        </TriggerButton>
        <Popper id={id} open={open} anchorEl={anchorEl}>
          <StyledPopperDiv>
            <div>{notes}</div>
          </StyledPopperDiv>
        </Popper>
      </Grid>
    </ClickAwayListener>
  );
};

/// This is the styled component for the trigger button
const blue = {
  50: "#F0F7FF",
  100: "#C2E0FF",
  200: "#99CCF3",
  300: "#66B2FF",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  700: "#0059B2",
  800: "#004C99",
  900: "#003A75",
};

/// This is the styled component for the trigger button
const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

/// This is the styled component for the trigger button
const TriggerButton = styled("button")(
  ({ theme }) => `
  width: 100%;
  
  
  background-color: ${blue[500]};
  padding: 8px 16px;
  border-radius: 8px;
  color: black;
  transition: all 150ms ease;
  cursor: pointer;
  border: 1px solid ${blue[500]};
  box-shadow: 0 2px 1px ${
    theme.palette.mode === "dark"
      ? "rgba(0, 0, 0, 0.5)"
      : "rgba(45, 45, 60, 0.2)"
  }, inset 0 1.5px 1px ${blue[400]}, inset 0 -2px 1px ${blue[600]};

  &:hover {
    background-color: ${blue[600]};
  }

  &:active {
    background-color: ${blue[700]};
    box-shadow: none;
  }

  &:focus-visible {
    box-shadow: 0 0 0 4px ${
      theme.palette.mode === "dark" ? blue[300] : blue[200]
    };
    outline: none;
  }

  &.disabled {
    opacity: 0.4;
    cursor: not-allowed;
    box-shadow: none;
    &:hover {
      background-color: ${blue[500]};
    }
  }
`
);

/// This is the styled component for the popper div
const StyledPopperDiv = styled("div")(
  ({ theme }) => css`
    placement: "top-start";
    width: 23%;
    background-color: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
    border-radius: 8px;
    border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
    box-shadow: ${theme.palette.mode === "dark"
      ? `0px 4px 8px rgb(0 0 0 / 0.7)`
      : `0px 4px 8px rgb(0 0 0 / 0.1)`};
    padding: 0.75rem;
    color: ${theme.palette.mode === "dark" ? grey[100] : grey[700]};
    font-size: 0.875rem;
    font-family: "IBM Plex Sans", sans-serif;
    font-weight: 500;
    opacity: 1;
    margin: 0.25rem 0;
  `
);

export default PopperNotes;
