const commonProps = {
  headerAlign: "center",
  align: "center",
  headerClassName: "super-app-theme--header",
  cellClassName: "super-app-theme--cell",
  flex: 1,
  sortable: false,
  disableColumnMenu: true,
};

const columns = [
  {
    field: "carrierName",
    headerName: "Carrier Name",
    type: "string",
  },
  {
    field: "count",
    headerName: "RTS Agent Count",
    type: "string",
  },
  {
    field: "planYear",
    headerName: "Plan Year",
    type: "string",
  },
];

const ReadyToSellByCarrierColumns = () => {
  return columns.map((column) => ({ ...commonProps, ...column }));
};

export default ReadyToSellByCarrierColumns;
