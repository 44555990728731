import React, { useState } from "react";
import {
  Button,
  Box,
  TextField,
  Card,
  CardContent,
  Typography,
  Container,
  Grid,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { registerValidationEmail } from "../../../services/GraphQl/BDSWebService";

const RegisterVerification = (props) => {
  const { setIsRegistering, setIsValidation, uniqueId, setRegistrationId, setEmail } = props;
  const [registration, setRegistration] = useState({
    registrationID: 0,
    email: "",
  });

  const history = useHistory();
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const validateEmail = (email) => {
    return emailRegex.test(email);
  };

  const handleRegistrationChange = (e) => {
    setRegistration({
      ...registration,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Verify Registration ID and Email
    if (
      registration.registrationID !== 0 &&
      validateEmail(registration.email) === true
    ) {
      // Send Email with uniqueId
      const result = await registerValidationEmail(
        registration.email,
        uniqueId.current
      );
      if (result !== true) {
        Swal.fire({
          title: "Email Not Sent",
          icon: "error",
          text: "Email was not sent.  Please try again.",
        });
        return;
      } else {
        Swal.fire({
          title: "Validation Code",
          icon: "success",
          text: "Validation Code has been sent to your email.  Please check your email and enter the code below.",
        });
        setRegistrationId(registration.registrationID);
        setEmail(registration.email);
        
        // Navigate to Email Verification
        setIsRegistering(false);
        setIsValidation(true);
      }
    } else {
      Swal.fire({
        title: "Check Registration ID and Email",
        icon: "error",
        text: "Please enter a valid Registration ID and Email.",
      });
    }
  };
  const handleCancel = () => {
    setRegistration({
      registrationID: 0,
      email: "",
    });
    history.push("/login");
  };
  const buttonStyles = {
    backgroundColor: "#3c4143",
    "&:hover": { backgroundColor: "#616161" },
  };

  const buttonSuccessStyles = {
    backgroundColor: "green",
    "&:hover": { backgroundColor: "#33c520" },
  };

  const textFieldStyles = {
    width: "100%", // Adjust the width as needed
    "& .MuiOutlinedInput-root": {
      color: "#000",
      fontFamily: "Arial",
      fontWeight: "bold",
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#2e2e2e",
        borderWidth: "2px",
      },
      "&.Mui-focused": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#3c4143",
          borderWidth: "3px",
        },
      },
    },
    "& .MuiInputLabel-outlined": {
      color: "#2e2e2e",
      fontWeight: "bold",
      "&.Mui-focused": {
        color: "#3c4143",
        fontWeight: "bold",
      },
    },
  };

  return (
    <Grid>
      <Container
        sx={{ alignContent: "center", textAlign: "center" }}
        maxWidth="lg"
      >
        <Card
          variant="outlined"
          sx={{
            bgcolor: "white",
            width: "800px",
            minHeight: "400px",
            margin: "auto",
            textAlign: "center",
            padding: "15px",
            borderRadius: "10px",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            marginTop: "150px",
          }}
        >
          <CardContent>
            <Typography
              variant="h4"
              fontSize="5"
              align="center"
              color="text.secondary"
            >
              Registration
            </Typography>
            <br />
            <TextField
              type="text"
              name="registrationID"
              sx={textFieldStyles}
              required
              placeholder="Registration ID *"
              onChange={(e) => handleRegistrationChange(e)}
              inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
            />
            <br />
            <br />
            <TextField
              type="email"
              name="email"
              sx={textFieldStyles}
              required
              placeholder="Email *"
              onChange={(e) => handleRegistrationChange(e)}
            />
            <br />
            <br />

            <Box display="flex" justifyContent="flex-end" gap={2}>
              <Button
                sx={buttonSuccessStyles}
                variant="contained"
                size="small"
                onClick={handleSubmit}
              >
                Register
              </Button>
              <Button
                sx={buttonStyles}
                variant="contained"
                size="small"
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Container>
    </Grid>
  );
};

export default RegisterVerification;
