import React, { useEffect, useState } from "react";
import { Button, ButtonGroup, Grid, Typography } from "@mui/material";
import { Notifications } from "@mui/icons-material";
import blue from "@mui/material/colors/blue";
import { makeStyles } from "tss-react/mui";
import SendCommunicationsDialog from "../../../common/sendCommunications";

const useStyles = makeStyles()((theme) => {
  return {
    root: {
      flexGrow: 1,
      textAlign: "center",
    },
    select: {
      color: "#eee",
    },
    inputContainer: {
      backgroundColor: "currentColor",
      color: "#eee",
      fontWeight: "bold",
    },
    selectorInput: {},
    typoGraphy: {
      color: "#003760",
      fontSize: "calc(12px + (24 - 12) * ((100vw - 250px) / (4000 - 250)))",
      lineHeight: "calc(1.3em + (1.5 - 1.2) * ((100vw - 250px)/(3000 - 250)))",
      fontWeight: "bold",
    },
    iconButton: {
      display: "inline-block",
      width: "auto",
      margin: 0,
      padding: 0,
      background: "none",
      backgroundColor: "transparent",
      border: "none",
      flexGrow: 1,
    },
    fabBlue: {
      color: theme.palette.common.black,
      backgroundColor: blue[300],
      "&:hover": {
        backgroundColor: blue[600],
      },
    },
    iconImage: {
      width: "50%",
    },
    input: {
      width: "80%",
      marginLeft: 8,
      flexGrow: 1,
      fontSize: "calc(8px + (24 - 8) * ((100vw - 250px) / (4000 - 250)))",
      lineHeight: "calc(1.3em + (1.5 - 1.2) * ((100vw - 250px)/(3000 - 250)))",
      [theme.breakpoints.down("xs")]: {
        fontSize: "calc(24px + (48 - 24) * ((100vw - 250px) / (4000 - 250)))",
        lineHeight:
          "calc(1.3em + (1.5 - 1.2) * ((100vw - 250px)/(3000 - 250)))",
      },
    },
    buttonIcon: {
      marginLeft: theme.spacing(1),
    },
  };
});

const ButtonSendNotification = ({ isOpen, setIsOpen, filteredAgents }) => {
  const { classes } = useStyles();
  const [communicationsTitle, setCommunicationsTitle] = useState("");
  const [uniqueRows, setUniqueRows] = useState([]);

  useEffect(() => {
    if (filteredAgents.length > 0) {
      const tempContactArray = [];
      filteredAgents?.forEach((row) => {
        if (
          !tempContactArray.find(
            (existingRow) => existingRow.email === row.agentEmail
          )
        ) {
          tempContactArray.push({
            firstName: row.agentName.split(" ")[0],
            lastName: row.agentName.split(" ").slice(1).join(" "),
            email: row.agentEmail,
            agentWayEnabled: row.agentWayEnabled !== "false",
            agentId: row.agentId,
          });
        }
      });
      setUniqueRows(tempContactArray);
    }
  }, [filteredAgents]);

  const onClickHandler = () => {
    setIsOpen(true);
  };
  return (
    <>
      <Grid
        item
        xs={8}
        style={{ m: 1, width: 1200, margin: "20px", alignContent: "center" }}
      >
        <ButtonGroup fullWidth data-tour="ad__resetAndDownload">
          <Button
            className={classes.fabBlue}
            onClick={onClickHandler}
            fullWidth
            disabled={uniqueRows.length === 0}
          >
            {
              <Typography style={{ color: "#000" }}>
                Send Notification
              </Typography>
            }
            <Notifications className={classes.buttonIcon} />
          </Button>
        </ButtonGroup>
      </Grid>
      <SendCommunicationsDialog
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
          setCommunicationsTitle("");
        }}
        title={communicationsTitle}
        currentAgents={uniqueRows}
      />
    </>
  );
};

export default ButtonSendNotification;
