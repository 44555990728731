import { Button, ButtonGroup, Grid, Typography } from "@mui/material";
import { ClearAll } from "@mui/icons-material";
import yellow from "@mui/material/colors/yellow";
import { makeStyles } from "tss-react/mui";
import { useDispatch } from "react-redux";
import { clearAgentListFilterModel } from "../../../../store/actions/agents/agentsContractInfo";


const useStyles = makeStyles()((theme) => {
  return {
    root: {
      flexGrow: 1,
      textAlign: "center",
    },
    select: {
      color: "#eee",
    },
    inputContainer: {
      backgroundColor: "currentColor",
      color: "#eee",
      fontWeight: "bold",
    },
    selectorInput: {},
    typoGraphy: {
      color: "#003760",
      fontSize: "calc(12px + (24 - 12) * ((100vw - 250px) / (4000 - 250)))",
      lineHeight: "calc(1.3em + (1.5 - 1.2) * ((100vw - 250px)/(3000 - 250)))",
      fontWeight: "bold",
    },
    iconButton: {
      display: "inline-block",
      width: "auto",
      margin: 0,
      padding: 0,
      background: "none",
      backgroundColor: "transparent",
      border: "none",
      flexGrow: 1,
    },
    fabYellow: {
      color: theme.palette.common.black,
      backgroundColor: yellow[300],
      "&:hover": {
        backgroundColor: yellow[600],
      },
    },
    iconImage: {
      width: "50%",
    },
    input: {
      width: "100%",
      marginLeft: 8,
      flexGrow: 1,
      fontSize: "calc(8px + (24 - 8) * ((100vw - 250px) / (4000 - 250)))",
      lineHeight: "calc(1.3em + (1.5 - 1.2) * ((100vw - 250px)/(3000 - 250)))",
      [theme.breakpoints.down("xs")]: {
        fontSize: "calc(24px + (48 - 24) * ((100vw - 250px) / (4000 - 250)))",
        lineHeight:
          "calc(1.3em + (1.5 - 1.2) * ((100vw - 250px)/(3000 - 250)))",
      },
    },
    buttonIcon: {
      marginLeft: theme.spacing(1),
    },
  };
});

const ButtonReset = (props) => {
  const dispatch = useDispatch();
  const {setCheckedCarrierValues , setCheckedLevelValues, setCheckedContractStatusValues, setAgencyId} = props;
  const { classes } = useStyles();
  const onClickHandler = () => {
    setCheckedCarrierValues([]);
    setCheckedLevelValues([]);
    setCheckedContractStatusValues([]);
    setAgencyId(sessionStorage.getItem("agencyId"));
    dispatch(clearAgentListFilterModel());
  };
  return (
    <Grid item xs={8} style={{ m: 1,  margin: '20px', alignContent:"center" }}>
      <ButtonGroup fullWidth data-tour="ad__resetAndDownload">        
          <Button
            fullWidth
            onClick={onClickHandler}
            className={classes.fabYellow}
          >
            {<Typography style={{ color: "#000" }}>Reset All</Typography>}
            <ClearAll className={classes.buttonIcon} />
          </Button>
      </ButtonGroup>
    </Grid>
  );
};

export default ButtonReset;
