const ProductionStatisticsData = (props) => {
  const { data } = props;
  const uniqueRows = [];
  const dataRows = data?.map((row) => {
    return {
      id:
        row.agentId +
        row.carrierId +
        row.companyId +
        row.planYear +
        row.effectivedate +
        row.county +
        row.state,
      agentName: row.agentName,
      carrierName: row.carrierName,
      companyName: row.companyName,
      hlevel: row.hlevel,
      ma: row.ma,
      medsupp: row.medsupp,
      pdp: row.pdp,
      planYear: row.planYear,
      uplines: row.uplines,
      county: row.county,
      state: row.state,
      effectivedate: row.effectivedate,
    };
  });

  dataRows.forEach((row) => {
    if (!uniqueRows.find((existingRow) => existingRow.id === row.id)) {
      uniqueRows.push(row);
    }
  });

  return uniqueRows;
};

export default ProductionStatisticsData;
