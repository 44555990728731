import React, { useState } from "react";
import MenuItem from "@mui/material/MenuItem/MenuItem";
import FormControl from "@mui/material/FormControl/FormControl";
import Select from "@mui/material/Select/Select";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentProduction } from "../../store/actions/production/CurrentProduction";
import { setCriteria } from "../../store/actions/production/productionActions";

const DropDown = (props) => {
  const dispatch = useDispatch();
  const { setYear, data } = props;
  const maxYear = (data) => {
    const salesYearList = data.map((item) => item.salesYear);
    if (salesYearList.length > 0) {
      return Math.max(...salesYearList);
    }
    return 0;
  };
  const absoluteMaxYear = maxYear(data);
  const [selectedValue, setSelectedValue] = useState(maxYear(data));
  const productionData = useSelector((state) => state.currentProduction);
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    setYear(event.target.value);
    let agencyId = parseInt(sessionStorage.getItem("agencyId"));
    //let agentId = parseInt(sessionStorage.getItem("agentId"));
    //Set Criteria
    setCriteria({
      selectedPlanYear: event.target.value,
    });
    //Set Current Production
    if (productionData && !productionData.production[event.target.value]) {
      dispatch(setCurrentProduction(agencyId, event.target.value));
    }
  };  
  
  const years = (data) => {
    const salesYearList = data.map((item) => item.salesYear);
    if (salesYearList.length > 0) {
      const uniqueYears = Array.from(new Set(salesYearList));
      return uniqueYears.sort((a, b) => b - a);
    }
    return [];
  };

  return (
    <FormControl>
      <label htmlFor="year-select">Select a Year</label>
      <Select
        id="year-select"
        value={selectedValue}
        onChange={handleChange}
        size="small"
      >
        {years(data).map((year, index) =>
          absoluteMaxYear - 1 <= year ? (
            <MenuItem key={index} value={year}>
              {year}
            </MenuItem>
          ) : null
        )}
      </Select>
    </FormControl>
  );
};



export default DropDown;
