import { useDispatch } from "react-redux";
import { GridLogicOperator } from "@mui/x-data-grid-pro";
import history from "../../../../utils/history";
import { setAgentListFilterModel } from "../../../../store/actions/agents/agentsContractInfo";
import { Button, ButtonGroup } from "@mui/material";
import { AccountCircle } from "@mui/icons-material";

const ProductionOverviewColumns = () => {
  const dispatch = useDispatch();

  const navigate = (path) => {
    history.push(path);
  };

  const handleClick = (e, cellValue) => {
    dispatch(
      setAgentListFilterModel({
        items: [
          {
            field: "agentName",
            value: cellValue,
            operatorValue: GridLogicOperator.EQ,
          },
        ],
      })
    );
    navigate("/AgentList");
  };

  const commonProps = {
    headerAlign: "center",
    align: "center",
    headerClassName: "super-app-theme--header",
    cellClassName: "super-app-theme--cell",
    flex: 1,
    sortable: false,
    disableColumnMenu: true,
  };

  const columns = [
    {
      ...commonProps,
      field: "product",
      headerName: "Carrier Name",
      type: "string",
    },
    {
      ...commonProps,
      field: "total",
      headerName: "Agency Production Total",
      type: "string",
    },
    {
      ...commonProps,
      field: "top",
      headerName: "Top Producing Agent",
      type: "string",
      renderCell: (params) => {
        return (
          <ButtonGroup variant="contained">
            <Button
              endIcon={<AccountCircle />}
              onClick={(event) => handleClick(event, params.value)}
              sx={{ width: "400px", flex: 1 }}
            >
              {params.value}
            </Button>
          </ButtonGroup>
        );
      },
    },
  ];
  return columns;
};

export default ProductionOverviewColumns;
