import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

const commonProps = {
  headerAlign: "center",
  align: "center",
  headerClassName: "super-app-theme--header",
  cellClassName: "super-app-theme--cell",
  sortable: false,
  disableColumnMenu: true,
  flex: 1,
};

const columns = [
  {
    field: "carrierName",
    headerName: " Carrier Name",
    width: 200,
    type: "string",
  },
  {
    field: "planYear",
    headerName: "Plan Year",
    width: 200,
    type: "string",
  },
  {
    field: "isCorporation",
    headerName: "Is Corporation",
    width: 200,
    type: "singleSelect",
    valueOptions: ["true", "false"],
    renderCell: (params) => {
      return params.value === "true" ? (
        <CheckCircleIcon style={{ color: "green" }} />
      ) : (
        <CancelIcon style={{ color: "red" }} />
      );
    },
  },
  {
    field: "agentCertified",
    headerName: "Agent Certified",
    width: 200,
    type: "singleSelect",
    valueOptions: ["true", "false"],
    renderCell: (params) => {
      return params.value === "true" ? (
        <CheckCircleIcon style={{ color: "green" }} />
      ) : (
        <CancelIcon style={{ color: "red" }} />
      );
    },
  },
  {
    field: "agentReady",
    headerName: "Agent Ready",
    width: 200,
    type: "singleSelect",
    valueOptions: ["true", "false"],
    renderCell: (params) => {
      return params.value === "true" ? (
        <CheckCircleIcon style={{ color: "green" }} />
      ) : (
        <CancelIcon style={{ color: "red" }} />
      );
    },
  },
  {
    field: "writingNumber",
    headerName: "Writing Number",
    width: 200,
    type: "string",
  },
];

const AgentsReadyToSellInfoColumns = () => {
  return columns.map((column) => ({ ...commonProps, ...column }));
};

export default AgentsReadyToSellInfoColumns;
